import React from 'react'
import { Button, ButtonText, TextProps } from '@red-ui/components'

type Props = {
  onPress?: () => void
  children: string
} & TextProps

export const TextButton = ({ onPress, children, ...props }: Props) => (
  <Button
    unstyled
    onPress={onPress}
    backgroundColor="$transparent"
    borderColor="unset"
    borderRadius="$0"
    borderWidth="$0"
    padding="$0"
    cursor="pointer">
    <ButtonText hoverStyle={{ opacity: 0.8 }} pressStyle={{ opacity: 0.6 }} color="$red6" fontSize="$4" fontWeight="500" {...props}>
      {children}
    </ButtonText>
  </Button>
)
