import React from 'react'
import { ScrollView, View, Text, Card, Separator } from '@red-ui/components'
import { getString } from '@vrw/data'
import { Header as SideSheetHeader } from '../SideSheet/Header'
import { Header as FlightDataHeader } from '../FlightDataCard/Header'
import { SeatTypeInfo } from '../FlightDataCard/SeatTypeInfo'
import { Footer } from './Footer'
import { PointsSummary } from './PointsSummary'
import { TextButton } from '../TextButton'

type Props = {
  onClose: () => void
  onEditSearch: () => void
}

export const FlightSummary = ({ onClose, onEditSearch }: Props) => {
  const handleEditSearch = () => {
    onClose()
    onEditSearch()
  }

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', gap: '$3' }}>
      <View>
        <SideSheetHeader title={getString('redSkySearch.results.sidesheet.summary')} onClose={onClose} />
        <View
          paddingHorizontal="$2"
          paddingTop="$2"
          $gtMobile={{ paddingTop: '$3', paddingHorizontal: '$3' }}
          $gtTabletLandscape={{ paddingTop: '$5' }}>
          <Text
            fontSize="$9"
            $mobile={{ fontSize: '$7' }}
            $tabletPortrait={{ fontSize: '$8' }}
            fontWeight="600"
            marginBottom="$3"
            $gtTabletLandscape={{ marginBottom: '$5' }}>
            Manchester to New York
          </Text>
          <Card padding="$3" borderWidth={1} borderColor="$black2">
            <FlightDataHeader inSideSheet subtitle="One way trip for 1 adult" />
            <View marginVertical="$2" $gtTabletPortrait={{ marginVertical: '$3' }}>
              <SeatTypeInfo />
            </View>
            <Separator />
            <View alignItems="center" marginTop="$3">
              <TextButton $gtMobile={{ fontSize: '$5' }} onPress={onClose}>
                {getString('redSkySearch.results.sidesheet.summary.change')}
              </TextButton>
            </View>
          </Card>
          <View marginTop="$4">
            <PointsSummary />
          </View>
        </View>
      </View>
      <View paddingHorizontal="$2" paddingBottom="$3" $gtMobile={{ paddingHorizontal: '$3' }}>
        <Footer onEditSearch={handleEditSearch} />
      </View>
    </ScrollView>
  )
}
