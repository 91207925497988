import React, { useState } from 'react'
import { Icon, Select } from '@red-ui/components'
import { generateDateObject, generateDateOptions } from '../../helpers/dateHelpers'
import { getTrackingId } from '../../helpers/getTrackingId'

type Props = {
  initialValue: Date
  onSelect: (date: Date) => void
}

export const DatePicker = ({ initialValue, onSelect }: Props) => {
  const options = generateDateOptions()
  const [value, setValue] = useState<string>(generateDateObject(initialValue).value)

  const onValueChange = (newValue: string) => {
    setValue(newValue)
    onSelect(new Date(newValue))
  }

  return (
    <Select onValueChange={onValueChange} defaultValue={value}>
      <Select.Trigger
        height="$5"
        backgroundColor="$white"
        iconAfter={<Icon.ChevronDown size="$3" marginRight="$-1" $mobile={{ marginRight: '$-0.75' }} />}>
        <Select.Value
          fontWeight="400"
          fontSize="$3"
          $mobile={{ marginLeft: '$0' }}
          $gtTabletPortrait={{ fontSize: '$4' }}
          $gtTabletLandscape={{ fontSize: '$4' }}
          marginLeft="$-1"
        />
      </Select.Trigger>
      <Select.Content>
        <Select.Viewport>
          {options.map((option, index) => (
            <Select.Item
              id={getTrackingId('search-form', 'date', option.label)}
              key={option.value}
              value={option.value}
              index={index}
              height="$5"
              backgroundColor="$white">
              <Select.ItemText
                $mobile={{ fontSize: '$3' }}
                fontSize="$3"
                $gtTabletPortrait={{ fontSize: '$4' }}
                color={value === option.value ? '$red6' : '$black10'}
                fontWeight={value === option.value ? '500' : '400'}>
                {option.label}
              </Select.ItemText>
            </Select.Item>
          ))}
        </Select.Viewport>
      </Select.Content>
    </Select>
  )
}
