import { Page } from '@/components'
import { useRedNavigate } from '@/dataImplementation/useRedNavigate'
import { PATHS } from '@/router/paths'
import { Accordion, config, H1, H2, Separator, XStack, YStack } from '@red-ui/components'
import { getString } from '@vrw/data'
import React, { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getAirportByCode } from '../api/searchAirports'
import { FlightDataCard } from '../components/FlightDataCard'
import { FlightSummary } from '../components/FlightSummary'
import { SearchSideSheets } from '../components/SearchSideSheets'
import { SideSheet } from '../components/SideSheet'
import { TextButton } from '../components/TextButton'
import { convertPassengersQueryStrToObject } from '../helpers/searchQueryParams'

export const flightsData = [{ id: '1' }, { id: '2' }]

export function SearchResults() {
  const [activeItem, setActiveItem] = useState<string | undefined>()
  const [selectedFlightId, setSelectedFlightId] = useState<string | null>(null)
  const [searchSheetOpen, setSearchSheetOpen] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useRedNavigate()

  const initFormData = useMemo(() => {
    const originCode = searchParams.get('origin')
    const destinationCode = searchParams.get('destination')
    const departing = searchParams.get('departing')
    const passengersQueryStr = searchParams.get('passengers')

    if (!originCode || !destinationCode || !departing || !passengersQueryStr) return null

    const departureAirport = getAirportByCode(originCode)
    const destinationAirport = getAirportByCode(destinationCode)

    if (!departureAirport || !destinationAirport) return null

    const departingDateData = new Date(departing)
    const passengersData = convertPassengersQueryStrToObject(passengersQueryStr)

    return {
      departure: departureAirport,
      destination: destinationAirport,
      date: departingDateData,
      ...passengersData,
    }
  }, [searchParams])

  const onCloseSummarySideSheet = () => setSelectedFlightId(null)
  const handleSearchOpen = () => setSearchSheetOpen(true)

  if (!initFormData) {
    navigate(PATHS.REDSKY_SEARCH)
    return null
  }

  const destinationCitiesText = getString('redSkySearch.results.search.summary.route')
    .replace('{{from}}', initFormData.departure.cityName)
    .replace('{{to}}', initFormData.destination.cityName)

  const destinationCodesText = getString('redSkySearch.results.search.summary.route')
    .replace('{{from}}', initFormData.departure.code)
    .replace('{{to}}', initFormData.destination.code)

  const passengersText = Object.entries({
    adults: initFormData.adults,
    youngAdults: initFormData.youngAdults,
    children: initFormData.children,
    infant: initFormData.infant,
  })
    .filter(([_, count]) => count > 0)
    .map(([type, count]) => {
      const key = `redSkySearch.results.search.summary.${type}.${count > 1 ? 'other' : 'one'}`
      return getString(key).replace('{{count}}', `${count}`)
    })
    .join(', ')

  const searchSummaryText = `${destinationCodesText} | ${initFormData.date.toDateString()} | ${passengersText}`

  return (
    <Page title={getString('redSkySearch.results.title')}>
      <SearchSideSheets
        mainSheetOpen={searchSheetOpen}
        onCloseMainSheet={setSearchSheetOpen}
        mainSheetTitle={getString('redSkySearch.results.sidesheet.summary.editSearch')}
        editMode
        initData={initFormData}
      />
      <SideSheet
        width="40%"
        open={!!selectedFlightId}
        onClose={onCloseSummarySideSheet}
        ariaLabel={getString('redSkySearch.results.sidesheet.summary.ariaLabel')}>
        <FlightSummary onClose={onCloseSummarySideSheet} onEditSearch={handleSearchOpen} />
      </SideSheet>
      <YStack
        gap="$3"
        paddingHorizontal="$2"
        paddingBottom="$3"
        marginHorizontal="auto"
        maxWidth={config.media.desktop.maxWidth}
        $gtMobile={{ paddingHorizontal: '$3' }}
        $gtTabletLandscape={{ paddingHorizontal: '$11' }}>
        <YStack
          // not supported in react-native-web
          style={{ position: 'sticky' }}
          top={0}
          zIndex={2}
          backgroundColor="$white"
          paddingTop="$3"
          $gtMobile={{ paddingTop: '$4' }}
          $gtTabletLandscape={{ paddingTop: '$6' }}
          gap="$2">
          <H1 fontSize="$7" $mobile={{ lineHeight: '$7' }} $gtMobile={{ fontSize: '$10' }} fontWeight="600">
            {destinationCitiesText}
          </H1>
          <XStack
            gap="$2"
            justifyContent="space-between"
            alignItems="center"
            $mobile={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <H2 fontSize="$4" $gtMobile={{ fontSize: '$6' }}>
              {searchSummaryText}
            </H2>
            <TextButton $gtMobile={{ fontSize: '$6' }} onPress={handleSearchOpen}>
              {getString('redSkySearch.results.search.summary.edit')}
            </TextButton>
          </XStack>
          <Separator />
        </YStack>
        <Accordion value={activeItem} onValueChange={setActiveItem} type="single" collapsible gap="$3">
          {flightsData.map(({ id }) => (
            <FlightDataCard key={id} value={id} isActive={id === activeItem} onSelect={setSelectedFlightId} />
          ))}
        </Accordion>
      </YStack>
    </Page>
  )
}
