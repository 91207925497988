import React, { useEffect } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { configureRedData } from '@vrw/data/src'
import { fetchFeatures } from '@vrw/data/src/redux/features/features.thunk'
import { EVIDENTLY_PROJECT } from '@vrw/data/src/redux/features/features.types'
import { getProfileData } from '@vrw/data/src/redux/profile/selectors'

import { persistor } from '../redux/configureStore'
import { redDataConfig } from '../dataImplementation/dataConfig'
import { handlePartnerLogin } from '../dataImplementation/partnerConnectWeb'

import 'tippy.js/dist/tippy.css'
import 'react-alice-carousel/lib/alice-carousel.css'
import '../style/tooltips.css'

import { Toast, DialogContainer } from '../components'
import { RootRouter } from '../router/router'
import { useAppDispatch, useAppSelector } from '../redux/hooks/hooks'
import { PATHS } from '../router/paths'
import { getRedDataConfig } from '@vrw/data/src/config'
import { useThirdPartyInit } from './App.hooks'
import '../i18next'
import { ViewingRegion } from '@vrw/data/src/utils'
import { useRedNavigate } from '../dataImplementation/useRedNavigate'
import { CookieConsent } from './CookieConsent'
import { APP_VERSION } from '@/config'
import { DynamicYieldHelmet } from '@/components/DynamicYieldHelmet'

const App = () => {
  console.debug(`App version: ${APP_VERSION}`)
  const dispatch = useAppDispatch()
  const profile = useAppSelector(getProfileData)

  configureRedData(redDataConfig)
  const config = getRedDataConfig()
  const redNavigate = useRedNavigate()

  useEffect(() => {
    dispatch(fetchFeatures({ project: EVIDENTLY_PROJECT, sessionId: profile?.redUserId }))
  }, [dispatch, profile?.redUserId])

  useEffect(() => {
    handlePartnerLogin()
  }, [])

  useEffect(() => {
    const allowedPaths = [PATHS.TERMS_AND_CONDITIONS, PATHS.PRIVACY, PATHS.LOGOUT_SUCCESS]

    if (profile?.appState?.onboarding && !allowedPaths.some((path) => window.location.pathname.includes(path))) {
      const { onboarding } = profile.appState
      redNavigate(config.navTargets[onboarding], {}, profile?.country as ViewingRegion)
    }
  }, [config, profile, redNavigate])

  useThirdPartyInit()

  return (
    <>
      <style jsx global>{`
        .app {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        .body-wrapper {
          display: flex;
          flex: 1 0 auto;
          flex-direction: column;
        }
        .overlay {
          background: rgba(0, 0, 0, 0.5);
          bottom: 0;
          display: none;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
        }
      `}</style>
      <PersistGate loading={null} persistor={persistor}>
        <DynamicYieldHelmet />
        <div className="app">
          <div className="body-wrapper">
            <RootRouter />
          </div>
        </div>
        <Toast />
        <DialogContainer />
        <CookieConsent />
        <div className="overlay" />
      </PersistGate>
    </>
  )
}

export { App }
