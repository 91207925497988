import React, { useState } from 'react'
import { XStack, Text, YStack, Button, Icon } from '@red-ui/components'
import { getString } from '@vrw/data'
import { AlertTooltip } from './AlertTooltip'
import { getTrackingId } from '@/feature/RedSkySearch/helpers/getTrackingId'

export type Props = {
  onChangeCount: (value: number) => void
  type: string
  toolTip: boolean
  max: number
  min: number
  value?: number
}

const buttonStyling = {
  width: '$5',
  height: '$5',
  backgroundColor: '$white',
  borderColor: '$black3',
  hoverStyle: {
    backgroundColor: '$black1',
    borderColor: '$black3',
    cursor: 'pointer',
  },
  disabledStyle: {
    backgroundColor: '$black1',
    cursor: 'auto',
  },
  pressStyle: {
    backgroundColor: '$black3',
    borderColor: '$black3',
  },
}

export const PassengerRow = ({ type, toolTip, max, min, value, onChangeCount }: Props) => {
  const [viewWidth, setViewWidth] = useState(0)
  const [count, setCount] = useState(value ?? min)

  const onClick = (value: number) => () => {
    setCount((prev) => {
      const nextValue = prev + value

      if (nextValue >= min && nextValue <= max) {
        onChangeCount(nextValue)
        return nextValue
      }

      return prev
    })
  }

  return (
    <XStack
      justifyContent="space-between"
      onLayout={(e) => {
        setViewWidth(e.nativeEvent.layout.width)
      }}>
      <YStack>
        <XStack alignItems="center" gap="$1">
          <Text display="flex" fontSize="$6" $mobile={{ fontSize: '$4' }} $tabletPortrait={{ fontSize: '$5' }}>
            {getString(`redSkySearch.question.passengers.${type}`)}
          </Text>
          {toolTip && (
            <AlertTooltip
              viewWidth={viewWidth}
              title={getString(`redSkySearch.question.passengers.${type}.toolTip.title`)}
              text={getString(`redSkySearch.question.passengers.${type}.toolTip.text`)}
              iconAriaLabel={getString(`redSkySearch.question.passengers.toolTip`)}
              closeAriaLabel={getString('redSkySearch.question.passengers.toolTip.close')}
            />
          )}
        </XStack>
        <Text fontSize="$3" $gtTabletPortrait={{ fontSize: '$4' }}>
          {getString(`redSkySearch.question.passengers.${type}.age`)}
        </Text>
      </YStack>
      <YStack justifyContent="center">
        <XStack justifyContent="space-between" alignItems="center">
          <Button
            {...buttonStyling}
            id={getTrackingId('search-form', type, getString('redSkySearch.question.passengers.minus'))}
            disabled={count === min}
            onPress={onClick(-1)}
            icon={<Icon.Minus color="$black10" aria-label={getString('redSkySearch.question.passengers.minus')} />}
          />
          <Text
            fontSize="$6"
            width="$7"
            $mobile={{ fontSize: '$4' }}
            $tabletPortrait={{ fontSize: '$5' }}
            textAlign="center"
            lineHeight="$5"
            alignContent="center"
            paddingHorizontal="$2">
            <strong>{count}</strong>
          </Text>
          <Button
            {...buttonStyling}
            id={getTrackingId('search-form', type, getString('redSkySearch.question.passengers.plus'))}
            onPress={onClick(1)}
            disabled={count === max}
            icon={<Icon.Plus color="$black10" aria-label={getString('redSkySearch.question.passengers.plus')} />}
          />
        </XStack>
      </YStack>
    </XStack>
  )
}
