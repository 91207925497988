import { Image, YStack, View, XStack, Text, Separator, useMedia } from '@red-ui/components'
import React from 'react'
import { Chip } from '@/components'
import { getString } from '@vrw/data'
import { PUBLIC_URL } from '@/config'

type Props = {
  subtitle?: string
  inSideSheet?: boolean
}

export const Header = ({ subtitle, inSideSheet = false }: Props) => {
  const { gtMobile } = useMedia()
  let logoSize = 35
  if (gtMobile) logoSize = inSideSheet ? 42 : 55

  const basicFontSizeConfig = inSideSheet ? '$5' : '$7'

  return (
    <YStack gap="$2" $gtMobile={{ gap: '$3' }}>
      <View
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        $gtMobile={inSideSheet && { flexDirection: 'column-reverse', alignItems: 'flex-start' }}
        $gtTabletLandscape={{ flexDirection: 'row' }}>
        <XStack alignItems="center">
          <Image
            source={{
              uri: `${PUBLIC_URL}/img/virgin_atlantic_logo.png`,
              height: logoSize,
              width: logoSize,
            }}
            aria-label={getString('redSkySearch.results.card.logo.alt')}
            marginRight="$2"
          />
          <YStack alignItems="flex-start" justifyContent="space-between">
            <Text
              marginBottom="$0.5"
              lineHeight="$4"
              fontSize="$4"
              fontWeight="600"
              $gtMobile={{
                fontSize: basicFontSizeConfig,
                lineHeight: basicFontSizeConfig,
                marginBottom: inSideSheet ? '$0.5' : '$2',
              }}>
              09:25 - 15:34
            </Text>
            <Text
              color="$black5"
              fontSize="$3"
              $gtMobile={!inSideSheet && { fontSize: '$5' }}
              $gtTabletLandscape={{ fontSize: inSideSheet ? '$4' : '$6' }}>
              {getString('redSkySearch.results.card.airline')}
            </Text>
          </YStack>
        </XStack>
        <Text
          fontSize="$4"
          $gtMobile={{
            fontSize: basicFontSizeConfig,
            lineHeight: basicFontSizeConfig,
            marginBottom: inSideSheet ? '$2' : '$0',
          }}
          $gtTabletLandscape={{ marginBottom: '$0' }}
          fontWeight="600"
          textAlign="right">
          Fri 14 Feb
        </Text>
      </View>
      <YStack>
        <XStack alignItems="center" justifyContent="space-between">
          <strong>
            <Text
              fontSize="$4"
              marginRight="$2"
              $gtMobile={{
                fontSize: basicFontSizeConfig,
                lineHeight: basicFontSizeConfig,
                marginRight: inSideSheet ? '$3' : '$4',
              }}>
              MAN
            </Text>
          </strong>
          <Separator borderColor="$black10" />
          <Chip.Root
            marginHorizontal="$1"
            paddingHorizontal="$2"
            minWidth="unset"
            backgroundColor="$white"
            borderWidth={1}
            borderColor="$black10"
            shadowOpacity={0}
            $gtMobile={{ marginHorizontal: '$2', paddingHorizontal: '$3' }}
            $gtTabletLandscape={{ paddingHorizontal: '$4' }}>
            <Chip.Text color="$black6" fontWeight="400" fontSize="$3" $gtTabletLandscape={{ fontSize: '$4' }}>
              Direct
            </Chip.Text>
          </Chip.Root>
          <Separator borderColor="$black10" />
          <strong>
            <Text
              textAlign="right"
              fontSize="$4"
              marginLeft="$2"
              $gtMobile={{
                fontSize: basicFontSizeConfig,
                lineHeight: basicFontSizeConfig,
                marginLeft: inSideSheet ? '$3' : '$4',
              }}>
              JFK
            </Text>
          </strong>
        </XStack>
        <XStack marginTop="$1" $gtTabletLandscape={{ marginTop: '$2' }} justifyContent="space-between">
          <Text
            fontSize={inSideSheet ? '$3' : '$4'}
            $gtMobile={{ fontSize: inSideSheet ? '$3' : '$5' }}
            $gtTabletLandscape={{ fontSize: '$6' }}>
            09:25
          </Text>
          <Text color="$black6" fontSize="$3" $gtTabletLandscape={{ fontSize: '$4' }}>
            6h 59m
          </Text>
          <Text
            textAlign="right"
            fontSize={inSideSheet ? '$3' : '$4'}
            $gtMobile={{ fontSize: inSideSheet ? '$3' : '$5' }}
            $gtTabletLandscape={{ fontSize: '$6' }}>
            15:34
          </Text>
        </XStack>
      </YStack>
      {!!subtitle && (
        <>
          <Text fontSize="$3" $gtTabletLandscape={{ fontSize: '$4' }} textAlign="center">
            {subtitle}
          </Text>
          <Separator />
        </>
      )}
    </YStack>
  )
}
