import React from 'react'
import { Image, YStack } from '@red-ui/components'
import { ContentfulImage } from '@vrw/data/src/utils/contentfulPages'
import { optimizeContentfulImageUrl } from '@/helpers/images'
import { isUrlExternal } from '@/helpers/urlHelper'
import { Link } from '@/components/Link'

const Wrapper = ({ target, isEmbed, children }: { target?: string; isEmbed?: boolean; children: React.ReactNode }) => {
  if (isEmbed) {
    return target ? <Link href={target} external={isUrlExternal(target)} children={children} /> : children
  }

  return (
    <YStack
      flex={1}
      width="100%"
      style={{ alignItems: 'inherit' }}
      children={target ? <Link href={target} external={isUrlExternal(target)} display="contents" children={children} /> : children}
    />
  )
}

interface DynamicImageFields {
  image: ContentfulImage
  aspectRatio?: '16/9' | '4/3' | '1/1'
  maxWidth?: number
  linkUrl?: string
}

export const DynamicImage = ({
  fields,
  transformImageUrl = (url) => url,
  isEmbed,
}: {
  fields: DynamicImageFields
  transformImageUrl?: (url: string) => string
  isEmbed?: boolean
}) => {
  const optimizedImageUrl = optimizeContentfulImageUrl(transformImageUrl(fields.image?.fields.file?.url ?? ''), 'fill')
  const imageDimensions = {
    width: fields.image?.fields.file?.details?.image?.width ?? 1000,
    height: fields.image?.fields.file?.details?.image?.height ?? 1000,
  }
  const [aspectRatioWidth, aspectRatioHeight] = fields.aspectRatio ? fields.aspectRatio.split('/').map(Number) : []
  const imageAspectRatio =
    aspectRatioHeight && aspectRatioWidth ? aspectRatioWidth / aspectRatioHeight : imageDimensions.width / imageDimensions.height

  return (
    <Wrapper target={fields.linkUrl} isEmbed={isEmbed}>
      <Image
        accessibilityLabel={fields.image?.fields.title ?? fields.image?.fields.description}
        source={{
          uri: optimizedImageUrl,
          width: imageDimensions.width,
          height: imageDimensions.height,
        }}
        aspectRatio={imageAspectRatio}
        width={isEmbed ? 'unset' : '100%'}
        height="100%"
        maxWidth={fields.maxWidth ?? 680}
        maxHeight={620}
        objectFit="contain"
        resizeMode="contain"
      />
    </Wrapper>
  )
}
