import React, { useState, useEffect } from 'react'
import H3Header from '../../../components/typography/H3Header'
import { spacing } from '../../../style/variables'
import { media } from '../../../style/media'
import { TextField } from '@virgin-core/components'
import { getString } from '@vrw/data'
import { Button } from '../../../components/Buttons/Button'
import { Spacer, Page } from '../../../components'
import { MyAccountBackground } from '../MyAccountBackground'

interface PromoCodesProps {
  isLoading?: boolean
  hasSubmitted?: boolean
  errorMessage: string | undefined
  doSubmitPromoCode: (code: string) => void
  doSubmitPromoCodeConfirmed: () => void
}

const PromoCodes: React.FC<PromoCodesProps> = ({
  isLoading,
  hasSubmitted,
  errorMessage,
  doSubmitPromoCode,
  doSubmitPromoCodeConfirmed,
}) => {
  const [promoCode, setPromoCode] = useState('')

  useEffect(() => {
    if (hasSubmitted && !errorMessage) {
      doSubmitPromoCodeConfirmed()
    }
  }, [hasSubmitted, errorMessage, doSubmitPromoCodeConfirmed])

  return (
    <>
      <style jsx>{`
        @media ${media.tabletAndHigher} {
          :global(.text-field-wrap .MuiInputLabel-formControl) {
            min-width: 200px;
          }
        }
        @media ${media.mobileAndLower} {
          .text-field-wrap :global(.MuiInputLabel-formControl) {
            position: relative;
          }
        }
      `}</style>
      <Page title="Redeem a promo code | Virgin Red" testId="page-account-promo-codes">
        <MyAccountBackground>
          <H3Header
            textAlign="left"
            fontSize={{ mobile: 24, tablet: 24, desktop: 28 }}
            weight={600}
            marginTop={spacing.zero}
            marginBottom={spacing.zero}>
            {getString('account.promoCodes.title')}
          </H3Header>
          <div className="text-field-wrap">
            <p>{getString('account.promoCodes.description')}</p>
            <TextField
              id="promo-code"
              label={getString('account.promoCodes.field.label')}
              error={Boolean(errorMessage) && hasSubmitted && !isLoading}
              onChange={(event) => setPromoCode(event.target.value)}
              required
            />
          </div>
          <Spacer size={'medium'} />
          <Button
            dataAttributes={{ testid: 'promo-submit-button' }}
            isPrimary
            isFullWidth={false}
            display={'inline-block'}
            text={getString('account.promoCodes.submit')}
            onClick={() => doSubmitPromoCode(promoCode)}
            loading={isLoading}></Button>
        </MyAccountBackground>
      </Page>
    </>
  )
}

export { PromoCodes }
