import React from 'react'
import { Card, Separator, Button } from '@red-ui/components'
import { SeatTypeInfo } from './SeatTypeInfo'
import { FlightPointsPrice } from './FlightPointsPrice'
import { getString } from '@vrw/data'
import { getTrackingId } from '../../helpers/getTrackingId'

type Props = {
  onSelect: () => void
}

export const SeatTypeCard = ({ onSelect }: Props) => {
  return (
    <Card
      minWidth={284}
      flex={1}
      backgroundColor="$white"
      borderWidth={1}
      padding="$2"
      marginRight="$2"
      $gtMobile={{ padding: '$3', marginRight: '$3', minWidth: 254 }}>
      <SeatTypeInfo />
      <Separator marginVertical="$2" $gtMobile={{ marginVertical: '$3' }} />
      <FlightPointsPrice
        label={getString('redSkySearch.results.card.points.label.onewaytrip')}
        points={75000}
        $gtMobile={{ flexDirection: 'column', alignItems: 'flex-start' }}
        $gtTabletLandscape={{ flexDirection: 'row' }}
      />
      <Button
        id={getTrackingId('flight-card', getString('redSkySearch.results.card.cta'))}
        height="$6"
        onPress={onSelect}
        aria-label={getString('redSkySearch.results.card.cta')}
        marginTop="$3">
        {getString('redSkySearch.results.card.cta')}
      </Button>
    </Card>
  )
}
