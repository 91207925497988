import React from 'react'
import { View, Button, Icon } from '@red-ui/components'
import { getString } from '@vrw/data'
import { getTrackingId } from '../../helpers/getTrackingId'

type Props = {
  onEditSearch: () => void
}

export const Footer = ({ onEditSearch }: Props) => {
  const onContinue = () => {}

  return (
    <View gap="$2" flexDirection="column" $gtMobile={{ flexDirection: 'row' }}>
      <Button id={getTrackingId('flight-summary', getString('actions.continue'))} flex={1} height="$6" onPress={onContinue}>
        <Button.Icon>
          <Icon.ArrowUpRightFromSquare />
        </Button.Icon>
        <Button.Text>{getString('actions.continue')}</Button.Text>
      </Button>
      <Button flex={1} height="$6" onPress={onEditSearch} theme="secondary">
        {getString('redSkySearch.results.sidesheet.summary.editSearch')}
      </Button>
    </View>
  )
}
