export type Airport = {
  code: string
  airportName: string
  cityName: string
  countryName: string
  region: string
}

type GroupedAirportsByRegion = {
  [region: string]: Airport[]
}

export const airports: Airport[] = [
  { code: 'LHR', airportName: 'London Heathrow (LHR)', cityName: 'London', countryName: 'United Kingdom', region: 'United Kingdom' },
  { code: 'EDI', airportName: 'Edinburgh (EDI)', cityName: 'Edinburgh', countryName: 'United Kingdom', region: 'United Kingdom' },
  { code: 'MAN', airportName: 'Manchester (MAN)', cityName: 'Manchester', countryName: 'United Kingdom', region: 'United Kingdom' },
  { code: 'ATL', airportName: 'Atlanta (ATL)', cityName: 'Atlanta', countryName: 'USA', region: 'USA' },
  { code: 'BOS', airportName: 'Boston (BOS)', cityName: 'Boston', countryName: 'USA', region: 'USA' },
  { code: 'LAS', airportName: 'Las Vegas (LAS)', cityName: 'Las Vegas', countryName: 'USA', region: 'USA' },
  { code: 'LAX', airportName: 'Los Angeles (LAX)', cityName: 'Los Angeles', countryName: 'USA', region: 'USA' },
  { code: 'MIA', airportName: 'Miami (MIA)', cityName: 'Miami', countryName: 'USA', region: 'USA' },
  { code: 'JFK', airportName: 'New York (JFK)', cityName: 'New York', countryName: 'USA', region: 'USA' },
  { code: 'MCO', airportName: 'Orlando (MCO)', cityName: 'Orlando', countryName: 'USA', region: 'USA' },
  { code: 'SFO', airportName: 'San Francisco (SFO)', cityName: 'San Francisco', countryName: 'USA', region: 'USA' },
  { code: 'SEA', airportName: 'Seattle (SEA)', cityName: 'Seattle', countryName: 'USA', region: 'USA' },
  { code: 'TPA', airportName: 'Tampa (TPA)', cityName: 'Tampa', countryName: 'USA', region: 'USA' },
  { code: 'IAD', airportName: 'Washington, D.C. (IAD)', cityName: 'Washington, D.C.', countryName: 'USA', region: 'USA' },
  { code: 'ACC', airportName: 'Accra (ACC)', cityName: 'Accra', countryName: 'Ghana', region: 'Africa' },
  { code: 'CPT', airportName: 'Cape Town (CPT)', cityName: 'Cape Town', countryName: 'South Africa', region: 'Africa' },
  { code: 'JNB', airportName: 'Johannesburg (JNB)', cityName: 'Johannesburg', countryName: 'South Africa', region: 'Africa' },
  { code: 'LOS', airportName: 'Lagos (LOS)', cityName: 'Lagos', countryName: 'Nigeria', region: 'Africa' },
  { code: 'BLR', airportName: 'Bengaluru (BLR)', cityName: 'Bengaluru', countryName: 'India', region: 'Asia' },
  { code: 'DEL', airportName: 'Delhi (DEL)', cityName: 'Delhi', countryName: 'India', region: 'Asia' },
  { code: 'MLE', airportName: 'Maldives (MLE)', cityName: 'Maldives', countryName: 'Maldives', region: 'Asia' },
  { code: 'BOM', airportName: 'Mumbai (BOM)', cityName: 'Mumbai', countryName: 'India', region: 'Asia' },
  { code: 'YYZ', airportName: 'Toronto (YYZ)', cityName: 'Toronto', countryName: 'Canada', region: 'Canada' },
  { code: 'ANU', airportName: 'Antigua (ANU)', cityName: 'Antigua', countryName: 'Antigua and Barbuda', region: 'Caribbean' },
  { code: 'BGI', airportName: 'Barbados (BGI)', cityName: 'Barbados', countryName: 'Barbados', region: 'Caribbean' },
  { code: 'GND', airportName: 'Grenada (GND)', cityName: 'Grenada', countryName: 'Grenada', region: 'Caribbean' },
  { code: 'MBJ', airportName: 'Montego Bay (MBJ)', cityName: 'Montego Bay', countryName: 'Jamaica', region: 'Caribbean' },
  { code: 'NAS', airportName: 'Bahamas (NAS)', cityName: 'Bahamas', countryName: 'Bahamas', region: 'Caribbean' },
  {
    code: 'PLS',
    airportName: 'Turks & Caicos (PLS)',
    cityName: 'Turks & Caicos',
    countryName: 'Turks and Caicos Islands',
    region: 'Caribbean',
  },
  {
    code: 'SVD',
    airportName: 'St. Vincent & the Grenadines (SVD)',
    cityName: 'St. Vincent & the Grenadines',
    countryName: 'Saint Vincent and the Grenadines',
    region: 'Caribbean',
  },
  { code: 'UVF', airportName: 'St. Lucia (UVF)', cityName: 'St. Lucia', countryName: 'Saint Lucia', region: 'Caribbean' },
  { code: 'CUN', airportName: 'Cancun (CUN)', cityName: 'Cancun', countryName: 'Mexico', region: 'Mexico' },
  { code: 'DXB', airportName: 'Dubai (DXB)', cityName: 'Dubai', countryName: 'United Arab Emirates', region: 'Middle East' },
  { code: 'RUH', airportName: 'Riyadh (RUH)', cityName: 'Riyadh', countryName: 'Saudi Arabia', region: 'Middle East' },
  { code: 'TLV', airportName: 'Tel Aviv (TLV)', cityName: 'Tel Aviv', countryName: 'Israel', region: 'Middle East' },
]

export const matchesQuery = (tokens: string[], airport: Airport) => {
  return tokens.every((token) => Object.values(airport).some((field) => field.toLowerCase().includes(token)))
}

export const searchAirports = (query: string): Airport[] => {
  const tokens = query.trim().toLowerCase().split(/\s+/).filter(Boolean)
  return tokens.length ? airports.filter((airport) => matchesQuery(tokens, airport)) : airports
}

export const getAirportByCode = (code: string): Airport | undefined => airports.find((airport) => airport.code === code)

export const groupAirportsByRegion = (items: Airport[]): GroupedAirportsByRegion => {
  return items.reduce((acc: GroupedAirportsByRegion, airport: Airport) => {
    const { region } = airport

    if (!acc[region]) {
      acc[region] = []
    }

    acc[region].push(airport)
    return acc
  }, {})
}
