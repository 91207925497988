import {
  AnimatePresence,
  Button,
  Form,
  Icon,
  Input,
  Label,
  ScrollView,
  Separator,
  Text,
  useDebounceValue,
  XStack,
  YStack,
  ButtonProps,
  styled,
} from '@red-ui/components'
import { Airport, airports, groupAirportsByRegion, searchAirports } from '../../api/searchAirports'
import React, { useMemo, useRef, useState } from 'react'
import { getString } from '@vrw/data'
import { CloseRight, Header } from '../SideSheet/Header'
import { getTrackingId } from '../../helpers/getTrackingId'
import { useRecentSearches } from './useRecentSearches'

export type FlightSearchFormProps = {
  onClose: () => void
  title: string
  info: string
  onSelect: (airport: Airport) => void
  value?: string
}

const SearchResult = ({ name, ...props }: { name: string } & ButtonProps) => (
  <Button
    unstyled
    borderStyle="unset"
    borderWidth="$0"
    flexDirection="row"
    paddingVertical="$1"
    alignItems="center"
    cursor="pointer"
    paddingLeft="$0.5"
    width="100%"
    backgroundColor="$white"
    icon={<Icon.Plane color="$black10" fill="$black10" size="$2" />}
    hoverStyle={{ backgroundColor: '$black2' }}
    pressStyle={{ backgroundColor: '$black3' }}
    {...props}>
    <Text fontSize="$5" $gtTabletLandscape={{ fontSize: '$6' }} $mobile={{ fontSize: '$4' }} color="$black10">
      {name}
    </Text>
  </Button>
)

const ListSectionHeader = styled(Text, {
  fontWeight: '500',
  color: '$black7',
  marginBottom: '$2',
  fontSize: '$3',
  $gtTabletLandscape: {
    fontSize: '$4',
  },
})

export function FlightSearchForm({ info, onClose, onSelect, title, value }: Readonly<FlightSearchFormProps>) {
  const inputRef = useRef<Input>(null)
  const [internalValue, setInternalValue] = useState(value ?? '')
  const debouncedValue = useDebounceValue(internalValue, 300)
  const data = useMemo(() => searchAirports(debouncedValue), [debouncedValue])
  const { recentSearches, saveSearch } = useRecentSearches()

  const airportGroupsByRegion = groupAirportsByRegion(data.length > 0 ? data : airports)
  const regions = Object.keys(airportGroupsByRegion)

  const onSelectResult = (result: Airport) => {
    onSelect(result)
    saveSearch(result)
  }

  return (
    <>
      <Header iconPosition="left" title={title} onClose={onClose} />
      <YStack flex={1} gap="$3" paddingTop="$4" paddingBottom="$3" paddingHorizontal="$3">
        <Form flex={1} aria-label={getString('redSkySearch.searchInput.form.arialLabel')}>
          <YStack>
            <XStack>
              <Input
                autoFocus
                flex={1}
                ref={inputRef}
                id="airport-search"
                placeholder={title}
                aria-label={getString('redSkySearch.searchInput.arialLabel')}
                value={internalValue}
                height="$7"
                fontSize="$3"
                paddingRight={internalValue ? '$7' : undefined}
                onChangeText={setInternalValue}
                $gtTabletPortrait={{ fontSize: '$4' }}
              />
              <AnimatePresence>
                {internalValue && (
                  <CloseRight
                    aria-label={getString('redSkySearch.searchInput.clearButton.arialLabel')}
                    animation="300ms"
                    enterStyle={{ opacity: 0 }}
                    exitStyle={{ opacity: 0 }}
                    top="$2"
                    right="$2"
                    onPress={() => {
                      setInternalValue('')
                      inputRef.current?.focus()
                    }}
                  />
                )}
              </AnimatePresence>
            </XStack>
            <Label marginLeft="$3" fontSize="$3" htmlFor="airport-search">
              {info}
            </Label>
          </YStack>
          <AnimatePresence>
            {data.length === 0 && internalValue.length > 0 && (
              <XStack
                marginTop="$3"
                marginBottom={recentSearches.length === 0 ? '$3' : '$0'}
                borderRadius="$3"
                backgroundColor="$pink10"
                alignItems="center"
                paddingHorizontal="$2"
                paddingVertical="$1"
                animation="300ms"
                enterStyle={{ opacity: 0 }}
                exitStyle={{ opacity: 0 }}>
                <Icon.AlertCircle minWidth="$3" />
                <YStack marginLeft="$2" flex={1}>
                  <Text fontWeight="600">{getString('redSkySearch.form.airports.error.title')}</Text>
                  <Text>{getString('redSkySearch.form.airports.error.body')}</Text>
                </YStack>
              </XStack>
            )}
          </AnimatePresence>
          {recentSearches.length > 0 && (
            <>
              <YStack paddingBottom="$2" marginTop="$3">
                <ListSectionHeader>{getString('redSkySearch.searchInput.form.recentSearches')}</ListSectionHeader>
                <YStack gap="$1" tag="ul" style={{ listStyle: 'none' }} padding="$0" margin="$0">
                  {recentSearches.map((airport) => (
                    <li key={airport.code}>
                      <SearchResult
                        id={getTrackingId('search-form', title, airport.airportName)}
                        onPress={() => onSelectResult(airport)}
                        name={airport.airportName}
                      />
                    </li>
                  ))}
                </YStack>
              </YStack>
              <Separator />
            </>
          )}
          <ScrollView contentContainerStyle={{ paddingTop: '$2' }}>
            {regions.map((region) => (
              <YStack key={region} marginBottom="$3">
                <ListSectionHeader>{region}</ListSectionHeader>
                {airportGroupsByRegion[region].map((airport) => (
                  <SearchResult
                    key={airport.code}
                    id={getTrackingId('search-form', title, airport.airportName)}
                    onPress={() => onSelectResult(airport)}
                    name={airport.airportName}
                  />
                ))}
              </YStack>
            ))}
          </ScrollView>
        </Form>
        <Button height="$6" onPress={onClose} variant="secondary">
          {getString('actions.back')}
        </Button>
      </YStack>
    </>
  )
}
