import { Icon, Tooltip, XStack, Text, Button } from '@red-ui/components'
import React, { useEffect, useRef, useState } from 'react'

type Props = {
  viewWidth: number
  iconAriaLabel?: string
  closeAriaLabel?: string
  text: string
  title: string
}

export const AlertTooltip = ({ viewWidth, iconAriaLabel, closeAriaLabel, title, text }: Props) => {
  const [openToolTip, setOpenToolTip] = useState(false)
  const contentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleOutsideClick = ({ target }: MouseEvent) => {
      if (!contentRef.current?.contains(target as HTMLElement)) {
        setOpenToolTip(false)
      }
    }
    openToolTip && document.addEventListener('mousedown', handleOutsideClick)
    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [openToolTip])

  return (
    <Tooltip placement="top-start" open={openToolTip}>
      <Tooltip.Trigger onPress={() => setOpenToolTip(true)}>
        <Tooltip.Anchor hoverStyle={{ cursor: 'pointer' }}>
          <Icon.AlertCircle width="$2" height="$2" $gtTabletPortrait={{ marginTop: '$0.25' }} aria-label={iconAriaLabel} />
        </Tooltip.Anchor>
      </Tooltip.Trigger>
      <Tooltip.Content
        width={viewWidth}
        ref={contentRef}
        shadowColor="$black10"
        shadowOffset={{ width: 0, height: 0 }}
        shadowRadius="$4"
        shadowOpacity={0.3}
        borderRadius="$6"
        padding="13px"
        marginHorizontal="$5"
        backgroundColor="$white"
        paddingBottom="15px"
        borderBlockColor="$white">
        <Tooltip.Arrow size="$5" backgroundColor="$white" />
        <XStack width="100%" justifyContent="space-between">
          <Text fontSize="$6" $mobile={{ fontSize: '$4' }} $tabletPortrait={{ fontSize: '$5' }} fontWeight="500" textAlign="justify">
            {title}
          </Text>
          <Button
            width="$2"
            height="$3"
            justifyContent="center"
            hoverStyle={{
              backgroundColor: '$white',
              borderColor: '$white',
            }}
            pressStyle={{
              backgroundColor: '$white',
              borderColor: '$white',
            }}
            backgroundColor="$white"
            borderWidth="$0"
            icon={<Icon.X color="$black10" position="absolute" size="$3" aria-label={closeAriaLabel} />}
            onPress={() => setOpenToolTip(false)}
          />
        </XStack>
        <Text fontSize="$3" $gtTabletPortrait={{ fontSize: '$4' }}>
          {text}
        </Text>
      </Tooltip.Content>
    </Tooltip>
  )
}
