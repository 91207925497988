import React from 'react'
import { XStack, FormSelect } from '@red-ui/components'
import { DatePart } from './types'
import { MONTHS, generateDays, generateYears } from './constants'

const daysOptions = generateDays()
const yearsOptions = generateYears()
const monthsOptions = MONTHS

interface DateSelectsProps {
  isUSUser?: boolean
  dateOfBirth: {
    day: string
    month: string
    year: string
  }
  handleDateChange: (field: DatePart, value: string) => void
}

export const DateSelects = React.memo(({ isUSUser, dateOfBirth, handleDateChange }: DateSelectsProps) => {
  if (!isUSUser) {
    return (
      <XStack gap="$2">
        <FormSelect
          options={daysOptions}
          value={dateOfBirth.day}
          onValueChange={(value) => handleDateChange('day', value)}
          placeholder="DD"
        />
        <FormSelect
          options={monthsOptions}
          value={dateOfBirth.month}
          onValueChange={(value) => handleDateChange('month', value)}
          placeholder="MM"
        />
        <FormSelect
          options={yearsOptions}
          value={dateOfBirth.year}
          onValueChange={(value) => handleDateChange('year', value)}
          placeholder="YYYY"
        />
      </XStack>
    )
  }

  return (
    <XStack gap="$2">
      <FormSelect
        options={monthsOptions}
        value={dateOfBirth.month}
        onValueChange={(value) => handleDateChange('month', value)}
        placeholder="MM"
      />
      <FormSelect
        options={daysOptions}
        value={dateOfBirth.day}
        onValueChange={(value) => handleDateChange('day', value)}
        placeholder="DD"
      />
      <FormSelect
        options={yearsOptions}
        value={dateOfBirth.year}
        onValueChange={(value) => handleDateChange('year', value)}
        placeholder="YYYY"
      />
    </XStack>
  )
})

DateSelects.displayName = 'DateSelects'
