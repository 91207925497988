import React, { useCallback, useState } from 'react'
import { Accordion, AnimatePresence, Button, ScrollView, Text, View, XStack, YStack } from '@red-ui/components'
import { Question } from './Question'
import { getString } from '@vrw/data'
import { ListItem } from './ListItem'
import { DatePicker } from './DatePicker'
import { Header } from '../SideSheet/Header'
import { PassengerForm } from './PassengerForm'
import { OnChangeType, SearchForm } from '../SearchSideSheets/useSearchForm'
import { getTrackingId } from '../../helpers/getTrackingId'
import { fields } from '../SearchSideSheets'

export type Props = {
  onClose: () => void
  onFromInputOpen: () => void
  onToInputOpen: () => void
  title?: string
  editMode?: boolean
  form: SearchForm
  onChange: OnChangeType
  onSubmit: () => void
  currentQuestionIdx: number
  onChangeQuestionIdx: (idx: number) => void
}

export const QuestionForm = ({
  onClose,
  onFromInputOpen,
  onToInputOpen,
  title = getString('redSkySearch.title'),
  editMode = false,
  form,
  onChange,
  onSubmit,
  currentQuestionIdx,
  onChangeQuestionIdx,
}: Props) => {
  const [editAccordionState, setEditAccordionState] = useState<string[]>(fields)
  const isBackVisible = editMode || currentQuestionIdx !== 0
  const currentQuestion = fields[currentQuestionIdx]
  const primaryLabel = getString(editMode || currentQuestionIdx === fields.length - 1 ? 'redSkySearch.form.submit.cta' : 'actions.continue')
  const secondaryLabel = getString(editMode ? 'actions.cancel' : 'actions.back')

  const isFieldValid = (field: string) => {
    if (editMode) return true

    const fieldMappings: Record<string, boolean> = {
      departure: !!form.departure,
      destination: !!form.destination,
    }

    return fieldMappings[field] ?? true
  }

  const isAnswerValid = isFieldValid(currentQuestion)
  const isFormValid = fields.every(isFieldValid)

  const onSelectDate = useCallback(
    (value: Date) => {
      onChange('date', value)
    },
    [onChange]
  )

  const componentMap: Record<string, JSX.Element | null> = {
    departure: (
      <ListItem onPress={onFromInputOpen} label={form.departure?.airportName ?? getString('redSkySearch.question.departure.label')} />
    ),
    destination: (
      <ListItem onPress={onToInputOpen} label={form.destination?.airportName ?? getString('redSkySearch.question.destination.label')} />
    ),
    date: <DatePicker onSelect={onSelectDate} initialValue={form.date} />,
    passengers: <PassengerForm data={form} onChange={onChange} />,
  }

  const onContinue = () => {
    if (editMode || currentQuestionIdx >= fields.length - 1) {
      onSubmit()
      return
    }

    onChangeQuestionIdx(currentQuestionIdx + 1)
  }

  const onBack = () => {
    if (currentQuestionIdx > 0) onChangeQuestionIdx(currentQuestionIdx - 1)
  }

  const renderQuestion = (field: string, index: number) => {
    const isQuestionValid = fields.slice(0, index).every(isFieldValid)
    const canBeTriggered = editMode || (index !== currentQuestionIdx && (isFormValid || isQuestionValid))
    const isActive = editMode ? editAccordionState.includes(field) : currentQuestion === field

    const onPress = () => {
      if (isFormValid) {
        onChangeQuestionIdx(index)
        return
      }

      const isAdjacent = Math.abs(currentQuestionIdx - index) === 1

      if (!isAdjacent) return

      if (index < currentQuestionIdx) {
        onBack()
      } else if (isAnswerValid) {
        onContinue()
      }
    }

    return (
      <Question canBeTriggered={canBeTriggered} key={field} isActive={isActive} field={field} onPress={editMode ? undefined : onPress}>
        <View borderTopWidth={1} borderTopColor="$black2" paddingTop="$2" marginTop="$2">
          <Text marginBottom="$2" fontSize="$6" $mobile={{ fontSize: '$4' }} $tabletPortrait={{ fontSize: '$5' }}>
            {getString(`redSkySearch.question.${field}.info`)}
          </Text>
          {componentMap[field]}
        </View>
      </Question>
    )
  }

  return (
    <>
      <Header title={title} onClose={onClose} />
      <YStack flex={1} padding="$3" gap="$3">
        <ScrollView>
          {editMode ? (
            <Accordion type="multiple" gap="$2" collapsable onValueChange={setEditAccordionState} value={editAccordionState}>
              {fields.map(renderQuestion)}
            </Accordion>
          ) : (
            <Accordion type="single" gap="$2" collapsable={false} value={currentQuestion}>
              {fields.map(renderQuestion)}
            </Accordion>
          )}
        </ScrollView>
        <XStack gap="$2" $mobile={{ flexDirection: 'column' }}>
          <Button
            id={getTrackingId('search-form', primaryLabel)}
            flex={1}
            height="$6"
            onPress={onContinue}
            disabled={!isAnswerValid}
            disabledStyle={{ opacity: 0.5, backgroundColor: '$red6', cursor: 'auto' }}
            pressStyle={{ opacity: 0.5, backgroundColor: '$red6' }}>
            {primaryLabel}
          </Button>
          <AnimatePresence>
            {isBackVisible && (
              <Button
                flex={1}
                animation="200ms"
                exitStyle={{ opacity: 0 }}
                enterStyle={{ opacity: 0 }}
                height="$6"
                onPress={editMode ? onClose : onBack}
                theme="secondary">
                {secondaryLabel}
              </Button>
            )}
          </AnimatePresence>
        </XStack>
      </YStack>
    </>
  )
}
