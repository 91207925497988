import resolveLinks from 'contentful-resolve-response'
import { ContentfulBlock } from '@/components/ContentfulBlock/types'
import { useQuery } from '@tanstack/react-query'
import { rawContentfulDomain } from '@vrw/data/src/api/content.api'
import { ContentfulPageSEO, ContentfulLocale } from '@vrw/data/src/utils/contentfulPages'
import { withContentfulFetchRetry } from '@vrw/data/src/utils/httpClient'
import { Entry } from 'contentful'

type Resonse<T> = {
  data?: ContentfulBlock<T>
  seo?: ContentfulPageSEO
  isLoading: boolean
  isError: boolean
}

type FilteredData<T> =
  | {
      content?: ContentfulBlock<T>
      seo?: ContentfulPageSEO
    }
  | undefined

export const useContentBySlug = <T>({ slug, viewingRegion }: { slug: string; viewingRegion?: ContentfulLocale }): Resonse<T> => {
  const contentfulRawDomain = rawContentfulDomain()
  const url = `${contentfulRawDomain}&limit=2&include=10&content_type=page&order=sys.createdAt&fields.slug=${slug}${
    viewingRegion ? `&locale=${viewingRegion}` : ''
  }`

  const { data, isLoading, isError } = useQuery({
    queryKey: [slug, url],
    queryFn: () => withContentfulFetchRetry(url).then((res) => res.json()),
  })

  const defaultQueryResults = {
    isLoading,
    isError,
  }
  if (data) {
    const resolved = resolveLinks(data ?? {})

    const filteredData: FilteredData<T> = resolved.filter((entry: Entry<any>) => entry.fields.slug === slug)[0]?.fields

    return {
      data: filteredData?.content,
      seo: filteredData?.seo,
      ...defaultQueryResults,
    }
  }

  return {
    ...defaultQueryResults,
  }
}
