import React, { useState } from 'react'
import { ButtonProps, Dialog, Spinner, Image, Icon, XStack, Button, YStack, DynamicRichText } from '@red-ui/components'
import { useLocation, useSearchParams } from 'react-router-dom'
import { apiCreateApplication } from '@vrw/data/src/api/creditCard.api'
import { getString } from '@vrw/data'
import { errorHandler } from '@vrw/data/src/redux/errorHandler'
import { getIsAuthenticated } from '@vrw/data/src/redux/auth/selectors'
import { doLoginWeb } from '@vrw/data/src/redux/auth/dispatchers'
import { PUBLIC_URL, SYNCHRONY_APPLY_URL } from '@/config'
import { useAppDispatch, useAppSelector } from '@/redux/hooks/hooks'
import { useMutation } from '@tanstack/react-query'
import { useFeatureSelector, getFeatureIsLoading, selectFeatureById } from '@vrw/data/src/redux/features/features.selectors'
import { FeatureName } from '@vrw/data/src/redux/features/features.types'
import { PATHS } from '@/router/paths'
import { useGuestApplyModal } from '@/query/content/guestApplyModal'
import { RichTextRenderer } from '@/components/DynamicContentFactory/DynamicRichText/RichTextRenderer'

export const TriggerCreditApply = ({
  children,
  runCTAExperiment = false,
}: {
  runCTAExperiment?: boolean
  children: React.ReactElement<ButtonProps & { onClick?: ButtonProps['onPress'] }>
}) => {
  const [showGuestApplyModal, setShowGuestApplyModal] = useState(false)
  const [searchParams] = useSearchParams()
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const guestApplyMVPFlag = useAppSelector(useFeatureSelector(FeatureName.GUEST_APPLY_MVP))
  const guestApplyModalFlag = useAppSelector(useFeatureSelector(FeatureName.GUEST_APPLY_MODAL))
  const { data: content, isPending: contentPending } = useGuestApplyModal()
  const { skipButton, loginButton, guestApplyTitle, guestApplyBody, redirectTitle, redirectBody } = content ?? {}

  const { mutate, isPending, data } = useMutation({
    mutationFn: async () => {
      const siteCode = searchParams.get('siteCode') || undefined
      const accorId = searchParams.get('accorId') || undefined
      return apiCreateApplication({ isAuthenticated, siteCode, accorId })
    },
    onError: (error) => {
      // hack to handle the error in existing error handler ( all in redux currently )
      errorHandler(dispatch, error, () => ({ type: 'DUD_ACTION' }))
    },
    onSuccess: (data) => {
      window.location.assign(`${SYNCHRONY_APPLY_URL}?dsaToken=${data.token}`)
    },
  })

  const loginViaTransitionPage = () => dispatch(doLoginWeb(`${PATHS.REWARDS_CREDIT_CARD_TRANSITION}${location.search}`))

  const closeGuestApplyModal = () => setShowGuestApplyModal(false)

  const beginApplication = () => {
    closeGuestApplyModal()
    mutate()
  }

  const onClickHandler = () => {
    if (guestApplyModalFlag && !isAuthenticated) setShowGuestApplyModal(true)
    else if (guestApplyMVPFlag || isAuthenticated) mutate()
    else loginViaTransitionPage()
  }

  const ctaExperimentEnabled = useAppSelector((state) => selectFeatureById(state, FeatureName.CC_CTA_EXPERIMENT)) && runCTAExperiment
  const loading = useAppSelector(getFeatureIsLoading)
  const buttonText = ctaExperimentEnabled ? getString('apply.card.button.findOutMore') : children.props.children

  const synchronyTab = (
    <>
      <XStack alignItems="center" justifyContent="center" gap="$2">
        <Image
          source={{
            uri: `${PUBLIC_URL}/img/virgin-logo-circle.svg`,
            width: 50,
            height: 50,
          }}
          accessibilityLabel={getString('apply.virgin.logo.alt')}
        />
        <Icon.ChevronRight color="$red7" />
        <Image
          source={{
            uri: `${PUBLIC_URL}/img/synchrony-logo.jpg`,
            width: 130,
            height: 30,
          }}
          accessibilityLabel={getString('apply.synchrony.logo.alt')}
        />
      </XStack>
      <Dialog.Title textAlign="center" size="$5">
        {redirectTitle}
      </Dialog.Title>
      {redirectBody && (
        <XStack width={'92%'} alignSelf="center">
          <DynamicRichText
            fields={{
              text: redirectBody,
              colour: 'black',
              align: 'center',
            }}
            RichTextRenderer={RichTextRenderer}
          />
        </XStack>
      )}
      <Spinner size="large" color="$red7" />
    </>
  )

  const guestApplyTab = (
    <>
      <Dialog.Title textAlign="center" size="$6" fontWeight={'600'}>
        {guestApplyTitle}
      </Dialog.Title>
      {guestApplyBody && (
        <XStack width={'92%'} alignSelf="center">
          <DynamicRichText
            fields={{
              text: guestApplyBody,
              colour: 'black',
              align: 'center',
            }}
            RichTextRenderer={RichTextRenderer}
          />
        </XStack>
      )}
      <YStack gap="$1">
        <Button onPress={loginViaTransitionPage} size={'$6'} alignSelf="center" width="90%">
          <Button.Text fontSize={'$5'}>{loginButton}</Button.Text>
        </Button>
        <Button onPress={beginApplication} size={'$6'} variant="secondary" alignSelf="center" width={'90%'}>
          <Button.Text fontSize={'$5'}>{skipButton}</Button.Text>
        </Button>
      </YStack>
    </>
  )

  if (loading || contentPending) return <></>

  return (
    <>
      {/* React clone only understands html mappings :( */}
      {React.cloneElement(children, { onClick: onClickHandler, id: runCTAExperiment ? 'cc-cta' : '', children: buttonText })}
      <Dialog modal open={showGuestApplyModal || isPending || !!data}>
        <Dialog.Portal>
          <Dialog.Overlay
            onPress={closeGuestApplyModal}
            key="overlay"
            opacity={0.5}
            enterStyle={{ opacity: 0 }}
            exitStyle={{ opacity: 0 }}
            backgroundColor="$black10"
          />
          <Dialog.Content
            bordered
            size="$3"
            maxWidth="600px"
            key="content"
            animateOnly={['transform', 'opacity']}
            enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
            exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
            gap="$4"
            margin={'$1'}>
            {showGuestApplyModal ? guestApplyTab : synchronyTab}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog>
    </>
  )
}
