import { EntrySkeletonType } from 'contentful'
import { color } from '@virgin-core/styles'
import { Locale } from './helperFunctions'

export enum ContentfulLocale {
  EN_GB = 'en-GB',
  EN_US = 'en-US',
}

export type ContentfulEntry = EntrySkeletonType

export interface ContentfulObject {
  sys: {
    id: string
    contentType?: {
      sys: {
        id: string
      }
    }
  }
}

export interface ContentfulPageContent<T = any> extends ContentfulObject {
  fields: T
}

export interface ContentfulPageSEO extends ContentfulObject {
  fields: {
    name: string
    title: string
    description: string
  }
}

export interface ContentfulPageFields<T = any> {
  name: string
  title: string
  slug: string
  content: ContentfulPageContent<T>
  seo: ContentfulPageSEO
}

export interface ContentfulPage<T = any> {
  fields: ContentfulPageFields<T>
  metadata?: {
    tags: { sys: { id: string } }[]
  }
}

export const ContentfulTags = {
  REGION_GB: 'region.GB',
  REGION_US: 'region.US',
  REGION_GLOBAL: 'region.global',
  REGION_RESTRICTED: 'region.restricted',
}

export interface ContentfulImage {
  fields: {
    title: string
    description: string
    file?: {
      url: string
      details?: {
        image: {
          width: number
          height: number
        }
      }
    }
  }
}

export const contentTypeOf = (contentfulObject?: ContentfulObject) => {
  return contentfulObject?.sys.contentType?.sys.id
}

export const getSectionBackgroundColor = (section: ContentfulEntry) => {
  const bgColor = section?.fields.backgroundColour?.fields.colour ?? 'White'
  switch (bgColor) {
    case 'Virgin Red':
      return color.brandPrimary
    case 'Light Pink':
      return '#FCE7E6'
    case 'Light Green':
      return '#AAD4BB'
    case 'Light Grey':
      return color.light
    case 'White':
    default:
      return color.white
  }
}

export const convertLocaleToContentfulLocale = (locale?: Locale | string): ContentfulLocale | undefined => {
  return locale ? (`${locale.split('-')[0]}-${locale.split('-')[1].toUpperCase()}` as ContentfulLocale) : undefined
}

export const validatePageAccess = ({
  tags,
  locale,
  isAuthenticated,
}: {
  tags?: { sys: { id: string } }[]
  locale?: Locale | string
  isAuthenticated?: boolean
}): boolean => {
  const cLocale = convertLocaleToContentfulLocale(locale)

  const isGlobalRegionPage =
    !tags ||
    tags?.filter((tag) => tag.sys.id !== ContentfulTags.REGION_RESTRICTED).length === 0 ||
    tags?.some((tag) => tag.sys.id === ContentfulTags.REGION_GLOBAL)
  const isGBPage = tags?.some((tag) => tag.sys.id === ContentfulTags.REGION_GB)
  const isUSPage = tags?.some((tag) => tag.sys.id === ContentfulTags.REGION_US)
  const isRestrictedPage = tags?.some((tag) => tag.sys.id === ContentfulTags.REGION_RESTRICTED)

  const regionAccessGranted =
    isGlobalRegionPage || (isGBPage && cLocale === ContentfulLocale.EN_GB) || (isUSPage && cLocale === ContentfulLocale.EN_US)
  const authenticatedAccessGranted = !isRestrictedPage || isAuthenticated

  return !!(regionAccessGranted && authenticatedAccessGranted)
}

export enum ContentfulPageType {
  homePublicGb = 'pageHomeLoggedOut',
  homePrivateGb = 'pageHomeLoggedIn',
  homePublicUs = 'pageHomeLoggedOutUs',
  homePrivateUS = 'pageHomeLoggedInUs',
}
