import React from 'react'
import { Accordion, AnimatePresence, YStack, ScrollView, XStack, useMedia, Separator } from '@red-ui/components'
import { Header } from './Header'
import { SeatTypeCard } from './SeatTypeCard'
import { FlightPointsPrice } from './FlightPointsPrice'
import { getString } from '@vrw/data'
import { getTrackingId } from '../../helpers/getTrackingId'

export type Props = {
  value: string
  isActive: boolean
  onSelect: (flightId: string) => void
}

export const FlightDataCard = ({ value, isActive, onSelect }: Props) => {
  const { gtTabletPortrait } = useMedia()

  const seatTypeCards = (
    <>
      <SeatTypeCard onSelect={() => onSelect(value)} />
      <SeatTypeCard onSelect={() => onSelect(value)} />
      <SeatTypeCard onSelect={() => onSelect(value)} />
    </>
  )

  return (
    <Accordion.Item
      value={value}
      backgroundColor="$white"
      borderWidth={1}
      borderRadius="$4"
      borderColor={isActive ? '$black10' : '$black2'}>
      {/* ui lib auto makes a h1 tag for the accordion header, so we need to use a div tag */}
      <Accordion.Header tag="div" width="100%">
        <Accordion.Trigger
          id={getTrackingId('flight-card')}
          borderRadius="$4"
          cursor="pointer"
          backgroundColor="$white"
          unstyled
          width="100%"
          hoverStyle={{ backgroundColor: '$white' }}
          focusStyle={{ backgroundColor: '$white' }}>
          <YStack padding="$2" $gtMobile={{ padding: '$3' }} width="100%">
            <Header />
            {!isActive && (
              <>
                <Separator marginVertical="$2" $gtMobile={{ marginVertical: '$3' }} />
                <FlightPointsPrice
                  label={getString('redSkySearch.results.card.points.label.from')}
                  points={75000}
                  justifyContent="space-between"
                  $gtTabletLandscape={{ justifyContent: 'flex-end' }}
                />
              </>
            )}
          </YStack>
        </Accordion.Trigger>
      </Accordion.Header>
      <AnimatePresence>
        <Accordion.Content borderRadius="$4" unstyled exitStyle={{ opacity: 0 }} backgroundColor="$white">
          {gtTabletPortrait ? (
            <XStack paddingLeft="$3" marginBottom="$3">
              {seatTypeCards}
            </XStack>
          ) : (
            <ScrollView horizontal paddingLeft="$2" marginBottom="$2" $gtMobile={{ paddingLeft: '$3', marginBottom: '$3' }}>
              {seatTypeCards}
            </ScrollView>
          )}
        </Accordion.Content>
      </AnimatePresence>
    </Accordion.Item>
  )
}
