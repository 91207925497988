import { logger, fetchAndMergeRewards } from '../../utils'
import { updatePagination } from '../pagination/actions'
// import { DEFAULT_OFFSET } from '../pagination/types'
import { getFilteredRewards, getFilteredRewardsSuccess, getFilteredRewardsFailure, getFilteredRewardsSuccessPaginated } from './actions'

import { DispatchFunction, GetRedDataState } from '../types'
import { errorHandler } from '../errorHandler'
import {
  apiGetSpendRewardsByCategoryAndCategoryCostCount,
  apiGetSpendRewardsByCategoryAndCategoryCostCountPaginated,
} from '../../api/discovery.api'
import { updateRewards } from '../rewards/actions'
import { getSort } from '../discovery/selectors'
import { UPDATE_REWARDS_PAGINATION } from '../discovery/types'
import { getSelectedCategories, getSelectedCategoryCostCounts } from './selectors'

const doUpdateRewardsPaginated = (loadMoreClicked: boolean) => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  logger.log(`doUpdateRewardsPaginated()`)
  const categories = getSelectedCategories(getState())
  const categoryCostCounts = getSelectedCategoryCostCounts(getState())
  const sort = getSort(getState())
  // const { currentOffset } = getPagination(getState())

  dispatch(getFilteredRewards(loadMoreClicked))

  try {
    const { rewards, paging } = await apiGetSpendRewardsByCategoryAndCategoryCostCountPaginated({
      isPublicEndpoint: false,
      categoriesData: { categories, categoryCostCounts },
      limit: 1000,
      offset: 0,
      sort,
    })
    dispatch(updateRewards(rewards))

    dispatch(
      getFilteredRewardsSuccessPaginated({
        filters: { selectedCategories: categories, selectedCategoryCostCounts: categoryCostCounts },
        rewardIds: rewards.map((reward) => reward.rewardId),
        addToExistingRewardIds: loadMoreClicked,
      })
    )

    dispatch(updatePagination({ actionName: UPDATE_REWARDS_PAGINATION, paging, paginationCategories: categories, loadMoreClicked }))
  } catch (error) {
    errorHandler(dispatch, error, getFilteredRewardsFailure)
  }
}

const doUpdateRewards = (isBrands?: boolean) => async (dispatch: DispatchFunction, getState: GetRedDataState) => {
  const categories = getSelectedCategories(getState())
  const categoryCostCounts = getSelectedCategoryCostCounts(getState())
  const sort = getSort(getState())
  logger.log(`doFetchFilteredRewards()`)
  dispatch(getFilteredRewards())
  try {
    const rewards = await fetchAndMergeRewards(
      apiGetSpendRewardsByCategoryAndCategoryCostCount,
      categories,
      categoryCostCounts,
      sort,
      isBrands
    )

    dispatch(updateRewards(rewards))
    dispatch(
      getFilteredRewardsSuccess(
        { selectedCategories: categories, selectedCategoryCostCounts: categoryCostCounts },
        rewards.map((reward) => reward.rewardId)
      )
    )
  } catch (error) {
    errorHandler(dispatch, error, getFilteredRewardsFailure)
  }
}
export { doUpdateRewards, doUpdateRewardsPaginated }
