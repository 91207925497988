import { PointsRange } from '../redux/categories/types'
import { Buffer } from 'buffer'
import { Reward } from '../redux/rewards/types'

// https://stackoverflow.com/questions/39468022/how-do-i-know-if-my-code-is-running-as-react-native/39473604
export const isWeb = () => {
  return typeof document !== 'undefined'
}

export const numberWithCommas = (x: number | string): string => {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

type StyledTextResults = {
  text: string
  style: {
    fontWeight?: '600'
    color?: '$red6'
  }
}[]
export const parseStyledText = (input: string): StyledTextResults => {
  const regex = /\{\{bold\}\}([\s\S]*?)\{\{\/bold\}\}/g
  const colorRegex = /\{\{red\}\}([\s\S]*?)\{\{\/red\}\}/g
  const result = []
  let lastIndex = 0

  input.replace(regex, (match: string, boldText: string, index: number) => {
    // Add text before the bold section
    if (index > lastIndex) {
      result.push({ text: input.substring(lastIndex, index), style: {} })
    }

    // Check if the bold section contains a red text section
    let styledText = boldText
    const boldStyle = { fontWeight: '600' }

    styledText = styledText.replace(colorRegex, (colorMatch, redText) => {
      result.push({ text: redText, style: { ...boldStyle, color: '$red6' } })
      return ''
    })

    if (styledText.trim()) {
      result.push({ text: styledText, style: boldStyle })
    }

    lastIndex = index + match.length
    return ''
  })

  // Add any remaining text
  if (lastIndex < input.length) {
    result.push({ text: input.substring(lastIndex), style: {} })
  }

  return result
}

export const getPointsLabels = (obj: PointsRange): string[] => {
  const labels: string[] = []
  Object.entries(obj).forEach((item) => {
    if (item[0].includes('+')) {
      const parts = item[0].split('+')
      if (parts.length > 0) labels.push(numberWithCommas(parts[0]) + '+')
    } else if (item[0].includes('-')) {
      const parts = item[0].split('-')
      if (parts.length > 1) labels.push(numberWithCommas(parts[0]) + ' - ' + numberWithCommas(parts[1]))
    }
  })
  return labels
}

export enum Locale {
  EN_GB = 'en-gb',
  EN_US = 'en-us',
}

export enum ViewingRegion {
  GB = 'GB',
  US = 'US',
}

export enum LinkingJourneyOrigins {
  ONBOARDING = 'onboarding',
  ACCOUNT = 'account',
  PARTNER = 'partner',
}

export enum ViewingRegionDisplayName {
  GB = 'United Kingdom',
  US = 'United States',
}

export const getRegionDisplayName = (key: ViewingRegion): ViewingRegionDisplayName => ViewingRegionDisplayName[key]

export const getLocalisedString = (region?: ViewingRegion) => (region !== ViewingRegion.GB ? `.${region}` : '')

export const parseDigits = (input?: string) => (input?.match(/\d+/g) || []).join('')

export const delay = async (delayTime: number) => new Promise((resolve) => setTimeout(resolve, delayTime))

export const jsonToBase64 = <Record>(object: Record) => {
  const json = JSON.stringify(object)
  return Buffer.from(json).toString('base64')
}

/**
 * Fetches rewards from API calls and handles merging for both branded and non-branded rewards
 * @param apiFn The API function to call
 * @param categories Selected categories for filtering
 * @param categoryCostCounts Selected category cost counts for filtering
 * @param sort Sort parameter
 * @param isBrands Whether to fetch branded rewards
 * @returns Array of unique rewards
 */

export const fetchAndMergeRewards = async (
  apiFn: (categoriesData: { categories: any; categoryCostCounts: any }, sort: any, isBrandOnly?: boolean) => Promise<Reward[] | undefined>,
  categories: any,
  categoryCostCounts: any,
  sort: any,
  isBrands?: boolean
): Promise<Reward[]> => {
  if (isBrands) {
    // Fetch both branded and non-branded rewards in parallel
    const [brandOnlyRewards, normalRewards] = await Promise.all([
      apiFn({ categories, categoryCostCounts }, sort, true),
      apiFn({ categories, categoryCostCounts }, sort, false),
    ])

    // Merge and deduplicate rewards
    const allRewards = [
      ...(Array.isArray(brandOnlyRewards) ? brandOnlyRewards : []),
      ...(Array.isArray(normalRewards) ? normalRewards : []),
    ]

    // Create a Map to deduplicate by rewardId
    const uniqueRewardsMap = new Map<string, Reward>()

    allRewards.forEach((reward) => {
      uniqueRewardsMap.set(reward.rewardId, reward)
    })

    return Array.from(uniqueRewardsMap.values())
  } else {
    // Just fetch normal rewards
    const rewards = await apiFn({ categories, categoryCostCounts }, sort, false)
    return Array.isArray(rewards) ? rewards : []
  }
}
