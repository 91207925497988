import React from 'react'
import { View, Separator, XStack, Text } from '@red-ui/components'
import { formatPoints } from '@vrw/data/src/utils/formatters'
import { getString } from '@vrw/data'

export const PointsSummary = () => {
  return (
    <View borderWidth={1} borderColor="$black2" borderRadius="$3" padding="$2">
      <XStack justifyContent="space-between" alignItems="flex-end">
        <Text fontSize="$4" $gtMobile={{ fontSize: '$5' }} $gtTabletLandscape={{ fontSize: '$6' }}>
          {getString('redSkySearch.results.sidesheet.summary.points.label')}
        </Text>
        <Text fontWeight="700" fontSize="$7" $gtTabletLandscape={{ fontSize: '$9' }}>
          {formatPoints({ points: 75000 })}
        </Text>
      </XStack>
      <Separator marginVertical="$1" />
      <Text fontSize="$3" $gtTabletLandscape={{ fontSize: '$4' }} color="$black6">
        {getString('redSkySearch.results.sidesheet.summary.points.subtitle')}
      </Text>
    </View>
  )
}
