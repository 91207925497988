import React from 'react'
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, Document, Block, Inline } from '@contentful/rich-text-types'
import { DynamicPlaceholder, dynamicRichTextOptionOverrides, styled, YStack, Text, View } from '@red-ui/components'
import { contentTypeOf } from '@vrw/data/src/utils/contentfulPages'
import { isUrlExternal } from '../../../helpers/urlHelper'
import { Link } from '../../Link'
import { DynamicButton } from '../DynamicButton'
import { DynamicImage } from '../DynamicImage'

export const Mark = styled(Text, {
  color: 'inherit',
  fontSize: 'unset',
})

const getEmbeddedContent = (node: Block | Inline, isInline?: boolean) => {
  const contentType = contentTypeOf(node.data?.target)
  const isButton = contentType === 'dynamicButton'
  let component: JSX.Element

  switch (contentType) {
    case 'dynamicButton':
      component = <DynamicButton fields={node.data?.target.fields} />
      break
    case 'dynamicImage':
      component = <DynamicImage fields={node.data?.target.fields} isEmbed />
      break
    default:
      component = <DynamicPlaceholder content={node.data?.target} />
      break
  }

  return isInline ? (
    <View display="inline-flex" marginBottom="$1" style={{ verticalAlign: 'middle' }}>
      {component}
    </View>
  ) : (
    <View marginBottom="$2" $mobile={isButton && { width: '100%' }}>
      {component}
    </View>
  )
}

const optionOverrides: Options = {
  ...dynamicRichTextOptionOverrides,
  renderNode: {
    ...dynamicRichTextOptionOverrides.renderNode,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => getEmbeddedContent(node),
    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri} external={isUrlExternal(node.data.uri)} asChild children={<Mark>{children}</Mark>} />
    ),
    [INLINES.EMBEDDED_ENTRY]: (node) => getEmbeddedContent(node, true),
  },
}

export const RichTextRenderer = YStack.styleable<{ content: Document; options?: Options }>(
  ({ content, options = optionOverrides, ...props }, ref) => (
    <YStack {...props} ref={ref}>
      {documentToReactComponents(content, options)}
    </YStack>
  )
)
