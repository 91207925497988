import { apiGetAllOrders, apiPostSubmitOrder } from '../../api/orders.api'
import { getRedDataConfig } from '../../config'
import { getString, logger } from '../../utils'
import { apiErrors } from '../../utils/httpClient'
import { doStepDown, doStepUpWeb } from '../auth/dispatchers'
import { showDialog } from '../dialog/actions'
import { DialogType } from '../dialog/types'
import { errorHandler } from '../errorHandler'
import { RedemptionType, Reward } from '../rewards/types'
import { DispatchFunction, GetRedDataState } from '../types'
import { showWalletBadge } from '../wallet/actions'
import { BadgeType } from '../wallet/types'
import {
  getOrders,
  getOrdersFailure,
  getOrdersSuccess,
  startOrder,
  submitOrder,
  submitOrderFailure,
  submitOrderSuccess,
  updateGiftMessage,
  updateOrderConsent,
  updateOrderDeliveryAddress,
} from './actions'
import { Consent, DeliveryAddress, OrderContent, OrderRequest, PersonDetails, VoyagesOrderRequest, SailorDetails } from './types'
import { apiPostVoyagesOrder } from '../../api/voyagesOrders.api'

export const doFetchOrders = () => async (dispatch: DispatchFunction) => {
  logger.log('doFetchOrders()')
  dispatch(getOrders())
  try {
    const data = await apiGetAllOrders()
    dispatch(getOrdersSuccess(data))
  } catch (error) {
    logger.warn(`orders/dispatchers.ts doFetchOrders(): ${error}`)
    errorHandler(dispatch, error, getOrdersFailure)
  }
}

const doViewOrderConfirmation = (orderId: string, onClose?: string) => (dispatch: DispatchFunction, getState: GetRedDataState) => {
  const { redemptionType, rewardId } = getState().orders.data.entities[orderId]
  const campaignId = getState().rewards.data?.entities[rewardId]?.campaignId
  let confirmationRoute
  const config = getRedDataConfig()
  switch (redemptionType) {
    case RedemptionType.DIGITAL_FULFILMENT:
      confirmationRoute = config.navTargets.DigitalOrderConfirmation
      break
    case RedemptionType.WINES_FULFILMENT:
    case RedemptionType.VOYAGES_FULFILMENT:
    case RedemptionType.PHYSICAL_SPARKLES:
      confirmationRoute = config.navTargets.OrderConfirmation
      break
    case RedemptionType.DONATION_LINK:
      confirmationRoute = config.navTargets.DonationConfirmation
      break
    default:
      dispatch(doStepDown())
      throw new Error(`orders/dispatchers.ts:doViewOrderConfirmation(): Unknown order redemption type ${redemptionType}`)
  }
  dispatch(doStepDown())
  config.navigate(confirmationRoute, {
    orderId,
    rewardId,
    campaignId,
    onClose: () => (onClose ? config.navigate(onClose) : config.navigateBack()),
  })
}

const doStartOrder =
  (rewardId: string, rewardParentId: string, content: OrderContent) => (dispatch: DispatchFunction, getState: GetRedDataState) => {
    const inProgressOrder = getState().orders.inProgress
    if (!inProgressOrder || inProgressOrder.rewardId !== rewardId) {
      dispatch(startOrder(rewardId, rewardParentId, content))
    }
  }

const doUpdateOrderDeliveryAddress = (address: DeliveryAddress) => (dispatch: DispatchFunction) => {
  const trimmedAddressFields = Object.keys(address).reduce(
    (acc, item) => {
      acc[item as any] = address[item as any].trim()
      return acc
    },
    {} as Record<keyof DeliveryAddress, string>
  )

  dispatch(updateOrderDeliveryAddress(trimmedAddressFields))
}

const doUpdateOrderConsent = (consent: Consent) => (dispatch: DispatchFunction) => {
  dispatch(updateOrderConsent(consent))
}

const doUpdateGiftMessage = (giftMessage: string | null) => (dispatch: DispatchFunction) => {
  dispatch(updateGiftMessage(giftMessage))
}

const mapSailorDetailsToPersonDetails = (sailorDetails: SailorDetails): PersonDetails => {
  const { dateOfBirth, firstName, lastName, email, gender, mobileNumber, citizenship } = sailorDetails ?? {}
  const formattedDateOfBirth = dateOfBirth
    ? `${dateOfBirth.year}-${dateOfBirth.month.padStart(2, '0')}-${dateOfBirth.day.padStart(2, '0')}`
    : ''

  return {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    email: email ?? '',
    dateOfBirth: formattedDateOfBirth,
    phoneNumber: mobileNumber?.number ?? '',
    sex: gender ?? '',
    countryAccessCode: mobileNumber?.countryCode ?? '',
    citizenship: citizenship ?? '',
  }
}

const doSubmitOrder =
  (order: OrderRequest | VoyagesOrderRequest, stepUp = false, reward?: Reward) =>
  // eslint-disable-next-line consistent-return
  async (dispatch: DispatchFunction) => {
    logger.log(`doSubmitOrder()`)
    const config = getRedDataConfig()
    dispatch(submitOrder())
    try {
      let data
      if (order.sailorDetails && reward && reward?.redemptionType === RedemptionType.VOYAGES_FULFILMENT) {
        const { sailorDetails, ...orderBase } = order
        const personDetails = mapSailorDetailsToPersonDetails(sailorDetails)
        const orderWithPersonDetails: VoyagesOrderRequest = {
          ...orderBase,
          personDetails,
          expectedPoints: reward?.cost,
        }
        data = await apiPostVoyagesOrder(orderWithPersonDetails, stepUp)
        data = data.order
      } else {
        data = await apiPostSubmitOrder(order, stepUp)
      }

      await dispatch(submitOrderSuccess(data))
      await dispatch(showWalletBadge(BadgeType.NEW_ORDER))
      dispatch(doViewOrderConfirmation(data.orderId, config.navTargets.MyOrders))
    } catch (error) {
      const message = (error as Error)?.message
      if (message === apiErrors.NOT_ACCEPTABLE) {
        return dispatch(doStepUpWeb())
      } else if (message === apiErrors.VUA) {
        error = {
          ...(error as Error),
          message: getString('orders.checkout.outOfStock'),
        }
      }
      dispatch(doStepDown())
      errorHandler(dispatch, error, submitOrderFailure)
    }
  }

const doPromptSubmitOrder =
  (redemptionType: RedemptionType | undefined, reward?: Reward) => (dispatch: DispatchFunction, getState: GetRedDataState) => {
    logger.log(`doPromptSubmitOrder()`)

    dispatch(
      showDialog({
        callbackOnConfirm: () => dispatch(doSubmitOrder(getState().orders.inProgress, false, reward)),
        type: DialogType.ALERT,
        callbackOnCancel: () => dispatch(submitOrderFailure('User cancelled redemption')),
        titleTextId:
          redemptionType === RedemptionType.DONATION_LINK
            ? 'orders.checkout.confirmPopupDonation.title'
            : 'orders.checkout.confirmPopup.title',
        bodyTextId:
          redemptionType === RedemptionType.DONATION_LINK
            ? 'orders.checkout.confirmPopupDonation.description'
            : 'orders.checkout.confirmPopup.description',
        buttonCancelTextId: 'actions.cancel',
        buttonConfirmTextId: 'actions.confirmOrder',
      })
    )
  }

export {
  doViewOrderConfirmation,
  doStartOrder,
  doUpdateOrderDeliveryAddress,
  doUpdateOrderConsent,
  doUpdateGiftMessage,
  doSubmitOrder,
  doPromptSubmitOrder,
}
