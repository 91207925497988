import React, { useEffect } from 'react'
import { DynamicComposedPage } from '../DynamicComposedPage'
import { pushToGTM } from '../../helpers/windowMethods'
import { Helmet } from 'react-helmet'

const ErrorPage404 = () => {
  useEffect(() => {
    pushToGTM({ event: 'error_404' })
  })

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div data-testid="404">
        <DynamicComposedPage slugName="404" useStatic404Fallback />
      </div>
    </>
  )
}

export { ErrorPage404 }
