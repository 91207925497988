import { datadogRum } from '@datadog/browser-rum'
import {
  ENV,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_SERVICE_NAME,
  API_URL,
  B2C_API_URL,
  BRAZE_JWT_URL,
  MENTION_ME_API_URL,
  RED_API_URL,
  IS_DEV_MODE,
  APP_VERSION,
} from '@/config'
import { logger } from '@vrw/data/src/utils'

const trimUrlPrefix = (url: string) => url.replace(/^https?:\/\//, '')

const isInitialized = () => datadogRum.getInternalContext()?.session_id !== undefined

export const initDatadog = (userId?: string) => {
  if (IS_DEV_MODE) {
    // datadog is disabled in dev mode to ensure we don't pollute live monitoring with local dev data
    console.log('>>>>> Datadog RUM is disabled in dev mode')
    return
  }

  if (!DATADOG_APPLICATION_ID || !DATADOG_CLIENT_TOKEN || !DATADOG_SERVICE_NAME) throw new Error('Missing required datadog env variables')

  if (!isInitialized()) {
    logger.log('>>>>> Enabled RUM')
    datadogRum.init({
      allowedTracingUrls: [API_URL, B2C_API_URL, BRAZE_JWT_URL, MENTION_ME_API_URL, RED_API_URL].map(trimUrlPrefix),
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      defaultPrivacyLevel: 'mask-user-input',
      enableExperimentalFeatures: ['feature_flags'],
      env: ENV,
      service: DATADOG_SERVICE_NAME,
      sessionReplaySampleRate: 20,
      sessionSampleRate: 40,
      site: 'datadoghq.eu',
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
      version: APP_VERSION,
    })
  }

  if (userId) {
    datadogRum.setUser({
      id: userId,
      name: userId,
    })
  }

  // allows us to have screen recordings of users interactions
  datadogRum.startSessionReplayRecording()
}

export const addFeatureFlagEvaluation: typeof datadogRum.addFeatureFlagEvaluation = (...args) => {
  if (!isInitialized()) return

  datadogRum.addFeatureFlagEvaluation(...args)
}
