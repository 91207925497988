import React, { useState, useCallback } from 'react'
import { Dialog, FormInput, FormSelect, XStack, YStack, Text, Icon } from '@red-ui/components'
import { sailorDetailsSchema, validateField, validateForm } from './validation'
import { COUNTRY_CODES, GENDERS, CITIZENSHIP } from './constants'
import { DialogSailorDetailsProps, SailorDetailsFormValues, SailorDetailsErrorState, DatePart, MobileNumberField } from './types'
import { getString } from '@vrw/data/src/utils'
import { DateSelects } from './DateSelects'
import {
  StyledCloseButton,
  StyledButtonWrapper,
  StyledButton,
  StyledDialogContent,
  StyledTitleWrapper,
  StyledTitle,
  StyledDescription,
} from './styles'

const citizenshipOptions = CITIZENSHIP
const genderOptions = GENDERS
const countryCodesOptions = COUNTRY_CODES

export const DialogSailorDetails = ({
  visible,
  onClose = () => {},
  onSubmit = () => {},
  initialValues = {},
  isUSUser,
}: DialogSailorDetailsProps) => {
  const [formValues, setFormValues] = useState<SailorDetailsFormValues>({
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: {
      day: '',
      month: '',
      year: '',
    },
    gender: '',
    citizenship: isUSUser ? 'US' : 'GB',
    mobileNumber: {
      countryCode: isUSUser ? '+1' : '+44',
      number: '',
    },
    ...initialValues,
  })

  const [errors, setErrors] = useState<SailorDetailsErrorState>({})

  const handleCancel = useCallback(() => {
    setErrors({})
    onClose()
  }, [onClose])

  const handleSubmit = async () => {
    // Validate entire form
    const newErrors = await validateForm(formValues)
    setErrors(newErrors)
    if (Object.keys(newErrors).length === 0) {
      onSubmit(formValues)
      onClose()
    }
  }

  const handleChange = useCallback(async (field: keyof SailorDetailsFormValues, value: string) => {
    setFormValues((prev) => ({ ...prev, [field]: value }))
    validateField(sailorDetailsSchema, field, value)
    const newError = await validateField(sailorDetailsSchema, field, value)
    setErrors((prev) => ({ ...prev, [field]: newError }))
  }, [])

  const handleDateChange = useCallback(
    async (field: DatePart, value: string) => {
      setFormValues((prev) => ({
        ...prev,
        dateOfBirth: { ...prev.dateOfBirth, [field]: value },
      }))
      const newError = await validateField(sailorDetailsSchema, 'dateOfBirth', {
        ...formValues.dateOfBirth,
        [field]: value,
      })
      setErrors((prev) => ({ ...prev, dateOfBirth: newError }))
    },
    [formValues.dateOfBirth]
  )

  const handleNumberOrCountryCodeChange = useCallback(
    async (field: MobileNumberField, value: string) => {
      setFormValues((prev) => ({
        ...prev,
        mobileNumber: { ...prev.mobileNumber, [field]: value },
      }))
      const newError = await validateField(sailorDetailsSchema, 'mobileNumber', {
        ...formValues.mobileNumber,
        [field]: value,
      })
      setErrors((prev) => ({ ...prev, mobileNumber: newError }))
    },
    [formValues.mobileNumber]
  )

  return (
    <Dialog modal open={visible}>
      <Dialog.Portal>
        <Dialog.Overlay key="overlay" animation="quick" opacity={0.5} enterStyle={{ opacity: 0 }} exitStyle={{ opacity: 0 }} />
        <StyledDialogContent
          bordered
          elevate
          key="content"
          animateOnly={['transform', 'opacity']}
          animation={[
            'quick',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
          exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}>
          <YStack gap="$3" padding="$3" marginBottom="$4" width="100%">
            <StyledTitleWrapper>
              <StyledTitle>{getString('virginVoyages.dialog.title')}</StyledTitle>
              <StyledDescription>
                {getString('virginVoyages.dialog.description')}
                <Text fontWeight="600">{`\n${getString('virginVoyages.dialog.description.primarySailor')}`}</Text>
              </StyledDescription>
            </StyledTitleWrapper>

            <YStack gap="$2">
              <FormInput
                label={getString('virginVoyages.dialog.firstName')}
                value={formValues.firstName}
                onChange={(e) => handleChange('firstName', e.nativeEvent.text)}
                error={!!errors.firstName}
                touched={true}
                aria-label="First name input">
                <FormInput.ErrorText>{errors.firstName}</FormInput.ErrorText>
              </FormInput>
              <FormInput
                label={getString('virginVoyages.dialog.lastName')}
                value={formValues.lastName}
                onChange={(e) => handleChange('lastName', e.nativeEvent.text)}
                error={!!errors.lastName}
                touched={true}
                aria-label="Last name input">
                <FormInput.ErrorText>{errors.lastName}</FormInput.ErrorText>
              </FormInput>
              <FormInput
                label={getString('virginVoyages.dialog.email')}
                value={formValues.email}
                onChange={(e) => handleChange('email', e.nativeEvent.text)}
                error={!!errors.email}
                touched={true}
                aria-label="Email input">
                <FormInput.ErrorText>{errors.email}</FormInput.ErrorText>
              </FormInput>
              <YStack>
                <Text color={errors.dateOfBirth ? '$red6' : '$black9'}>{getString('virginVoyages.dialog.dateOfBirth')}</Text>
                <XStack space="$2">
                  <DateSelects isUSUser={isUSUser} dateOfBirth={formValues.dateOfBirth} handleDateChange={handleDateChange} />
                </XStack>
                {errors.dateOfBirth && <FormSelect.ErrorText>{errors.dateOfBirth}</FormSelect.ErrorText>}
              </YStack>
              <FormSelect
                label={getString('virginVoyages.dialog.gender')}
                options={genderOptions}
                value={formValues.gender}
                onValueChange={(value) => handleChange('gender', value)}
                error={!!errors.gender}
                touched={true}
                aria-label="Gender input">
                <FormSelect.ErrorText>{errors.gender}</FormSelect.ErrorText>
              </FormSelect>
              <FormSelect
                label={getString('virginVoyages.dialog.citizenship')}
                options={citizenshipOptions}
                value={formValues.citizenship}
                onValueChange={(value) => handleChange('citizenship', value)}
                error={!!errors.citizenship}
                touched={true}
                aria-label="Citizenship input">
                <FormSelect.ErrorText>{errors.citizenship}</FormSelect.ErrorText>
              </FormSelect>
              <YStack>
                <XStack gap="$2">
                  <FormSelect
                    label={getString('virginVoyages.dialog.mobileNumber')}
                    options={countryCodesOptions}
                    value={formValues.mobileNumber.countryCode}
                    onValueChange={(value) => handleNumberOrCountryCodeChange('countryCode', value)}
                    touched={true}
                    aria-label="Phone country code input"
                  />
                  <FormInput
                    label=" "
                    value={formValues.mobileNumber.number}
                    onChange={(e) => handleNumberOrCountryCodeChange('number', e.nativeEvent.text)}
                    error={!!errors.mobileNumber}
                    touched={true}
                    aria-label="Phone number input"
                  />
                </XStack>
                {errors.mobileNumber && <FormInput.ErrorText>{errors.mobileNumber}</FormInput.ErrorText>}
              </YStack>
            </YStack>
          </YStack>

          <StyledButtonWrapper>
            <StyledButton variant="secondary" onPress={handleCancel}>
              {getString('virginVoyages.dialog.cancel')}
            </StyledButton>
            <StyledButton onPress={handleSubmit}>{getString('virginVoyages.dialog.continue')}</StyledButton>
          </StyledButtonWrapper>
          <Dialog.Close asChild>
            <StyledCloseButton onPress={handleCancel} aria-label="Close dialog">
              <Icon.X color="$black9" size={'$3'} />
            </StyledCloseButton>
          </Dialog.Close>
        </StyledDialogContent>
      </Dialog.Portal>
    </Dialog>
  )
}
