import React, { FC } from 'react'
import { getCurrentPage } from '@vrw/data/src/redux/pages/selectors'
import { useAppSelector } from '../../redux/hooks/hooks'
import { AuthWrapper, Page } from '../../components'
import { HomePrivateContainer } from './HomeContainer'
import { HomePublic } from './HomePublic'

export const Home: FC = () => {
  const homePage = useAppSelector(getCurrentPage)
  const publicSEOFields = homePage?.fields.seo?.fields

  return (
    <Page virtualPagePath="/virgin-red/*" testId="page-home" title={publicSEOFields?.title} metaDescription={publicSEOFields?.description}>
      <AuthWrapper publicComponent={<HomePublic />} privateComponent={<HomePrivateContainer />} />
    </Page>
  )
}
