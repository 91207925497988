import { connect } from 'react-redux'
import { doUpdateRewardsEarnPublicPaginated } from '@vrw/data/src/redux/discoveryEarnPublic/dispatchers'
import { doFetchRewardsEarnPublic } from '@vrw/data/src/redux/rewardsEarnPublic/dispatchers'
import {
  getIsFiltersChangedEarnPublic,
  getIsLoadingEarnPublic,
  getVisibleEarnPublicRewardIds,
} from '@vrw/data/src/redux/discoveryEarnPublic/selectors'
import {
  getRewardsIds as getAllEarnRewardsIds,
  getIsLoading as getIsLoadingAllEarn,
  getHasLoaded as getHasLoadedAllEarn,
  getRewardsEntities,
  getEarnPublicPagination,
} from '@vrw/data/src/redux/rewardsEarnPublic/selectors'
import { bindTypedActionCreators } from '../../helpers/bindTypedActionCreators'
import { RootState, DispatchFunction } from '../../redux/types'
import { DeprecatedRewardsList } from './DeprecatedRewardsList'
import { toggleCategorySelectedEarnPublic } from '@vrw/data/src/redux/discoveryEarnPublic/actions'
import { getCategoriesEarnPublic } from '@vrw/data/src/redux/categoriesEarnPublic/selectors'

const mapStateToProps = (state: RootState, ownProps: { ignoreFilters?: boolean }) => {
  return {
    categories: getCategoriesEarnPublic(state),
    rewards: getRewardsEntities(state),
    rewardIds: ownProps.ignoreFilters ? getAllEarnRewardsIds(state) : getVisibleEarnPublicRewardIds(state),
    earn: true,
    isLoading: ownProps.ignoreFilters ? getIsLoadingAllEarn(state) : getIsLoadingEarnPublic(state),
    hasLoaded: ownProps.ignoreFilters ? getHasLoadedAllEarn(state) : !getIsFiltersChangedEarnPublic(state),
    pagination: getEarnPublicPagination(state),
  }
}

const mapDispatchToProps = (dispatch: DispatchFunction, ownProps: { ignoreFilters?: boolean }) =>
  bindTypedActionCreators(
    {
      doRefreshRewards: (loadMoreClicked?: boolean) =>
        ownProps.ignoreFilters ? doFetchRewardsEarnPublic : doUpdateRewardsEarnPublicPaginated(loadMoreClicked),
      toggleCategorySelected: toggleCategorySelectedEarnPublic,
      doUpdateRewards: doUpdateRewardsEarnPublicPaginated,
    },
    dispatch
  )

export const PublicEarnRewardsListContainer = connect(mapStateToProps, mapDispatchToProps)(DeprecatedRewardsList)
