import React from 'react'
import { Paragraph, XStack, YStack, Button, AccountCard } from '@red-ui/components'
import { getString } from '@vrw/data/src/utils/getString'
import { LinkingJourneyOrigins, ViewingRegion } from '@vrw/data/src/utils'
import { Page, Spinner } from '../../../components'
import { MyAccountBackground } from '../MyAccountBackground'
import { PUBLIC_URL } from '../../../config'
import { useAccounts } from '../../../query/accounts'
import { useRedNavigate } from '@/dataImplementation/useRedNavigate'
import { PATHS } from '@/router/paths'
import { getViewingRegion } from '@/dataImplementation/viewingRegionWeb'
import { useLinkVAA } from '@/hooks/useLinkVAA'

const PartnerAccountDetails = ({
  partnerCode,
  accountLinked,
  hideFooterWhenLinked,
  backgroundUri,
  logoUrl,
  linkAccountsAction,
  partnerUserId,
  partnerUserIdLabel,
  loading,
}: {
  partnerCode: string
  accountLinked: boolean
  loading?: boolean
  hideFooterWhenLinked?: boolean
  linkAccountsAction: () => void
  backgroundUri: string
  logoUrl: string
  partnerUserId?: string
  partnerUserIdLabel?: string
}) => (
  <YStack alignItems="center">
    <AccountCard
      isCardLinked={accountLinked}
      backgroundUri={backgroundUri}
      logoUrl={logoUrl}
      partnerName={getString(`account.linkAccounts.${partnerCode}.partnerName`)}
      partnerUserId={partnerUserId}
      partnerUserIdLabel={partnerUserIdLabel}
      message={
        accountLinked
          ? getString(`account.linkAccounts.${partnerCode}.status.linked`)
          : getString(`account.linkAccounts.${partnerCode}.status.unlinked`)
      }
      hideFooter={hideFooterWhenLinked && accountLinked}
      width={345}
      margin={0}
      buttonComponent={
        <Button size="$5" fontWeight="700" height="$6" width="100%" paddingHorizontal="$0" onPress={linkAccountsAction}>
          {loading ? <Spinner size="small" color="white" /> : getString(`account.linkAccounts.${partnerCode}.button`)}
        </Button>
      }
    />
    {!accountLinked && (
      <YStack width={345} style={{ textAlign: 'center' }}>
        <Paragraph size={'$4'} fontWeight={'700'} marginVertical={'$3'}>
          {getString(`account.linkAccounts.${partnerCode}.pending.title`)}
        </Paragraph>
        <Paragraph size={'$4'}>{getString(`account.linkAccounts.${partnerCode}.pending.body`)}</Paragraph>
      </YStack>
    )}
  </YStack>
)

const LinkAccounts = () => {
  const navigate = useRedNavigate()
  const { data: accounts, isLoading: isLoadingAccounts } = useAccounts()
  const { linkVAA, linkStatus } = useLinkVAA()
  const vaaAccount = accounts?.find((account) => account.partnerCode === 'VAA')
  const syfAccount = accounts?.find((account) => account.partnerCode === 'SYF')
  const viewingUS = getViewingRegion() === ViewingRegion.US

  return (
    <Page title="Your linked accounts | Virgin Red" testId="page-account-link-accounts">
      <MyAccountBackground>
        <XStack alignItems="flex-start" gap={'$4'} $tabletLandscape={{ flexDirection: 'column' }}>
          {viewingUS && (
            <PartnerAccountDetails
              loading={isLoadingAccounts}
              accountLinked={!!syfAccount}
              backgroundUri={`${PUBLIC_URL || ''}/img/link-syf-account/card-background.png`}
              logoUrl={`${PUBLIC_URL || ''}/img/link-syf-account/logo-linked.png`}
              partnerCode="syf"
              hideFooterWhenLinked
              linkAccountsAction={() => navigate(PATHS.LINK_REWARDS_CREDIT_CARD)}
            />
          )}

          <PartnerAccountDetails
            loading={isLoadingAccounts || linkStatus === 'loading'}
            accountLinked={!!vaaAccount}
            backgroundUri={`${PUBLIC_URL || ''}/img/link-vaa-account/card-background.jpg`}
            logoUrl={`${PUBLIC_URL || ''}/img/link-vaa-account/vaa-logo.png`}
            partnerCode="vaa"
            linkAccountsAction={() => linkVAA(LinkingJourneyOrigins.ACCOUNT)}
            partnerUserIdLabel={getString('account.linkAccounts.vaa.partnerUserIdName')}
            partnerUserId={vaaAccount?.partnerUserId}
          />
        </XStack>
      </MyAccountBackground>
    </Page>
  )
}

export { LinkAccounts }
