import React, { ChangeEvent, FC, useEffect, useState, KeyboardEvent } from 'react'
import { Trans } from 'react-i18next'
import { Checkbox } from '@virgin-core/components'

import { RedemptionType } from '@vrw/data/src/redux/rewards/types'
import { getString, transformInterpolatedString } from '@vrw/data/src/utils'
import { getInvalidCharacters, getGiftMessageError, MAXIMUM_GIFT_MESSAGE_LENGTH } from '@vrw/data/src/redux/orders/validations'

import { PATHS } from '@/router/paths'
import { media } from '@/style/media'
import { color, FontFamilies } from '@/style/variables'
import { OrderCheckoutProps } from '../types'
import Header from '@/components/RewardDetails/Header'
import RewardInfoContent from '@/components/RewardDetails/RewardInfoContent'
import DashedDivider from '@/components/DashedDivider'
import { Button } from '@/components/Buttons/Button'
import { H3Header, Page, Spacer, AddressChooser, Content, CheckoutRewardSummary, StyledCopyAnchor } from '@/components'
import { DialogAddress } from './DialogAddress'
import { localizePath } from '@/dataImplementation/viewingRegionWeb'
import { convertToSingleLine } from '@/helpers'
import { useRedNavigate } from 'web/src/dataImplementation/useRedNavigate'
import { useLocation } from 'react-router-dom'
import { H1, XStack } from '@red-ui/components'
import { header3Style } from './shared'

const Other: FC<OrderCheckoutProps> = ({ reward, order, doUpdateOrderConsent, doUpdateGiftMessage, doPromptSubmitOrder, isSubmitting }) => {
  const { cost, content, redemptionType } = reward || {}
  const { title, lead, brandDetails, imageUrl, termsUrl } = content || {}
  const { brandName, brandLogo } = brandDetails || {}

  const [giftMessageError, setGiftMessageError] = useState<string | null>(null)
  const [characterCount, setCharacterCount] = useState(0)

  const showTermsAndConditions = redemptionType === RedemptionType.WINES_FULFILMENT

  const navigate = useRedNavigate()
  const pathname = useLocation()?.pathname
  const showDialogAddress = pathname === localizePath(PATHS.ORDER_CHECKOUT_ADD_ADDRESS)

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.name) {
      const { name, checked } = event.target
      doUpdateOrderConsent({ [name]: checked })
    }
  }

  const isFormValid = showTermsAndConditions
    ? Boolean(order.consent.over18 && order.consent.terms && order.deliveryAddress.firstName && !giftMessageError)
    : Boolean(order.deliveryAddress.firstName)

  useEffect(() => {
    doUpdateGiftMessage(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async () => {
    if (isFormValid && !isSubmitting) {
      doPromptSubmitOrder(redemptionType)
    }
  }

  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target
    const valueWithoutNewLines = convertToSingleLine(value)
    event.target.value = valueWithoutNewLines
    const invalidCharacters = getInvalidCharacters(valueWithoutNewLines)
    setCharacterCount(valueWithoutNewLines.length)
    if (characterCount <= MAXIMUM_GIFT_MESSAGE_LENGTH) setGiftMessageError(getGiftMessageError(invalidCharacters))

    doUpdateGiftMessage(valueWithoutNewLines)
  }

  // This event handler prevents the enter key from adding a new line
  const handleOnKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') event.preventDefault()
  }

  return (
    <>
      <style>{`
        .order-checkout__error-message {
          margin: 0;
          font-size: 14px;
          color: ${color.brandPrimary};
        }
        .order-checkout__error-container {
          min-height: 26px;
          display: flex;
          align-items: center;
        }
        .order-checkout__gift-message {
          padding: 4px 0 102px 8px;
          width: 100%;
          border-radius: 2px;
          line-height: 26px;
          border: solid 1px ${color.lighterGrey};
          font-family: ${FontFamilies.barlow};
        }
        .order-checkout__character-count {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 0;
          color: ${color.lighterGrey};
        }
        .order-checkout__form {
          margin: 0 auto;
          max-width: 535px;
        }
        .order-checkout__summary {
          display: none;
        }
        .order-checkout__divider {
          margin-top: 40px;
        }
        .order-checkout__divider-error {
          margin-top: 4px;
        }
        .button-wrapper {
          margin-top: 50px;
        }
        .order-back-to-reward-link {
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }
        .order-go-back-arrow {
          transform: rotate(-180deg);
          padding-left: 13px;
        }
        a {
          color: ${color.textHeader};
          font-size: 15px;
          font-family: ${FontFamilies.barlow};
          font-weight: 500;
          letter-spacing: 2px;
          text-align: center;
          text-decoration: none;
        }

        @media ${media.desktopAndHigher} {
          .order-checkout {
            display: flex;
            justify-content: space-between;
          }
          .order-checkout__summary {
            display: block;
            margin-top: 40px;
            margin-left: 50px;
            max-width: 425px;
          }
        }
      `}</style>
      <Page testId="page-reward-checkout" title="Reward Checkout | Virgin Red">
        {reward && (
          <>
            <Header>
              <XStack>
                <H1
                  marginHorizontal="auto"
                  fontWeight={600}
                  marginTop="$6"
                  $tabletLandscape={{ fontSize: '$9', marginTop: '$5' }}
                  $mobile={{ fontSize: '$8', marginTop: '$3' }}>
                  {getString('orders.checkout.web.title')}
                </H1>
              </XStack>
            </Header>

            <Content>
              <RewardInfoContent>
                <div className="order-checkout">
                  <div className="order-checkout__form">
                    <H3Header {...header3Style}>{getString('orders.checkout.web.orderSummary')}</H3Header>
                    <p>{reward.content?.lead}</p>
                    <div className="order-checkout__divider">
                      <DashedDivider />
                    </div>
                    <H3Header {...header3Style}>{getString('orders.checkout.web.deliveryAddress')}</H3Header>
                    <div className="order-checkout_address">
                      <DialogAddress visible={showDialogAddress} onClose={() => navigate(PATHS.ORDER_CHECKOUT)} />
                      <AddressChooser
                        address={order.deliveryAddress}
                        showDialog={() => navigate(PATHS.ORDER_CHECKOUT_ADD_ADDRESS)}
                        loading={isSubmitting}
                      />
                    </div>
                    {redemptionType === RedemptionType.WINES_FULFILMENT && (
                      <>
                        <div className="order-checkout__divider">
                          <DashedDivider />
                        </div>
                        <H3Header {...header3Style}>{getString('orders.checkout.giftMessageTitle')}</H3Header>
                        <p>{getString('orders.checkout.giftMessageDescription')}</p>
                        <textarea
                          data-testid="gift-message-textarea"
                          className="order-checkout__gift-message"
                          onChange={handleTextAreaChange}
                          placeholder={getString('orders.checkout.giftMessagePlaceholder')}
                          maxLength={MAXIMUM_GIFT_MESSAGE_LENGTH}
                          onKeyPress={handleOnKeyPress}
                        />
                        <p className="order-checkout__character-count">
                          {characterCount}
                          {getString('orders.checkout.giftMessageMaxLength').replace(
                            '{{maxLength}}',
                            MAXIMUM_GIFT_MESSAGE_LENGTH.toString()
                          )}
                        </p>
                        <div className="order-checkout__error-container">
                          {giftMessageError && <p className="order-checkout__error-message">{giftMessageError}</p>}
                        </div>
                      </>
                    )}
                    {showTermsAndConditions && (
                      <>
                        <div className="order-checkout__divider-error">
                          <DashedDivider />
                        </div>
                        <H3Header {...header3Style}>{getString('orders.checkout.termsAndConditionsTitle')}</H3Header>
                        <Checkbox
                          borderColor={color.lightGrey}
                          label={getString('orders.checkout.confirmOverEighteen')}
                          checked={order.consent.over18}
                          name="over18"
                          onChange={handleChangeCheckbox}
                          data-testid="checkbox-over18"
                        />
                        <Spacer size="small" />
                        <Checkbox
                          borderColor={color.lightGrey}
                          // @ts-ignore
                          label={
                            <Trans
                              defaults={transformInterpolatedString(getString('orders.checkout.confirmAcceptVirginWinesTerms'))}
                              components={{
                                tcLink: <StyledCopyAnchor to={termsUrl} target external />,
                              }}
                            />
                          }
                          checked={order.consent.terms}
                          name="terms"
                          onChange={handleChangeCheckbox}
                          data-testid="checkbox-terms"
                        />
                        <Spacer size="small" />
                        <Checkbox
                          borderColor={color.lightGrey}
                          label={getString('orders.checkout.confirmReceiveVirginWinesMarketing')}
                          checked={order.consent.marketingUpdates}
                          name="marketingUpdates"
                          onChange={handleChangeCheckbox}
                          data-testid="checkbox-marketingUpdates"
                        />
                      </>
                    )}
                    <div className="button-wrapper">
                      <Button
                        display="inline-block"
                        isFullWidth={false}
                        isPrimary
                        disabled={!isFormValid}
                        onClick={handleSubmit}
                        text={getString('actions.redeemNow')}
                        loading={isSubmitting}
                        dataAttributes={{
                          testid: 'order-checkout-confirm-button',
                        }}
                      />
                    </div>
                  </div>
                  {imageUrl && brandLogo && title && brandName && !!cost && (
                    <div className="order-checkout__summary">
                      <CheckoutRewardSummary
                        imageUrl={imageUrl}
                        brandLogoUrl={brandLogo}
                        brandName={brandName}
                        title={title}
                        lead={lead ?? ''}
                        cost={cost}
                      />
                    </div>
                  )}
                </div>
              </RewardInfoContent>
            </Content>
            <Spacer size="large" />
          </>
        )}
      </Page>
    </>
  )
}

export { Other }
