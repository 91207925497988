import React from 'react'
import { PassengerRow } from './PassengerRow'
import { View } from '@red-ui/components'
import { OnChangeType, SearchForm } from '@/feature/RedSkySearch/components/SearchSideSheets/useSearchForm'

type PassengerType = 'adults' | 'youngAdults' | 'children' | 'infant'

type Passenger = {
  type: PassengerType
  toolTip: boolean
}

export const passengers: Passenger[] = [
  { type: 'adults', toolTip: false },
  { type: 'youngAdults', toolTip: false },
  { type: 'children', toolTip: false },
  { type: 'infant', toolTip: true },
]

type Props = {
  onChange: OnChangeType
  data: SearchForm
}

const PASSENGER_LIMIT = 9

export const PassengerForm = ({ data, onChange }: Props) => {
  const totalCount = passengers.reduce((total, passenger) => total + data[passenger.type], 0)

  const onChangeCount = (type: PassengerType) => (value: number) => {
    onChange(type, value)
  }

  return (
    <View gap="$3">
      {passengers.map((passenger) => {
        const value = data[passenger.type]

        return (
          <PassengerRow
            key={passenger.type}
            onChangeCount={onChangeCount(passenger.type)}
            value={value}
            min={Number(passenger.type === 'adults')}
            max={PASSENGER_LIMIT - totalCount + value}
            {...passenger}
          />
        )
      })}
    </View>
  )
}
