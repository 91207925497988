import * as React from 'react'
import { Button } from '@/components/Buttons'
import { TileLister } from '@/components/TileLister'
import MyRewardsResults from './MyRewardsResults'
import { MyRewardsTab } from './MyRewardsPrivate'
import { XStack } from '@red-ui/components'
import { VoucherTileProps } from '@/components/TileLister/VoucherTile/types'
import { Content, ToggleSlider } from '@/components'

interface RenderVouchersProps {
  tabs: MyRewardsTab[]
  myRewardRoute: string | null
  voucherIds: string[]
  voucherTiles: Array<VoucherTileProps>
  hasVouchersLoaded: boolean
  onLoadMore: () => void
  moreAvailable: boolean
}

export const RenderVouchers: React.FC<RenderVouchersProps> = ({
  tabs,
  myRewardRoute,
  voucherIds,
  voucherTiles,
  hasVouchersLoaded,
  moreAvailable,
  onLoadMore,
}) => {
  return (
    <>
      <Content>
        <ToggleSlider tabs={tabs} selected={myRewardRoute} />
      </Content>
      <MyRewardsResults isEmpty={voucherIds.length === 0} isLoading={!hasVouchersLoaded}>
        <TileLister title="" tiles={voucherTiles} />
      </MyRewardsResults>
      {moreAvailable && (
        <XStack justifyContent="center">
          <Button
            isFullWidth={false}
            onClick={() => onLoadMore()}
            isPrimary
            text="Load more"
            dataAttributes={{ testid: 'load-more-button' }}
          />
        </XStack>
      )}
    </>
  )
}
