import React, { useEffect, useState } from 'react'
import { getString } from '@vrw/data'
import { media } from '../../style/media'
import { getLocale } from '../../dataImplementation/viewingRegionWeb'
import { Carousel, Container, Content, MobileAppsSplash, Spacer, PageLoader, Wishlist, BrandsCarousel } from '../../components'
import { HeroBlock } from '../../components/ContentfulBlock'
import { CardTypes, ContentfulBlock } from '../../components/ContentfulBlock/types'
import { HomePageProps } from './types'
import { H3Header } from '../../components/typography'
import { BrazeContentCard } from './BrazeContentCard'
import { IntroCarouselBlockPrivate } from '../../components/ContentfulBlock/IntroCarouselBlock'

export const HomePrivate = ({ currentPage, doSpecificPageFetch }: HomePageProps) => {
  const content = currentPage?.fields.content?.fields
  const locale = getLocale()

  const [showBrazeCard, setShowBrazeCard] = useState(false)

  useEffect(() => {
    doSpecificPageFetch({
      slugName: 'home-page-logged-in',
      locale,
    })
  }, [doSpecificPageFetch, locale])

  const renderHeading = (text: string, alignment?: string) => (
    <H3Header
      textAlign={alignment ?? 'left'}
      weight={600}
      marginTop={{ mobile: 0, tablet: 0, desktop: 0 }}
      marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}
      fontSize={{ mobile: 20, tablet: 28, desktop: 40 }}>
      {getString(text)}
    </H3Header>
  )

  return content ? (
    <>
      <style jsx>{`
        .styles-proposition-carousel :global(.text-panel) {
          padding: 15px 20px;
          align-items: center;
          text-align: center;
        }
        .styles-proposition-carousel :global(.text-panel h2) {
          font-style: unset;
          text-align: unset;
        }
        .brand-carousel {
          margin-bottom: 68px;
        }
        .top-container {
          position: relative;
        }
        .braze-card-container {
          max-width: 1440px;
          margin-left: auto;
          margin-right: auto;
          position: relative;
        }
        :global(.card-enter-fade) {
          opacity: 1;
          transition: opacity 500ms ease-in;
        }
        :global(.card-leave-fade) {
          opacity: 0;
          transition: opacity 500ms ease-in;
        }
        [aria-hidden='true'] {
          visibility: hidden;
        }

        @media ${media.tabletAndHigher} {
          .styles-proposition-carousel :global(.text-panel) {
            padding: 20px 22px;
            align-items: unset;
            text-align: unset;
          }
          .styles-proposition-carousel :global(.text-panel h2) {
            text-align: unset;
            font-size: 20px;
          }
          .wishlist-empty-container {
            padding: 18px 0;
            margin: 16px 42px;
          }
          .wishlist-empty-description {
            max-width: none;
          }
          .brand-carousel :global(.content) {
            padding-left: 20px;
          }
        }

        @media ${media.desktopAndHigher} {
          .styles-proposition-carousel :global(.text-panel) {
            padding: 40px 55px;
          }
          .wishlist-empty-container {
            padding: 30px 0;
            margin: 16px 60px;
          }
          .wishlist-empty-description {
            font-size: 20px;
          }
          .brand-carousel :global(.content) {
            padding-left: 0;
          }
        }
        @media ${media.hiResDesktop} {
          .styles-proposition-carousel :global(.text-panel) {
            padding: 40px 60px;
          }
        }
      `}</style>
      <div className="top-container">
        <div className="braze-card-container">
          <BrazeContentCard showBrazeCard={showBrazeCard} setShowBrazeCard={setShowBrazeCard} />
        </div>
        <div className={`${showBrazeCard ? 'card-leave-fade' : 'card-enter-fade'}`} aria-hidden={showBrazeCard}>
          {content?.topHero && <HeroBlock fields={content.topHero.fields} testId="main-hero" />}
        </div>
      </div>
      <IntroCarouselBlockPrivate type={CardTypes.FEATURED} />
      <Wishlist />
      {content?.propositionCarousel && (
        <Container>
          {renderHeading('home.propositionCarousel.title', 'center')}
          <div className="styles-proposition-carousel">
            <Carousel
              items={content?.propositionCarousel?.fields.heroBlocks?.map((block: ContentfulBlock<any>) => (
                <HeroBlock fields={block.fields} />
              ))}
            />
          </div>
        </Container>
      )}
      <div className="brand-carousel">
        <Content>{renderHeading('home.brandCarousel.title')}</Content>
        <BrandsCarousel />
      </div>
      <IntroCarouselBlockPrivate type={CardTypes.EARN} showAllLink={true} />
      <IntroCarouselBlockPrivate type={CardTypes.SPEND} showAllLink={true} />
      <Spacer size="medium" />
      <MobileAppsSplash />
    </>
  ) : (
    <PageLoader />
  )
}
