export const REDEEM_PROMOCODE = 'REDEEM_PROMOCODE'
export const REDEEM_PROMOCODE_SUCCESS = 'REDEEM_PROMOCODE_SUCCESS'
export const REDEEM_PROMOCODE_FAILURE = 'REDEEM_PROMOCODE_FAILURE'
export const REDEEM_PROMOCODE_CONFIRMED = 'REDEEM_PROMOCODE_CONFIRMED'

export interface PromoCodesState {
  isLoading?: boolean
  hasSubmitted?: boolean
  errorMessage: string | undefined
  pointsAdded: number | null
}

export interface PromotionsState {
  code: string
}
