import React, { useState } from 'react'
import { getString } from '@vrw/data'
import { PATHS } from '@/router/paths'
import { useRedNavigate } from '@/dataImplementation/useRedNavigate'

import { OnChangeType, SearchForm, useSearchForm } from './useSearchForm'
import { generateSearchQueryString } from '../../helpers/searchQueryParams'
import { Airport } from '../../api/searchAirports'

import { FlightSearchForm } from '../FlightSearchForm'
import { QuestionForm } from '../QuestionForm'
import { SideSheet } from '../SideSheet'

type SideSheetType = 'from' | 'to'

export type Props = {
  mainSheetOpen: boolean
  onCloseMainSheet: (value: boolean) => void
  mainSheetTitle?: string
  editMode?: boolean
  initData?: SearchForm | null
}

export const fields = ['departure', 'destination', 'date', 'passengers']

export const SearchSideSheets = ({ mainSheetOpen, onCloseMainSheet, mainSheetTitle, editMode = false, initData }: Props) => {
  const [openSheet, setOpenSheet] = useState<SideSheetType | null>(null)
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0)

  const { form, onChange: onChangeField, onReset } = useSearchForm(initData)

  const redNavigate = useRedNavigate()

  const handleMainClose = () => {
    if (openSheet) handleSubSheetClose()
    onCloseMainSheet(false)
    onReset()
  }

  const onChange: OnChangeType = (field, value) => {
    if (!editMode && currentQuestionIdx < fields.length - 1) {
      setCurrentQuestionIdx(currentQuestionIdx + 1)
    }

    onChangeField(field, value)
  }

  const onSelectAirport = (field: Extract<keyof SearchForm, 'departure' | 'destination'>) => (airport: Airport) => {
    handleSubSheetClose()
    onChange(field, airport)
  }

  const handleSubSheetClose = () => {
    setOpenSheet(null)
  }

  const onSheetOpen = (type: SideSheetType | null) => () => {
    setOpenSheet(type)
  }

  const onSubmitForm = () => {
    const searchQueryStr = generateSearchQueryString(form)
    onCloseMainSheet(false)
    redNavigate(`${PATHS.REDSKY_SEARCH_RESULTS}?${searchQueryStr}`)
  }

  return (
    <>
      <SideSheet open={mainSheetOpen} onClose={handleMainClose} ariaLabel={getString('redSkySearch.sidesheet.questions.ariaLabel')}>
        <QuestionForm
          onClose={handleMainClose}
          onFromInputOpen={onSheetOpen('from')}
          onToInputOpen={onSheetOpen('to')}
          title={mainSheetTitle}
          editMode={editMode}
          form={form}
          onChange={onChange}
          onSubmit={onSubmitForm}
          currentQuestionIdx={currentQuestionIdx}
          onChangeQuestionIdx={setCurrentQuestionIdx}
        />
      </SideSheet>
      <SideSheet
        open={openSheet === 'from'}
        onClose={handleSubSheetClose}
        hasOverlay={false}
        ariaLabel={getString('redSkySearch.sidesheet.departure.ariaLabel')}>
        <FlightSearchForm
          onClose={handleSubSheetClose}
          title={getString('redSkySearch.question.departure.label')}
          info={getString('redSkySearch.question.departure.input.info')}
          onSelect={onSelectAirport('departure')}
          value={form.departure?.airportName}
        />
      </SideSheet>
      <SideSheet
        open={openSheet === 'to'}
        onClose={handleSubSheetClose}
        hasOverlay={false}
        ariaLabel={getString('redSkySearch.sidesheet.destination.ariaLabel')}>
        <FlightSearchForm
          onClose={handleSubSheetClose}
          title={getString('redSkySearch.question.destination.label')}
          info={getString('redSkySearch.question.destination.input.info')}
          onSelect={onSelectAirport('destination')}
          value={form.destination?.airportName}
        />
      </SideSheet>
    </>
  )
}
