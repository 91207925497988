import { getAllFeatures, getFeatureValueType } from '@vrw/data/src/redux/features/features.selectors'
import { useAppSelector } from '../../../redux/hooks/hooks'
import { addFeatureFlagEvaluation } from '../../../dataImplementation/datadog'

export const useFeatures = () => {
  const features = useAppSelector(getAllFeatures)

  return features.reduce((features, feature) => {
    if (feature?.name && feature?.value) {
      const valueType = getFeatureValueType(feature)
      let value = valueType && feature.value[valueType]

      if (value !== undefined) {
        addFeatureFlagEvaluation(feature.name, feature.variation)
        features.push(`${feature.name}:${feature.variation}`)
      }
    }

    return features
  }, [] as string[])
}
