import React, { FC } from 'react'

import { RedemptionType } from '@vrw/data/src/redux/rewards/types'
import { OrderCheckoutProps } from '../types'

import { Voyages } from './Voyages'
import { Other } from './Other'

const OrderCheckout: FC<OrderCheckoutProps> = ({ reward, ...rest }) => {
  const { redemptionType } = reward || {}

  if (redemptionType === RedemptionType.VOYAGES_FULFILMENT) {
    return <Voyages reward={reward} {...rest} />
  }

  return <Other reward={reward} {...rest} />
}

export { OrderCheckout }
