import React, { FC } from 'react'
import { color } from '@/style/variables'
import { View, ViewProps } from 'tamagui'

const DashedDivider: FC<ViewProps> = ({ ...rest }) => (
  <View
    width={'100%'}
    height={2}
    opacity={0.5}
    backgroundImage={`linear-gradient(to right, ${color.divider} 33%, ${color.white} 0%)`}
    backgroundPosition={'bottom'}
    backgroundSize={`10px 2px`}
    backgroundRepeat={`repeat-x`}
    {...rest}
  />
)

export default DashedDivider
