import React, { useEffect } from 'react'
import { getCurrentPage, getError } from '@vrw/data/src/redux/pages/selectors'
import { doSpecificPageFetch } from '@vrw/data/src/redux/pages/dispatchers'
import { ContentfulEntry } from '@vrw/data/src/utils/contentfulPages'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import { getLocale } from '../../dataImplementation/viewingRegionWeb'
import { Page } from '../../components'
import { DynamicContentFactory } from '../../components/DynamicContentFactory'
import { ErrorPage404 } from '../ErrorPage404'
import { Locale } from '@vrw/data/src/utils'

export const DynamicComposedPage = ({
  slugName,
  useStatic404Fallback = false,
  localized = true,
}: {
  slugName: string
  useStatic404Fallback?: boolean
  localized?: boolean
}) => {
  const dispatch = useAppDispatch()
  // use gb as default locale if localized is false
  const locale = localized ? getLocale() : Locale.EN_GB
  const error = useAppSelector(getError)
  const currentPage = useAppSelector(getCurrentPage)
  const { seo, content } = currentPage?.fields || {}

  useEffect(() => {
    dispatch(
      doSpecificPageFetch({
        slugName,
        locale,
      })
    )
  }, [locale, dispatch, slugName])

  if (error) {
    if (useStatic404Fallback) {
      // 404 component itself is dynamic, so we need to prevent a render loop
      // this fallback is only used when the 404 page itself is not found
      return <h1>404 Error</h1>
    }
    return <ErrorPage404 />
  }

  return currentPage ? (
    <Page title={`${seo?.fields?.title} | Virgin Red `} metaDescription={`${seo?.fields?.description}`}>
      {content && <DynamicContentFactory content={content as ContentfulEntry} />}
    </Page>
  ) : null
}
