import React from 'react'
import { Button, YStack, ButtonProps } from '@red-ui/components'
import { getIsAuthenticated } from '@vrw/data/src/redux/auth/selectors'
import { useAppSelector } from '../../../redux/hooks/hooks'
import { isUrlExternal } from '../../../helpers/urlHelper'
import { Link } from '../../Link'
import { TriggerCreditApply } from './TriggerCreditApply'
import { PATHS } from '@/router/paths'
import { regionalizePath } from '@/dataImplementation/viewingRegionWeb'

interface DynamicButtonFields {
  styleVariant: 'primary' | 'secondary'
  text: string
  textLoggedIn?: string
  target?: string
  triggerFunction?: string
}

const TRIGGER_ACTIONS = {
  CREDIT_CARD_APPLY: 'CREDIT_CARD_APPLY',
  LINK_SYF_ACCOUNT: 'LINK_SYF_ACCOUNT',
}

type ActionMapType = { [key: string]: () => void }

const functionMapping: ActionMapType = {
  TEST_RELOAD: () => window.location.reload(),
}

export const Wrapper = ({
  children,
  target,
  triggerFunction,
}: {
  children: React.ReactElement<ButtonProps>
  target?: string
  triggerFunction?: string
}) => {
  if (target) {
    return DynamicLink({ href: target, external: isUrlExternal(target), children })
  } else if (triggerFunction === TRIGGER_ACTIONS.CREDIT_CARD_APPLY) {
    return <TriggerCreditApply children={children} runCTAExperiment={true} />
  } else if (triggerFunction === TRIGGER_ACTIONS.LINK_SYF_ACCOUNT) {
    return DynamicLink({ href: regionalizePath(PATHS.LINK_REWARDS_CREDIT_CARD), children })
  }

  return children
}

export const DynamicButton = ({ fields, actionMap = functionMapping }: { fields: DynamicButtonFields; actionMap?: ActionMapType }) => {
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const { styleVariant, text, textLoggedIn, target, triggerFunction } = fields
  const triggerFn = (!target && triggerFunction && actionMap[triggerFunction]) || undefined

  return (
    <YStack alignItems="flex-start" $mobile={{ alignItems: 'center', flexDirection: 'row', width: '100%' }}>
      <Wrapper target={target} triggerFunction={triggerFunction}>
        <Button
          $mobile={{ flex: 1, paddingHorizontal: '$4', width: '100%' }}
          variant={styleVariant === 'primary' ? undefined : styleVariant}
          paddingHorizontal="$6"
          minHeight="$6"
          fontSize="$4"
          fontWeight={600}
          onPress={triggerFn}>
          {isAuthenticated && textLoggedIn ? textLoggedIn : text}
        </Button>
      </Wrapper>
    </YStack>
  )
}

export const DynamicLink = ({
  href,
  external = false,
  children,
  ...props
}: { href: string; external?: boolean; children: React.ReactNode } & React.ComponentProps<typeof Link>) => {
  return (
    <Link
      href={href}
      external={external}
      $mobile={{ width: '100%' }}
      textDecorationLine="none"
      hoverStyle={{ textDecorationLine: 'none' }}
      {...props}>
      {children}
    </Link>
  )
}
