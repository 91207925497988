import * as React from 'react'
import { OrderConfirmation } from './OrderConfirmation'
import { getOrderById } from '@vrw/data/src/redux/orders/selectors'
import { useAppSelector } from 'web/src/redux/hooks/hooks'
import { useParams } from 'react-router-dom'
import { RedemptionType } from '@vrw/data/src/redux/rewards/types'
import { VirginVoyagesConfirmation } from './VirginVoyagesConfirmation'

const OrderConfirmationContainer = () => {
  const { orderId = '' } = useParams()
  const order = useAppSelector((state) => getOrderById(state, orderId))
  if (order.redemptionType === RedemptionType.VOYAGES_FULFILMENT) {
    return <VirginVoyagesConfirmation order={order} />
  }
  return <OrderConfirmation order={order} />
}

export { OrderConfirmationContainer }
