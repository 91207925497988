export const getTrackingId = (...segments: string[]) => {
  const formatSegment = (segment: string) =>
    segment
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]/gi, '')
      .toLowerCase()

  return segments.map(formatSegment).filter(Boolean).join('-')
}
