export const COUNTRY_CODES = [
  { value: '1', label: '(+1)' },
  { value: '44', label: '(+44)' },
]

export const CITIZENSHIP = [
  { value: 'US', label: 'United States' },
  { value: 'GB', label: 'United Kingdom' },
]

export const GENDERS = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Unknown', label: 'Prefer not to say' },
]

export const generateYears = () => {
  const currentYear = new Date().getFullYear()
  const years = []
  for (let i = currentYear - 100; i <= currentYear; i++) {
    years.push({ value: i.toString(), label: i.toString() })
  }
  return years.reverse()
}

export const MONTHS = Array.from({ length: 12 }, (_, i) => {
  const value = (i + 1).toString()
  return { value, label: value.padStart(2, '0') }
})

export const generateDays = () => {
  const days = []
  for (let i = 1; i <= 31; i++) {
    const value = i.toString()
    days.push({ value, label: value.padStart(2, '0') })
  }
  return days
}
