export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE'
export const CLEAR_ORDER_SUCCESS = 'CLEAR_ORDER_SUCCESS'
export const STOP_ORDER = 'STOP_ORDER'

export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE'

export const START_ORDER = 'START_ORDER'
export const UPDATE_ORDER = 'UPDATE_ORDER'

export const SUBMIT_ORDER = 'SUBMIT_ORDER'
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS'
export const SUBMIT_ORDER_FAILURE = 'SUBMIT_ORDER_FAILURE'
export const UPDATE_GIFT_MESSAGE = 'UPDATE_GIFT_MESSAGE'

export interface DeliveryAddress {
  firstName: string
  lastName: string
  phoneNumber?: string
  email: string
  building?: string
  addressLine1?: string
  addressLine2?: string
  town?: string
  county?: string
  postcode?: string
}

// Values optional to allow use in action definitions where changes are made one value at a time
export interface Consent {
  over18?: boolean
  terms?: boolean
  marketingUpdates?: boolean
}

export interface OrderContent {
  title: string
  imageUrl: string
  partner?: string
  brandDetails?: {
    brandName?: string
    brandLogo: string
  }
}

export interface DeliveryAddressErrorState {
  firstName?: string
  lastName?: string
  phoneNumber?: string
  email?: string
  building?: string
  addressLine1?: string
  addressLine2?: string
  town?: string
  county?: string
  postcode?: string
}

export interface TrainsOrderContentOld {
  bookingRecordLocator: string
  origin: string
  destination: string
  numberOfPassengers: number
  outboundDepartureDate: number
  outboundArrivalDate: number
  returnDepartureDate?: number
  returnArrivalDate?: number
  title?: string
}

export interface TrainsOrderContent {
  bookingRecordLocator: string
  contentLines: TrainsOrderContentLine[]
  title: string
}

export interface TrainsOrderContentLine {
  origin: string
  destination: string
  numberOfPassengers: number
  outboundDepartureDate: number | string
  outboundArrivalDate: number | string
  returnDepartureDate?: number | string
  returnArrivalDate?: number | string
}

export interface OrderRequest {
  consent: Consent
  content: OrderContent | TrainsOrderContentOld | TrainsOrderContent
  deliveryAddress: DeliveryAddress
  giftMessage?: string | null
  rewardId: string
  rewardParentId: string
  sailorDetails?: SailorDetails
}

export interface Order extends OrderRequest {
  cost: number
  createdDate?: string
  deliveryWindow: string
  orderId: string
  redemptionType: string
  status: OrderStatus
  trackingUrl?: string
}

export interface VoyagesOrderRequest extends OrderRequest {
  expectedPoints?: number
  personDetails?: PersonDetails
}

export type VoyagesOrder = Order & {
  voyagesBooking?: VoyagesBooking
}

export type VoyagesBooking = {
  bookingReference: string
  cabinNumber: string
  deckName: string
  deckNumber: string
  email: string
  firstName: string
  lastName: string
  dateOfBirth: string
  sex: string
  citizenship: string
  countryAccessCode: string
  phoneNumber: string
}
export interface PersonDetails {
  firstName: string
  lastName: string
  email: string
  dateOfBirth: string
  sex: string
  citizenship: string
  countryAccessCode: string
  phoneNumber: string
}

export interface OrdersState {
  data: { entities: { [orderId: string]: Order }; ids: string[] }
  inProgress: OrderRequest
  isRefreshing: boolean
  isSubmitting: boolean
  hasLoaded: boolean
  errorMessage: string | null
  justRedeemed: boolean
  isLoading: boolean
}

export enum OrderStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  DISPATCHED = 'DISPATCHED',
  DELIVERED = 'DELIVERED',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
}

export interface SailorDetails {
  firstName?: string
  lastName?: string
  email?: string
  dateOfBirth?: {
    year: string
    month: string
    day: string
  }
  mobileNumber?: {
    number: string
    countryCode: string
  }
  gender?: string
  citizenship?: string
}
