import { doStartOrder } from '../../orders/dispatchers'
import { getRedDataConfig } from '../../../config'
import { Reward } from '../types'
import { DispatchFunction } from '../../types'

export const doVoyagesFulfilmentRedemptionFlow = (reward: Reward) => async (dispatch: DispatchFunction) => {
  const config = getRedDataConfig()
  await dispatch(doStartOrder(reward.rewardId, reward.rewardParentId, reward.content))
  return config.navigate(config.navTargets.OrderCheckout, {
    rewardId: reward.rewardId,
    campaignId: reward.campaignId,
  })
}
