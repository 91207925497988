import resolveLinks from 'contentful-resolve-response'
import { Entry, EntrySkeletonType } from 'contentful'
import { withContentfulFetchRetry, getResponseBody } from '../utils/httpClient'
import { ContentfulLocale, convertLocaleToContentfulLocale, validatePageAccess } from '../utils/contentfulPages'
import { isWeb, Locale } from '../utils/helperFunctions'
import { getRedDataConfig } from '../config'

export const rawContentfulDomain = (): string => {
  const {
    contentful: { env, space, url, accessToken },
  } = getRedDataConfig()
  const config = {
    contentfulUrl: url,
    contentfulSpace: space,
    contentfulEnvironment: env,
    contentfulAccessToken: accessToken,
  }
  return `${config.contentfulUrl}/spaces/${config.contentfulSpace}/environments/${config.contentfulEnvironment}/entries?access_token=${config.contentfulAccessToken}`
}

// for region specific pages like home-page-logged-in and home-page-logged-in-us
export const getSpecificRuntimePageBySlug = async ({
  slug,
  locale,
}: {
  slug: string
  locale?: Locale | string
}): Promise<EntrySkeletonType<any> | null> => {
  const contentfulRawDomain = rawContentfulDomain()
  const slugMatchedPages = await withContentfulFetchRetry(
    `${contentfulRawDomain}&limit=2&include=10&content_type=page&order=sys.createdAt&fields.slug=${slug}${
      locale ? `&locale=${convertLocaleToContentfulLocale(locale)}` : ''
    }`
  )
  const resolved = resolveLinks(await getResponseBody(slugMatchedPages))
  const filtered = resolved.filter((entry: EntrySkeletonType<any>) => entry.fields.slug === slug)[0]
  return filtered
}

// for generic pages like landing pages
export const getRuntimeContentAndFilterBySlug = async <T = Entry>({
  slug,
  locale,
  isAuthenticated,
}: {
  slug: string
  locale?: Locale | string
  isAuthenticated?: boolean
}): Promise<T | null> => {
  const contentfulRawDomain = rawContentfulDomain()
  const slugMatchedPages = await withContentfulFetchRetry(
    `${contentfulRawDomain}&include=10&content_type=page&order=sys.createdAt${
      locale ? `&locale=${convertLocaleToContentfulLocale(locale)}` : ''
    }`
  )
  const resolved = resolveLinks(await getResponseBody(slugMatchedPages))
  const filtered = resolved.filter((entry: Entry<any>) => entry.fields.slug === slug)[0]

  // safeguard adding these pages as physical URLs
  if (
    !filtered ||
    filtered?.fields.slug === 'home-page-logged-out' ||
    filtered?.fields.slug === 'home-page-logged-in' ||
    filtered?.fields.slug === 'home-page-logged-out-us' ||
    filtered?.fields.slug === 'home-page-logged-in-us' ||
    filtered?.fields.slug === 'footer'
  ) {
    return null
  }

  const accessValidated = validatePageAccess({ tags: filtered?.metadata?.tags, locale, isAuthenticated })
  return accessValidated ? filtered : null
}

type ContentTypesAllowedForSingluarFetch =
  | 'termsAndConditions'
  | 'privacyPolicy'
  | 'termsAndConditionsSection'
  | 'privacyPolicySection'
  | 'guestApplyModal'

export const getSingularRuntimeContent = async ({
  contentType,
  viewingRegion,
}: {
  contentType: ContentTypesAllowedForSingluarFetch
  viewingRegion?: ContentfulLocale | string
}): Promise<Entry<any>['fields']> => {
  const contentfulRawDomain = rawContentfulDomain()
  const content = await withContentfulFetchRetry(
    `${contentfulRawDomain}&include=10&content_type=${contentType}&order=sys.createdAt${
      viewingRegion ? `&locale=${ContentfulLocale[viewingRegion as keyof typeof ContentfulLocale]}` : ''
    }`
  )
  const resolved = resolveLinks(await getResponseBody(content))
  return { ...resolved[0].fields, version: resolved[0].sys.revision }
}

export const apiGetTermsAndConditions = async (viewingRegion?: ContentfulLocale | string) => {
  return getSingularRuntimeContent({
    contentType: isWeb() ? 'termsAndConditionsSection' : 'termsAndConditions',
    viewingRegion,
  })
}

export const apiGetGuestApplyModal = async () => {
  return getSingularRuntimeContent({
    contentType: 'guestApplyModal',
  })
}

export const apiGetPrivacyPolicy = async (viewingRegion?: ContentfulLocale | string) => {
  return await getSingularRuntimeContent({
    contentType: isWeb() ? 'privacyPolicySection' : 'privacyPolicy',
    viewingRegion,
  })
}
