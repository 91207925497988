import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Locale } from '@vrw/data/src/utils'
import { Login, LoginSuccess, LogoutSuccess, ErrorPage404, EmailUpdated, PartnerLoginSuccess, DynamicComposedPage } from '../pages'
import { PATHS, virginRedPath } from './paths'
import { RegionalisedRoutes } from './regionalizedRoutes'
import { PrivateRoute } from '../components'
import { RewardsCreditCardTransition } from '../pages/RewardsCreditCardTransition'
import { useOnRouteChange } from './useOnRouteChange'
import { CreditCardHub } from '@/pages/CreditCardHub/CreditCardHub'

export const RootRouter = () => {
  useOnRouteChange()

  return (
    <Routes>
      {/* GB only routes */}
      <Route path={`${virginRedPath}${PATHS.LOGIN_SUCCESS}`} element={<LoginSuccess />} />
      <Route path={`${virginRedPath}${PATHS.LOGOUT_SUCCESS}`} element={<LogoutSuccess />} />
      <Route path={`${virginRedPath}${PATHS.LOGIN}`} element={<Login />} />
      <Route path={`${virginRedPath}${PATHS.EMAIL_UPDATED}`} element={<EmailUpdated />} />
      <Route path={`${virginRedPath}${PATHS.PARTNER_LOGIN_SUCCESS}`} element={<PartnerLoginSuccess />} />
      {/* US Only routes */}
      <Route
        path={`/${Locale.EN_US}${virginRedPath}${PATHS.REWARDS_CREDIT_CARD}`}
        element={<DynamicComposedPage slugName="rewards-credit-card" />}
      />
      <Route
        path={`/${Locale.EN_US}${virginRedPath}${PATHS.MY_ACCOUNT_CREDIT_CARD_HUB}`}
        element={
          <PrivateRoute>
            <CreditCardHub />
          </PrivateRoute>
        }
      />

      <Route
        path={`/${Locale.EN_US}${virginRedPath}${PATHS.REWARDS_CREDIT_CARD_TRANSITION}`}
        element={
          <PrivateRoute>
            <RewardsCreditCardTransition />
          </PrivateRoute>
        }
      />

      {/* NON Region specific routes */}
      <Route path={`/:locale?${virginRedPath}/*`} element={<RegionalisedRoutes />} />
      {/* catch all route */}
      <Route path="*" element={<ErrorPage404 />} />
    </Routes>
  )
}
