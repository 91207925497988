import { ContentfulBlock } from '@/components/ContentfulBlock/types'

export const TextLinkKeys = {
  ukPrivate: 'ukPrivateFooterLinks',
  ukPublic: 'ukPublicFooterLinks',
  usPrivate: 'usPrivateFooterLinks',
  usPublic: 'usPublicFooterLinks',
} as const

export const IconLinkKeys = {
  ukSocial: 'ukSocialLinks',
  usSocial: 'usSocialLinks',
} as const

type Links = {
  link: Array<
    ContentfulBlock<{
      linkText: string
      url: string
    }>
  >
}

type Icons = {
  link: Array<
    ContentfulBlock<{
      title: string
      accessibilityLabel: string
      iconName: string
      url: string
    }>
  >
}

export type TextKey = (typeof TextLinkKeys)[keyof typeof TextLinkKeys]
export type IconKey = (typeof IconLinkKeys)[keyof typeof IconLinkKeys]

export type FooterBlock = {
  [key in TextKey | IconKey]: key extends TextKey ? ContentfulBlock<Links> : ContentfulBlock<Icons>
}
