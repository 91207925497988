import React, { FC, useEffect, useState } from 'react'
import { PaginationState } from '@vrw/data/src/redux/pagination/types'

import { TileLister, TilesType } from '../TileLister'
import { Button } from '../Buttons/Button'
import { CtaTileProps } from '../TileLister/CtaTile'
import { DoubleCtaTileProps } from '../TileLister/DoubleCtaTile/DoubleCtaTile'
import { LoadSpinner } from '..'
import { convertCmsRewardsToTiles } from '../TileLister/RewardsTile/convertCmsRewardsToTiles'
import { convertCmsEarnsToTiles, RewardEarn } from '../TileLister/RewardsEarnTile/convertCmsEarnsToTiles'
import { media } from '../../style/media'
import { WishedType, Wishlist } from '@vrw/data/src/redux/wishlist/types'
import { RewardCategory } from '@vrw/data/src/redux/categories/types'
import { Reward } from '@vrw/data/src/redux/rewards/types'
export type CtaTiles = { [position: number]: CtaTileProps | DoubleCtaTileProps }

export interface DeprecatedRewardsListProps {
  categories?: RewardCategory[]
  earn?: boolean
  limit?: number
  rewards: { [rewardId: string]: Reward | RewardEarn } // Earn/spend and public/private set by containers
  rewardIds: string[]
  doRefreshRewards: (loadMoreClicked?: boolean) => void
  ctaTiles?: CtaTiles
  isLoading?: boolean
  pagination?: PaginationState
  showWishListIcons?: boolean
  wishlist?: Wishlist
  doGetWishlist?: () => void
  doRemoveRewardFromWishlist?: (rewardId: string, rewardType: WishedType) => void
  doAddRewardToWishlist?: (rewardId: string, rewardType: WishedType) => void
  isLoadingWishlist?: boolean
  toggleCategorySelected: (category: string) => void
  doUpdateRewards: () => void
}

const ctaTilesEmpty = {}

export const DeprecatedRewardsList: FC<DeprecatedRewardsListProps> = ({
  categories,
  earn,
  rewards,
  rewardIds,
  limit,
  doRefreshRewards,
  ctaTiles = ctaTilesEmpty,
  isLoading = true,
  pagination,
  showWishListIcons = false,
  wishlist,
  doGetWishlist,
  doRemoveRewardFromWishlist,
  doAddRewardToWishlist,
  isLoadingWishlist,
  toggleCategorySelected,
  doUpdateRewards,
}) => {
  const [delayRender, setDelayRender] = useState(true)
  let rewardsList: TilesType = []

  useEffect(() => {
    if (categories !== undefined) {
      window.scrollTo(0, 0)

      const params = new URLSearchParams(window.location.search)
      let urlCategoryName = params.get('category') ?? ''

      const { categoryName = '' } = categories?.find(({ categoryName }) => categoryName === decodeURIComponent(urlCategoryName)) || {}

      if (categoryName !== '') {
        toggleCategorySelected(categoryName)
      }
      doUpdateRewards()
      setDelayRender(false)
    }
  }, [categories, doUpdateRewards, toggleCategorySelected])

  useEffect(() => {
    if (doGetWishlist) {
      doGetWishlist()
    }
  }, [doGetWishlist])

  if (Object.keys(rewards).length && rewardIds?.length) {
    rewardsList = earn
      ? convertCmsEarnsToTiles(rewardIds.slice(0, limit || rewardIds.length), rewards as { [rewardId: string]: RewardEarn })
      : convertCmsRewardsToTiles(rewardIds.slice(0, limit || rewardIds.length), rewards as { [rewardId: string]: Reward })

    Object.keys(ctaTiles)
      .map(Number)
      .sort((a, b) => a - b)
      .forEach((position) => {
        if (rewardsList.length > position) {
          rewardsList.splice(position, 0, ctaTiles[position as keyof typeof ctaTiles])
        } else {
          rewardsList.push(ctaTiles[position as keyof typeof ctaTiles])
        }
      })
  }

  return (
    <>
      <style>{`
        .tile-lister-spinner {
          align-items: center;
          display: flex;
          min-height: 500px;
        }
        .load-more-button {
          margin: 0px auto;
          margin-bottom: 22px;
          width: 140px;
        }
        @media ${media.tabletAndHigher} {
          .tile-lister-spinner {
            min-height: 600px;
          }
        }
        @media ${media.desktopAndHigher} {
          .tile-lister-spinner {
            min-height: 515px;
          }
        }
      `}</style>
      {(isLoading || delayRender) && (
        <div className="tile-lister-spinner">
          <LoadSpinner />
        </div>
      )}
      {!isLoading && rewardsList.length > 0 && (
        <TileLister
          tiles={rewardsList}
          showWishListIcons={showWishListIcons}
          wishlist={wishlist}
          isLoadingWishlist={isLoadingWishlist}
          doRemoveRewardFromWishlist={doRemoveRewardFromWishlist}
          doAddRewardToWishlist={doAddRewardToWishlist}
        />
      )}
      {pagination?.hasMore && (
        <div className="load-more-button">
          <Button
            isPrimary={false}
            buttonType="button"
            dataAttributes={{ testid: 'btn-load-more' }}
            text="Load more"
            loading={isLoading}
            onClick={() => doRefreshRewards(true)}
          />
        </div>
      )}
    </>
  )
}
