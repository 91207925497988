import React from 'react'
import { View, Text, ViewProps } from '@red-ui/components'
import { formatPoints } from '@vrw/data/src/utils/formatters'
import { getString } from '@vrw/data'

type Props = {
  label: string
  points: number
} & ViewProps

export const FlightPointsPrice = ({ label, points, ...props }: Props) => {
  return (
    <View flexDirection="row" justifyContent="space-between" alignItems="flex-end" {...props}>
      <Text
        marginRight="$2"
        fontSize="$3"
        $gtMobile={{ fontSize: '$5', marginBottom: '$1' }}
        $gtTabletLandscape={{ fontSize: '$6', marginBottom: '$0' }}>
        {label}
      </Text>
      <strong>
        <Text lineHeight="$7" fontSize="$7" $gtMobile={{ fontSize: '$8' }} $gtTabletLandscape={{ fontSize: '$9' }}>
          {`${formatPoints({ points })} `}
          <Text>{getString('redSkySearch.results.card.points')}</Text>
        </Text>
      </strong>
    </View>
  )
}
