import { createSelector } from 'reselect'
import { RedDataState } from '../types'

const getPromoCodesState = (state: RedDataState) => state.promoCodes

const getIsLoading = createSelector(getPromoCodesState, (promoCodes) => promoCodes.isLoading)

const getHasSubmitted = createSelector(getPromoCodesState, (promoCodes) => promoCodes.hasSubmitted)

const getErrorMessage = createSelector(getPromoCodesState, (promoCodes) => promoCodes.errorMessage)

const getPromoPoints = createSelector(getPromoCodesState, (promoCodes) => promoCodes.pointsAdded)

export { getIsLoading, getHasSubmitted, getErrorMessage, getPromoPoints }
