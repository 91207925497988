import { NavigateOptions, generatePath, useLocation, useNavigate } from 'react-router-dom'
import { getViewingRegion, regionalizePath } from './viewingRegionWeb'
import { ViewingRegion } from '@vrw/data/src/utils'

// We should generally use this in place of the plain navigate
export const useRedNavigate = () => {
  const navigate = useNavigate()
  const { pathname, hash } = useLocation()
  const currentPathWithHash = `${pathname}${hash ?? ''}`

  return (routeName: string, params?: { [key: string]: any }, region?: ViewingRegion, options?: NavigateOptions) => {
    const currentRegion = getViewingRegion()
    const target = generatePath(regionalizePath(routeName, region ?? currentRegion), params)
    const completeTarget = `${target}${hash ?? ''}`

    if (completeTarget !== currentPathWithHash) {
      navigate(completeTarget, options)
    }
  }
}
