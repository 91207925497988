import { useCallback, useState } from 'react'
import { Airport } from '../../api/searchAirports'

export type SearchForm = {
  departure: Airport | null
  destination: Airport | null
  date: Date
  adults: number
  youngAdults: number
  children: number
  infant: number
}

export const getInitialState = (): SearchForm => ({
  departure: null,
  destination: null,
  date: new Date(),
  adults: 1,
  youngAdults: 0,
  children: 0,
  infant: 0,
})

export type OnChangeType = (field: keyof SearchForm, value: string | number | Airport | Date) => void

export const useSearchForm = (initialData?: SearchForm | null) => {
  const seedData = initialData ?? getInitialState()
  const [form, setForm] = useState<SearchForm>(seedData)

  const onChange: OnChangeType = useCallback((field, value) => setForm((prevForm) => ({ ...prevForm, [field]: value })), [])

  const onReset = useCallback(() => setForm(seedData), [seedData])

  return { form, onChange, onReset }
}
