import React, { FC, ReactNode, useState } from 'react'
import { BLOCKS, Node } from '@contentful/rich-text-types'
import { getString } from '@vrw/data'
import { RedemptionType, Reward, RewardVariant } from '@vrw/data/src/redux/rewards/types'
import { color, ZIndex, layout } from '@/style/variables'
import { AuthWrapper, HelpCTA, LoginBox, Page, PageLoading, Spacer } from '@/components'
import ContentH1 from '@/components/typography/Red/ContentH1'
import { Button } from '@/components/Buttons/Button'
import ContentfulCopy from '@/components/richText/contentfulCopy'
import { RewardImage } from '@/components/RewardDetails/RewardImage'
import RewardInfoWrapper from '@/components/RewardDetails/RewardInfoWrapper'
import RewardInfoContent from '@/components/RewardDetails/RewardInfoContent'
import RewardContent from '@/components/RewardDetails/RewardContent'
import RewardCta from '@/components/RewardDetails/RewardCta'
import { DateAndLocation } from '@/components/DateAndLocation'
import { WishListIcon } from '@/components/WishListIcon'
import Header from '@/components/RewardDetails/Header'
import { RewardDetailsProps } from './types'
import { useReward } from './useReward'
import { H1, View, Paragraph as P, Text, useMedia } from '@red-ui/components'
import { VariantSelector } from './VariantSelector'
import { useTrackRewardEvent } from '@/analytics/useTrackRewardEvent'
import { ErrorPage404 } from '../ErrorPage404/ErrorPage404'
import { formatPoints } from '@vrw/data/src/utils/formatters'

const renderContentH1 = (node: Node, children: ReactNode) => <ContentH1>{children}</ContentH1>

export const RewardDetails: FC<RewardDetailsProps> = ({
  isEarn,
  doRedeemReward,
  onViewReward,
  doFetchReward,
  isLoading,
  errorMessage,
  testIdPrefix,
  doAddRewardToWishlist,
  doRemoveRewardFromWishlist,
  isLoadingWishlist,
  doGetWishlist,
  getRewardById,
}) => {
  const media = useMedia()
  const [selectedOption, setSelectedOption] = useState<RewardVariant | null>(null)
  const { rewardId, reward, isGroupReward, isLoadingState, isErrorState, isWished, trackRewardRedeemedEvent, onRender } = useReward({
    isEarn,
    getRewardById,
    doFetchReward,
    onViewReward,
    doGetWishlist,
    isLoading,
    errorMessage,
  })
  const testId = `${testIdPrefix}-${rewardId}`

  const { trackRewardRedeemedEvent: trackParentRewardRedeemedEvent } = useTrackRewardEvent(selectedOption ?? undefined, 'spend')

  if (isLoadingState) {
    return (
      <Page testId={testId} title={'… | Virgin Red'}>
        <PageLoading />
      </Page>
    )
  }

  if (isErrorState) {
    return <ErrorPage404 />
  }

  if (!reward) return null

  const {
    cost = 0,
    redemptionType,
    earnType,
    content: {
      title,
      description,
      brandDetails: { brandName, brandLogo },
      imageUrl,
      redemption,
      location = '',
      dateAndTime = '',
      redeemButtonTextOverride,
    },
    variants = [],
  } = reward as Reward

  const zeroPointsGroupReward = cost === 0 && isGroupReward

  const isDonation = redemptionType === RedemptionType.DONATION_LINK
  const isParentReward = variants.length > 0

  let redeemButtonText
  if (isEarn) {
    redeemButtonText = redeemButtonTextOverride ?? getString('rewardDetails.button.getPoints')
  } else {
    redeemButtonText = isDonation ? getString('rewardDetails.button.donate') : getString('rewardDetails.button.redeem')
  }
  const redeemButtonId = `${redeemButtonText.toLowerCase().replace(/\s/g, '-')}-button`

  const getOnWishListIconClick = () => {
    return isWished
      ? () => doRemoveRewardFromWishlist(rewardId, isEarn ? 'EARN' : 'SPEND')
      : () => doAddRewardToWishlist(rewardId, isEarn ? 'EARN' : 'SPEND')
  }

  const onRedeemButtonPress = () => {
    const { rewardId: childRewardId } = selectedOption ?? {}
    doRedeemReward(childRewardId ?? rewardId)

    if (selectedOption) {
      return trackParentRewardRedeemedEvent()
    }

    return trackRewardRedeemedEvent()
  }

  const handleSelectVariant = (rewardId: string) => {
    const selected = variants.find((variant) => variant.rewardId === rewardId)

    if (selected) {
      setSelectedOption(selected)
    }
  }

  return (
    <>
      <Header disableCurve={media.mobile} position="absolute" left={0} right={0} top={0}>
        <View $gtMobile={{ height: 227 }} $gtTabletLandscape={{ height: 387 }} />
      </Header>
      <Page testId={testId} title={`${title} | Virgin Red`} navigationFocusHandler={onRender} metaDescription={description}>
        <View
          style={{ justifySelf: 'center' }}
          marginInline={'auto'}
          width={'100%'}
          $gtTabletPortrait={{
            width: layout.heroWidth.tablet,
          }}
          $gtTabletLandscape={{ marginTop: 60, width: layout.heroWidth.desktop }}>
          <AuthWrapper
            publicComponent={
              <View
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  zIndex: ZIndex.carouselBoxShadow,
                  top: 8,
                  right: 8,
                }}>
                <WishListIcon isWished={isWished} onWishListIconClick={getOnWishListIconClick()} isLoading={isLoadingWishlist} />
              </View>
            }
            privateComponent={
              <View
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  zIndex: ZIndex.carouselBoxShadow,
                  top: 8,
                  right: 8,
                }}>
                {!isGroupReward && (
                  <WishListIcon isWished={isWished} onWishListIconClick={getOnWishListIconClick()} isLoading={isLoadingWishlist} />
                )}
              </View>
            }
          />
          <RewardImage
            rewardImageUrl={imageUrl}
            rewardImageAlt={title}
            brandLogoUrl={brandLogo}
            brandLogoAlt={brandName}
            isEarn={isEarn}
            earnType={earnType}
          />
        </View>
        <RewardInfoWrapper>
          <RewardInfoContent>
            <H1
              display="block"
              fontStyle="italic"
              textAlign="center"
              fontWeight={'700'}
              textTransform="uppercase"
              $gtTabletLandscape={{ marginTop: '$11', marginBottom: 32, size: '$10', fontWeight: '700' }}
              $gtMobile={{ marginTop: '$11', marginBottom: 32, size: '$9', fontWeight: '700' }}
              $mobile={{ marginTop: '$6', marginBottom: 24, size: '$7', fontWeight: '700' }}>
              {title}
            </H1>
            <Spacer size="medium" />
            {(location || dateAndTime) && (
              <>
                <View display="flex" justifyContent="center" flexDirection="column">
                  <DateAndLocation location={location} dateAndTime={dateAndTime} />
                </View>
                <Spacer size="medium" />
              </>
            )}

            <P $mobile={{ size: '$4' }} $gtMobile={{ size: '$5' }}>
              {description}
            </P>

            {!isEarn && isParentReward && (
              <>
                <VariantSelector variants={variants} onSelect={handleSelectVariant} />
                <View $gtTabletPortrait={{ height: '$1' }} />
              </>
            )}
          </RewardInfoContent>
          <View height="$2" />

          <View
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            marginBlock="$6"
            $gtMobile={{ flexDirection: 'row', gap: '$8' }}>
            {isEarn || zeroPointsGroupReward || (isParentReward && selectedOption == null) ? null : (
              <Text
                fontWeight={600}
                fontStyle="italic"
                fontSize={'$8'}
                color={color.brandPrimary}
                marginBottom={'$2'}
                $gtMobile={{ fontSize: '$9' }}
                $gtTabletLandscape={{ fontSize: '$10' }}>
                {`${formatPoints({
                  points: selectedOption?.cost ?? cost,
                  showPlus: redemptionType === RedemptionType.SIMPLE_LINK,
                })} point${selectedOption?.cost || cost !== 1 ? 's' : ''}`}
              </Text>
            )}
            <AuthWrapper
              publicComponent=""
              privateComponent={
                <View width={'$18'} height={'$6'}>
                  <Button
                    id={redeemButtonId}
                    text={redeemButtonText}
                    display="inline-block"
                    isPrimary
                    disabled={isParentReward && selectedOption === null}
                    onClick={onRedeemButtonPress}
                  />
                </View>
              }
            />
          </View>
          <AuthWrapper
            publicComponent={<LoginBox isEarn={isEarn} />}
            privateComponent={
              <View paddingTop="$5">
                <RewardCta>
                  <RewardInfoContent>
                    {redemption && redemption.method && (
                      <ContentfulCopy
                        content={redemption.method}
                        renderNodeOverrides={{
                          [BLOCKS.HEADING_1]: renderContentH1,
                        }}
                      />
                    )}
                  </RewardInfoContent>
                </RewardCta>
              </View>
            }
          />
          <RewardContent reward={reward} />
        </RewardInfoWrapper>
        <HelpCTA />
      </Page>
    </>
  )
}
