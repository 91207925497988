import { Asset } from 'contentful'

export interface ImageProps {
  url: string
  title?: string
  alt?: string
  caption?: string
}

const convertContentfulImage = (contentfulImage?: Asset): ImageProps | undefined => {
  const url = contentfulImage?.fields?.file?.url
  if (!url) return undefined
  return {
    url,
    title: contentfulImage?.fields?.title || '',
    caption: contentfulImage?.fields?.description,
    alt: contentfulImage?.fields?.description || contentfulImage?.fields?.title,
  } as ImageProps
}

export { convertContentfulImage }
