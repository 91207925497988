import React, { useEffect } from 'react'
import { getString } from '@vrw/data'
import { apiRecordOrderMentionMe } from '@vrw/data/src/api/mentionme.api'
import { Profile } from '@vrw/data/src/redux/profile/types'
import { doSubmitOnboarding } from '@vrw/data/src/redux/profile/dispatchers'
import { getProfileData } from '@vrw/data/src/redux/profile/selectors'
import { LinkingJourneyOrigins, logger, ViewingRegion } from '@vrw/data/src/utils'
import H3Header from '../../../components/typography/Red/H3Header'
import { Button } from '../../../components/Buttons/Button'
import { Spacer, Page } from '../../../components'
import { color } from '../../../style/variables'
import { MENTION_ME_PARTNER_CODE, PUBLIC_URL } from '../../../config'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/hooks'
import { LinkAccountsSuccess } from '../LinkAccountsSuccess'
import { LinkAccountsError } from '../LinkAccountsError'
import { OnboardingBackground } from '../OnboardingBackground'
import { LinkAccountsAutoEnrolled } from '../LinkAccountsAutoEnrolled'
import { useLinkVAA } from '@/hooks/useLinkVAA'

const WelcomeDefault = ({ linkStatus, linkVAA }: { linkStatus: string; linkVAA: (journey: LinkingJourneyOrigins) => void }) => {
  const dispatch = useAppDispatch()

  return (
    <>
      <style jsx>{`
        @keyframes in {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        .parentContainer {
          animation: in 0.3s cubic-bezier(0, 0, 0.25, 1);
        }
        .parentContainer :global(.header-three) {
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 16px;
        }
        .image {
          width: 100%;
          height: 100%;
          max-width: 270px;
          margin: 45px auto;
          display: block;
        }
        .body-wrapper {
          text-align: center;
          margin-bottom: 40px;
        }
        .body-wrapper b {
          font-weight: 600;
        }
        .button-wrapper :global(button):not(:last-of-type) {
          margin-bottom: 16px;
        }
      `}</style>
      <div className="parentContainer">
        <H3Header
          color={color.brandPrimary}
          textAlign="center"
          textTransform="none"
          marginTop={{ mobile: 0 }}
          marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}>
          {getString('onboarding.welcomeAndLink.title')}
        </H3Header>
        <img
          src={`${PUBLIC_URL}/img/link-vaa-account/unlinked.png`}
          className="image"
          alt="Virgin Red and Virgin Atlantic Airlines unlinked logo"
        />
        <div className="body-wrapper">
          <b>{getString('onboarding.welcomeAndLink.body1')}</b>
          <Spacer size="xSmall" />
          {getString('onboarding.welcomeAndLink.body2')}
          <Spacer size="xSmall" />
          {getString('onboarding.welcomeAndLink.body3')}
        </div>
        <div className="button-wrapper">
          <Button
            text={getString('onboarding.welcomeAndLink.linkButton')}
            onClick={() => linkVAA(LinkingJourneyOrigins.ONBOARDING)}
            loading={linkStatus === 'loading'}
            dataAttributes={{ testid: 'onboarding.welcome.linkButton' }}
          />
          <Button
            isPrimary
            text={getString('onboarding.welcomeAndLink.skipButton')}
            onClick={() => dispatch(doSubmitOnboarding({ linkedAccountsState: { VAA: false }, complete: true }))}
            loading={linkStatus === 'loading'}
            dataAttributes={{ testid: 'onboarding.welcome.skipButton' }}
          />
        </div>
      </div>
    </>
  )
}

export const Welcome = () => {
  const profile = useAppSelector(getProfileData)
  const { email, firstName, lastName, redUserId, country, linkedAccountsState } = profile as Profile
  const vaaAccountAutoEnrolled = linkedAccountsState?.VAA
  const { linkVAA, linkStatus, isVAALinked } = useLinkVAA()

  useEffect(() => {
    if (email && firstName && lastName && redUserId && country === ViewingRegion.GB) {
      const customer = {
        firstname: firstName,
        surname: lastName,
        emailAddress: email,
        uniqueIdentifier: redUserId,
      }

      // mention me referral scheme uses the Order API to identify referrals and customers, data populated according to marketing team requirements
      const order = {
        orderIdentifier: redUserId,
        total: '0',
        currencyCode: 'GBP',
        dateString: new Date().toISOString(),
      }

      const request = {
        partnerCode: MENTION_ME_PARTNER_CODE,
        situation: 'welcome',
      }

      const postData = async () => {
        try {
          await apiRecordOrderMentionMe(customer, order, request)
        } catch (err) {
          logger.warn('Welcome: Error posting to Mention Me API: ', err)
        }
      }

      postData()
    }
  }, [email, firstName, lastName, redUserId, country])

  const getWelcomeComp = () => {
    if (vaaAccountAutoEnrolled) return <LinkAccountsAutoEnrolled />
    if (linkStatus === 'success' || isVAALinked) return <LinkAccountsSuccess />
    if (linkStatus === 'failed') return <LinkAccountsError />
    return <WelcomeDefault linkStatus={linkStatus} linkVAA={linkVAA} />
  }

  return (
    <Page testId="onboarding-link-accounts" title="Link your flying club account | Virgin Red" showNavigation={false} showFooter={false}>
      <OnboardingBackground showSteps={false}>{getWelcomeComp()}</OnboardingBackground>
    </Page>
  )
}
