import React, { FC, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import { MenuItem, TextField, Checkbox, LocalizationProvider, AdapterDateFns, MuiDatePicker } from '@virgin-core/components'
import { getString } from '@vrw/data'
import { yupValidationSchemaForPersonalDetails } from '@vrw/data/src/redux/profile/validations'
import {
  PersonalDetails as PersonalDetailsType,
  PersonalDetailsFormPropsType,
  UsaState,
  UsaStates,
} from '@vrw/data/src/redux/profile/types'
import { getStringOrNull, transformInterpolatedString } from '@vrw/data/src/utils/getString'
import { ContentfulLocale } from '@vrw/data/src/utils/contentfulPages'
import { ViewingRegion, getLocalisedString, getRegionDisplayName, parseDigits } from '@vrw/data/src/utils/helperFunctions'

import { color } from '../../../style/variables'
import { Page, StyledCopyAnchor } from '../../../components'
import H3Header from '../../../components/typography/Red/H3Header'
import { Button } from '../../../components/Buttons/Button'
import { OnboardingBackground } from '../OnboardingBackground'
import { getViewingRegion } from '../../../dataImplementation/viewingRegionWeb'
import { PATHS, virginRedPath } from '../../../router/paths'
import styles from './PersonalDetails.styles'
import { useIncentiveCodes } from '../../../helpers'
import { Trans } from 'react-i18next'
import { format } from 'date-fns'

export interface PersonalDetailsProps {
  isUpdating: boolean
  termsVersion?: string
  doSubmitOnboarding: Function
  doFetchTermsAndConditions: (viewingRegion?: ContentfulLocale | string) => void
}

export const commsPrefsData = {
  commsIntroText: getString('onboarding.personalDetails.communicationPreferences.introText'),
  commsInstructionText: getString('onboarding.personalDetails.communicationPreferences.instruction'),
  emailLabel: getString('onboarding.personalDetails.communicationPreferences.marketingEmail.label'),
  smsLabel: getString('onboarding.personalDetails.communicationPreferences.marketingSms.label'),
}

const PersonalDetails: FC<PersonalDetailsProps> = ({ isUpdating, termsVersion, doFetchTermsAndConditions, doSubmitOnboarding }) => {
  const screenId = 'onboarding.personalDetails'
  const viewingRegion = getViewingRegion()
  const { getCodes } = useIncentiveCodes()

  const { commsIntroText, commsInstructionText, emailLabel, smsLabel } = commsPrefsData

  useEffect(() => {
    if (!termsVersion) doFetchTermsAndConditions(`EN_${viewingRegion}`)
  }, [doFetchTermsAndConditions, termsVersion, viewingRegion])

  const formikInitialValues: PersonalDetailsFormPropsType = {
    country: viewingRegion,
    firstName: '',
    lastName: '',
    dateOfBirth: null, // if dateOfBirth is defined as '' yup will throw a invalid date error
    mobile: '',
    postcode: '',
    state: '',
    // Consent is opt-out on form but opt-in in API, so reverse boolean value
    marketingEmail: true,
    marketingSms: true,
    marketingTel: true,
    termsVersion: '',
  }

  const handleSubmit = async (values: PersonalDetailsFormPropsType) => {
    const { state, ...formattedFormStateWithoutState } = values
    const incentiveCodes = getCodes()
    const formattedFormState = {
      ...formattedFormStateWithoutState,
      ...(state && { state }),
      ...{
        mobile: parseDigits(values.mobile),
        dateOfBirth: format(values.dateOfBirth as Date, 'dd/MM/yyyy'),
        marketingText: `${commsIntroText}\n${commsInstructionText}`,
        marketingEmailText: emailLabel,
        marketingSmsText: smsLabel,
        // This variable isn't needed on Web but has to be set to false to skip the Notifications Consent screen
        notifications: false,
      },
    } as PersonalDetailsType

    await doSubmitOnboarding(formattedFormState, { incentiveCodes })
  }

  return (
    <Page testId="onboarding-personal-details" title="Your details | Virgin Red" showNavigation={false} showFooter={false}>
      <OnboardingBackground showSteps={false}>
        <style jsx>{styles}</style>
        <div className="parentContainer">
          <H3Header
            textTransform="none"
            color={color.brandPrimary}
            marginTop={{ mobile: 0 }}
            marginBottom={{ mobile: 0, tablet: 0, desktop: 0 }}>
            {getString(`${screenId}.heading`)}
          </H3Header>
          <p className="instructions">{getString(`${screenId}.introText`)}</p>
          <Formik
            validateOnBlur={true}
            validateOnChange={true}
            initialValues={formikInitialValues}
            validationSchema={yupValidationSchemaForPersonalDetails(screenId)}
            onSubmit={handleSubmit}>
            {({ setFieldValue, values, touched, errors, handleBlur }) => {
              const isUS = values.country === ViewingRegion.US
              return (
                <Form>
                  <section className="personal-details-section">
                    <h4 className="section-header">{getString(`${screenId}.yourDetails.header`)}</h4>
                    <Field
                      data-testid={`${screenId}.country`}
                      component={TextField}
                      id="country"
                      name="country"
                      as="select"
                      label={getString(`${screenId}.country.label`) + '*'}
                      placeholder={getStringOrNull(`${screenId}.country.placeholder2`)}
                      error={touched.country && !!errors.country}
                      helperText={touched.country && errors.country}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        setFieldValue('country', event.target.value)
                      }}
                      value={values.country}
                      select
                      style={{ pointerEvents: 'all' }}
                      iconColor={color.textStandard}
                      fullWidth>
                      {Object.keys(ViewingRegion).map((enumKey) => (
                        <MenuItem
                          data-testid={`menu-item-${enumKey}`}
                          id={`menu-item-${enumKey}`}
                          key={`menu-item-${enumKey}`}
                          value={enumKey}>
                          {getRegionDisplayName(enumKey as ViewingRegion)}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      data-testid={`${screenId}.firstName`}
                      component={TextField}
                      id="firstName"
                      name="firstName"
                      type="text"
                      label={getString(`${screenId}.firstName.label`) + '*'}
                      placeholder={getStringOrNull(`${screenId}.firstName.placeholder`)}
                      error={touched.firstName && !!errors.firstName}
                      helperText={touched.firstName && errors.firstName}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('firstName', event.target.value)}
                      onBlur={handleBlur}
                      fullWidth
                    />
                    <Field
                      data-testid={`${screenId}.lastName`}
                      component={TextField}
                      id="lastName"
                      name="lastName"
                      type="text"
                      label={getString(`${screenId}.lastName.label`) + '*'}
                      placeholder={getStringOrNull(`${screenId}.lastName.placeholder`)}
                      error={touched.lastName && !!errors.lastName}
                      helperText={touched.lastName && errors.lastName}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('lastName', event.target.value)}
                      onBlur={handleBlur}
                      fullWidth
                    />
                    <div className="dob-field">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MuiDatePicker
                          data-testid={`${screenId}.dateOfBirth`}
                          className="dob-field-picker"
                          label={getString(`${screenId}.dateOfBirth.label`)}
                          onChange={(value) => setFieldValue('dateOfBirth', value)}
                          value={values.dateOfBirth}
                          format={isUS ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
                          slotProps={{
                            textField: {
                              required: true,
                              helperText: 'Please provide your date of birth',
                              onBlur: handleBlur,
                            },
                            openPickerButton: {
                              'aria-label': 'change date',
                            },
                          }}
                        />
                      </LocalizationProvider>
                      <div className="dob-note">{getString(`${screenId}.dateOfBirth.terms`)}</div>
                    </div>
                    <Field
                      data-testid={`${screenId}.mobile`}
                      component={TextField}
                      id="mobile"
                      name="mobile"
                      type="tel"
                      label={getString(`${screenId}.mobile.label${isUS ? '.US' : ''}`) + '*'}
                      placeholder={getString(`${screenId}.mobile.placeholder${isUS ? '.US' : ''}`)}
                      error={touched.mobile && !!errors.mobile}
                      helperText={touched.mobile && errors.mobile}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('mobile', event.target.value)}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: isUS ? 10 : 11 }}
                      fullWidth
                    />
                    {isUS && (
                      <>
                        <Field
                          data-testid={`${screenId}.state`}
                          component={TextField}
                          id="state"
                          name="state"
                          as="select"
                          label={getString(`${screenId}.state.label`) + '*'}
                          placeholder={getString(`${screenId}.state.label`)}
                          error={touched.state && !!errors.state}
                          helperText={touched.state && errors.state}
                          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setFieldValue('state', event.target.value)}
                          onBlur={(event: React.ChangeEvent<HTMLSelectElement>) =>
                            handleBlur({ ...event, target: { ...event.target, name: 'state' } })
                          }
                          value={values.state}
                          select
                          iconColor={color.textStandard}
                          style={{ pointerEvents: 'all' }}
                          fullWidth>
                          {UsaStates.map((state: UsaState) => (
                            <MenuItem key={`menu-item-${state.name}`} id={`menu-item-${state.name}`} value={state.code}>
                              {state.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </>
                    )}
                    <Field
                      data-testid={`${screenId}.postcode`}
                      component={TextField}
                      className="postcode-field"
                      id="postcode"
                      name="postcode"
                      type="text"
                      label={getString(`${screenId}.postcode.label${isUS ? '.US' : ''}`) + '*'}
                      placeholder={getStringOrNull(`${screenId}.postcode.placeholder${getLocalisedString(values.country)}`)}
                      error={touched.postcode && !!errors.postcode}
                      helperText={touched.postcode && errors.postcode}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('postcode', event.target.value)}
                      onBlur={handleBlur}
                      tooltipText={getString(`${screenId}.postcode.infoModal.body${getLocalisedString(values.country)}`)}
                      iconColor={color.textStandard}
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      fullWidth
                    />
                  </section>
                  <section className="communication-preferences-section">
                    <h4 className="section-header">{getString(`${screenId}.communicationPreferences.header`)}</h4>
                    <p className="instructions">
                      {commsIntroText}
                      <br />
                      <br />
                      {commsInstructionText}
                    </p>
                    <div className="checkbox-wrapper">
                      <Checkbox
                        data-testid="checkbox-email"
                        name="marketingEmail"
                        label={emailLabel}
                        checked={!values.marketingEmail}
                        onChange={(event: any, checked: boolean) => setFieldValue('marketingEmail', !checked)}
                      />
                      <Checkbox
                        data-testid="checkbox-sms"
                        name="marketingSms"
                        label={smsLabel}
                        checked={!values.marketingSms}
                        onChange={(_, checked: boolean) => setFieldValue('marketingSms', !checked)}
                      />
                    </div>
                    <p className="instructions">{getString(`${screenId}.communicationPreferences.outroText`)}</p>
                  </section>
                  <section className="terms-section">
                    <h4 className="section-header">{getString(`${screenId}.termsVersion.header`)}</h4>
                    <p className="instructions">
                      {getString(`${screenId}.termsVersion.introText1`)}
                      <br />
                      <br />
                      <Trans
                        defaults={transformInterpolatedString(getString(`${screenId}.termsVersion.introText2`))}
                        components={{
                          tcLink: <StyledCopyAnchor to={virginRedPath + PATHS.PRIVACY} target external />,
                        }}
                      />
                      <br />
                      <br />
                      {getString(`${screenId}.termsVersion.introText3`)}
                    </p>
                    <div className="checkbox-wrapper">
                      <Checkbox
                        data-testid="checkbox-terms"
                        name="termsVersion"
                        // @ts-ignore
                        label={
                          <Trans
                            defaults={transformInterpolatedString(getString(`${screenId}.termsVersion.label`))}
                            components={{
                              tcLink: <StyledCopyAnchor to={virginRedPath + PATHS.TERMS_AND_CONDITIONS} target external />,
                            }}
                          />
                        }
                        checked={values.termsVersion === termsVersion}
                        onChange={(_, checked: boolean) => {
                          touched.termsVersion = true
                          setFieldValue('termsVersion', checked ? termsVersion : '')
                        }}
                      />
                      {touched.termsVersion && errors.termsVersion && <div className="terms-error">{errors.termsVersion}</div>}
                    </div>
                  </section>
                  <div className="button-wrapper">
                    <Button
                      dataAttributes={{ testid: 'onboarding-personal-details-button' }}
                      text={getString('onboarding.personalDetails.confirmButton')}
                      isPrimary
                      buttonType="submit"
                      onClick={() => {}}
                      loading={isUpdating}
                      display="inline-block"
                      isFullWidth={false}
                    />
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </OnboardingBackground>
    </Page>
  )
}

export { PersonalDetails }
