import React, { FC, useState, useEffect } from 'react'
import { doPartnerLinkVAAWeb, doLinkAccountsWebResponseHandler } from '@vrw/data/src/redux/accounts/dispatchers'
import { getIsUpdating, getPartnerLinkVAASuccessUrl, getPartnerLinkVAAFailUrl } from '@vrw/data/src/redux/accounts/selectors'
import { getProfileData } from '@vrw/data/src/redux/profile/selectors'
import { LinkingJourneyOrigins } from '@vrw/data/src/utils'
import {
  handlePartnerVAALinking,
  getPartnerVAALinkingParams,
  removeAllPartnerVAALinkingParams,
} from '../../../dataImplementation/partnerVAALinkingWeb'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/hooks'
import { Page, LoadSpinner } from '../../../components'
import { OnboardingBackground } from '../OnboardingBackground'
import { PUBLIC_URL } from '../../../config'

enum LinkingStatus {
  LOADING = 'loading',
  PRELINKED = 'prelinked',
  SUCCESS = 'success',
  FAILED = 'failed',
  PARAM_ERROR = 'param_error',
}

type urlType = string | null | undefined

const PartnerLinkVAA: FC = () => {
  const dispatch = useAppDispatch()
  const isUpdating = useAppSelector(getIsUpdating)
  const profile = useAppSelector(getProfileData)
  const vaaAccountPreLinked = profile?.linkedAccountsState?.VAA
  const successURL: urlType = useAppSelector(getPartnerLinkVAASuccessUrl)
  const failURL: urlType = useAppSelector(getPartnerLinkVAAFailUrl)
  const [vaaLinkingStatus, setVAALinkingStatus] = useState<LinkingStatus>(LinkingStatus.LOADING)
  const [isRedirecting, setIsRedirecting] = useState(false)

  const redirectToURL = (url: urlType) => {
    if (url) {
      setIsRedirecting(true)
      setTimeout(() => {
        window.location.href = `${url}`
      }, 5000)
    }
  }

  const handleVAALinkingResponse = async () => {
    const response = await dispatch(doLinkAccountsWebResponseHandler()) // sets isUpdating to false
    setVAALinkingStatus(response.done ? LinkingStatus.SUCCESS : LinkingStatus.FAILED)
    redirectToURL(response.done ? successURL : failURL)
  }

  useEffect(() => {
    handlePartnerVAALinking() // stores url params in local storage
  }, [])

  useEffect(() => {
    const { linkVAA, onLinkSuccessUrl, onLinkFailUrl } = getPartnerVAALinkingParams() // gets url params if they exist in local storage
    if (vaaAccountPreLinked) {
      setVAALinkingStatus(LinkingStatus.PRELINKED)
      redirectToURL(onLinkSuccessUrl)
    } else if (linkVAA === 'true') {
      setVAALinkingStatus(LinkingStatus.LOADING)
      dispatch(doPartnerLinkVAAWeb(LinkingJourneyOrigins.PARTNER, onLinkSuccessUrl, onLinkFailUrl)) // sets isUpdating to true
    } else {
      setVAALinkingStatus(LinkingStatus.PARAM_ERROR)
    }
    removeAllPartnerVAALinkingParams()
  }, [dispatch, vaaAccountPreLinked])

  useEffect(() => {
    if (isUpdating) {
      setVAALinkingStatus(LinkingStatus.LOADING)
      handleVAALinkingResponse()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <style jsx>{`
        :global(.smartbanner),
        :global(.js_smartbanner) {
          display: none;
        }
        @keyframes in {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        .parentContainer {
          text-align: center;
          animation: in 0.3s cubic-bezier(0, 0, 0.25, 1);
        }
        .image {
          width: 100%;
          height: 100%;
          max-width: 270px;
          margin: 45px auto;
          display: block;
        }
      `}</style>
      <Page
        testId="onboarding-partner-link-vaa"
        title="Link your flying club account | Virgin Red"
        showNavigation={false}
        showFooter={false}>
        <OnboardingBackground showSteps={false}>
          <div className="parentContainer partnerLinkVAAContainer">
            <img
              src={`${PUBLIC_URL}/img/link-vaa-account/unlinked.png`}
              className="image"
              alt="Virgin Red and Virgin Atlantic Airlines unlinked logo"
            />
            {vaaLinkingStatus === LinkingStatus.PRELINKED && (
              <div className="partnerLinkVAAPrelinked" data-testid="prelinked">
                <b>Account Linked</b>
                <p>Your account has already been linked to a Flying Club account.</p>
              </div>
            )}
            {vaaLinkingStatus === LinkingStatus.SUCCESS && (
              <div className="partnerLinkVAASuccess" data-testid="success">
                <b>Success</b>
                <p>Your Flying Club account has been linked successfully.</p>
              </div>
            )}
            {vaaLinkingStatus === LinkingStatus.FAILED && (
              <div className="partnerLinkVAAFailed" data-testid="failed">
                <b>Failed</b>
                <p>Your Flying Club account could not be linked at this time.</p>
                <p>Try again later.</p>
              </div>
            )}
            {vaaLinkingStatus === LinkingStatus.PARAM_ERROR && (
              <div className="partnerLinkVAAParamError" data-testid="param-error">
                <b>Parameter Error</b>
                <p>No valid URL parameters supplied.</p>
              </div>
            )}
            {isRedirecting && vaaLinkingStatus !== LinkingStatus.LOADING && (
              <div className="partnerLinkVAARedirecting" data-testid="redirecting">
                <LoadSpinner />
                <p>Redirecting...</p>
              </div>
            )}
            {vaaLinkingStatus === LinkingStatus.LOADING && (
              <div className="partnerLinkVAALoading" data-testid="loading">
                <LoadSpinner />
              </div>
            )}
          </div>
        </OnboardingBackground>
      </Page>
    </>
  )
}

export { PartnerLinkVAA }
