import React, { FC } from 'react'
import { Reward } from '@vrw/data/src/redux/rewards/types'
import { RewardEarn } from '@vrw/data/src/redux/rewardsEarn/types'

export interface RewardsListProps {
  rewards: Array<Reward | RewardEarn>
}

export const RewardsList: FC<RewardsListProps> = () => {
  return <div></div>
}
