import React, { FC } from 'react'
import { getString } from '@vrw/data'
import { color } from '../../style/variables'
import { media } from '../../style/media'
import { RewardsListPublicEarn, MobileAppsSplash, RewardsFilterWrapper } from '../../components'
import { CurvedContainer } from '../../components/CurvedContainer'
import { useEarnDiscovery } from './useEarnDiscovery'
import { useAppSelector } from '@/redux/hooks/hooks'
import { FeatureName } from '@vrw/data/src/redux/features/features.types'
import { useFeatureSelector } from '@vrw/data/src/redux/features/features.selectors'
import { RewardsList } from '../../components/RewardsList/RewardsList'

export const EarnPublic: FC = () => {
  const { rewards } = useEarnDiscovery()
  const newFiltersFlag = useAppSelector(useFeatureSelector(FeatureName.NEW_FILTERS))
  return (
    <>
      <style jsx>{`
        .hidden-seo-header {
          visibility: hidden;
          margin: 0;
          height: 0;
        }
        .pink {
          height: 210px;
          margin-top: 130px;
          margin-bottom: -350px;
          background-image: linear-gradient(to bottom, #fce6e6, #fef4f4 57%, #ffffff);
        }

        @media ${media.tabletAndHigher} {
          .pink {
            height: 200px;
            margin-bottom: -330px;
          }
        }

        @media ${media.desktopAndHigher} {
          .pink {
            height: 200px;
            margin-bottom: -350px;
          }
        }
      `}</style>
      <CurvedContainer position="bottom" height={65} offset={140} overlayColor={color.darkPinkBackground}>
        <h1 className="hidden-seo-header">{getString('earn.public.title').toUpperCase()}</h1>
        <RewardsFilterWrapper isEarn={true} isPublic={true} />
      </CurvedContainer>
      <div className="pink" />
      {newFiltersFlag ? <RewardsList rewards={rewards} /> : <RewardsListPublicEarn />}
      <MobileAppsSplash />
    </>
  )
}
