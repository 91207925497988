import React, { FC, useState } from 'react'
import { H1, Icon, Text, XStack, YStack, Paragraph, ButtonCard, View, useTheme, H3, CheckboxWithLabel } from '@red-ui/components'
import { format, addDays, differenceInDays } from 'date-fns'
import { getString } from '@vrw/data/src/utils'
import { formatPoints } from '@vrw/data/src/utils/formatters'

import { OrderCheckoutProps } from '../types'
import { useAppDispatch, useAppSelector } from '@/redux/hooks/hooks'
import { optimizeContentfulImageUrl } from '@/helpers/images'
import { SailorDetailsFormValues } from './DialogSailorDetails/types'
import { GENDERS, CITIZENSHIP } from './DialogSailorDetails/constants'
import { DialogSailorDetails } from './DialogSailorDetails/DialogSailorDetails'
import { Button } from '@/components/Buttons/Button'
import { Page, Spacer, Content, Picture, HelpCTA } from '@/components'
import Header from '@/components/RewardDetails/Header'
import DashedDivider from '@/components/DashedDivider'
import { CheckoutRewardSummary, DotList, ParsedStyledText } from './shared'
import { VoyageRewardContent } from '@vrw/data/src/redux/rewards/types'

const Voyages: FC<OrderCheckoutProps> = ({ reward, order, doUpdateOrderConsent, doPromptSubmitOrder }) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()

  const [isDialogSailorDetailsOpen, setIsDialogSailorDetailsOpen] = useState<boolean>(false)

  const isSubmitting = useAppSelector((state) => state.orders.isSubmitting)
  const sailorDetails = useAppSelector((state) => state.orders.inProgress.sailorDetails)
  const setSailorDetails = (sailorDetails: SailorDetailsFormValues) => {
    dispatch({ type: 'UPDATE_SAILOR_DETAILS', payload: sailorDetails })
  }

  let { cost, content, redemptionType } = reward || {}
  const {
    title,
    brandDetails,
    imageUrl,
    termsUrl,
    dateAndTime: voyageStartDate,
    endDate: voyageEndDate,
  } = (content as VoyageRewardContent) || {}
  const { brandName, brandLogo } = brandDetails || {}

  const handleChangeCheckbox = (name: string) => (checked: boolean) => {
    doUpdateOrderConsent({ [name]: checked })
  }

  const isFormValid = Boolean(order.consent.over18 && order.consent.terms && order.sailorDetails && order.consent.marketingUpdates)

  const handleSubmit = async () => {
    if (isFormValid && !isSubmitting) {
      doPromptSubmitOrder(redemptionType, reward)
    }
  }

  const ports = content.lead?.split(';')
  const voyageDurationInDays = differenceInDays(new Date(voyageEndDate), new Date(voyageStartDate))
  const voyageStartDateDisplay = format(new Date(voyageStartDate), 'iii, dd MMM')
  const voyageEndDateDisplay = format(addDays(new Date(voyageStartDate), voyageDurationInDays), 'iii, dd LLL yyyy')

  const cabinName = 'Sea Terrace - 1 Cabin'
  const cabinCapacity = '(Up to x2 Sailors)'

  const smallScreenVoyageImages = {
    mobile: {
      imgSrc: optimizeContentfulImageUrl(imageUrl, 'fill', { width: 82, height: 82 }, 60),
      hiResImgSrc: optimizeContentfulImageUrl(imageUrl, 'fill', { width: 82 * 2, height: 82 * 2 }, 60),
    },
  }

  return (
    <>
      <DialogSailorDetails
        visible={isDialogSailorDetailsOpen}
        onClose={() => setIsDialogSailorDetailsOpen(false)}
        onSubmit={setSailorDetails}
        initialValues={sailorDetails || {}}
        isUSUser={true}
      />
      <Page testId="page-reward-checkout" title="Reward Checkout | Virgin Red">
        {reward && (
          <>
            <Header>
              <XStack>
                <H1
                  marginHorizontal="auto"
                  fontWeight={600}
                  marginTop="$6"
                  $tabletLandscape={{ fontSize: '$9', marginTop: '$5' }}
                  $mobile={{ fontSize: '$8', marginTop: '$3' }}>
                  {getString('orders.checkout.web.title')}
                </H1>
              </XStack>
            </Header>

            <Content>
              <View alignItems="center">
                <View $gtTabletPortrait={{ flexDirection: 'row' }}>
                  <View margin={` 0 auto`} maxWidth={535} flex={1}>
                    <YStack marginBottom={'$5'} $tabletPortrait={{ flexDirection: 'column-reverse' }}>
                      <View>
                        <H3
                          marginBottom={'$2'}
                          marginTop={'$3'}
                          textTransform={'uppercase'}
                          fontStyle={'italic'}
                          fontWeight={600}
                          $mobile={{ fontSize: '$7' }}>
                          {getString('orders.checkout.web.orderSummaryVoyages')}
                        </H3>
                        <Paragraph lineHeight={'$7'}>{reward.content?.description}</Paragraph>
                        <View display="block" marginBottom={'$2'} marginTop={'$3'}>
                          <DotList list={ports} />
                        </View>
                        <View $tabletPortrait={{ flexDirection: 'row', alignItems: 'center' }}>
                          <View
                            $gtTabletPortrait={{ display: 'none' }}
                            borderRadius={'$2'}
                            display={'block'}
                            overflow={'hidden'}
                            width={82}
                            height={82}
                            marginRight={'$2'}>
                            <Picture
                              altText={`${title} reward`}
                              width={82}
                              fallbackImg={smallScreenVoyageImages.mobile.imgSrc}
                              height={82}
                              responsiveImages={smallScreenVoyageImages}
                              loading={'lazy'}
                              isWebP={true}
                            />
                          </View>
                          <View>
                            <XStack marginBottom={'$2'} alignItems="center">
                              <Icon.Calendar width={'$3'} color={'$black7'} />
                              <Text marginLeft={'$2'} fontSize={'$6'} fontWeight={'500'}>
                                {`${voyageStartDateDisplay} - ${voyageEndDateDisplay}`}
                              </Text>
                            </XStack>
                            <XStack alignItems="center">
                              <Icon.Bed width={'$3'} color={'$black7'} />
                              <Text marginLeft={'$2'} fontSize={'$6'} fontWeight={'500'}>
                                {cabinName}{' '}
                                <Text fontStyle="italic" fontWeight={'400'}>
                                  {cabinCapacity}
                                </Text>
                              </Text>
                            </XStack>
                          </View>
                        </View>
                      </View>

                      <DashedDivider marginTop={'$5'} />

                      <View>
                        <H3
                          textTransform={'uppercase'}
                          fontStyle={'italic'}
                          fontWeight={600}
                          marginBottom={'$2'}
                          marginTop={'$3'}
                          $mobile={{ fontSize: '$7' }}>
                          {getString('orders.checkout.web.sailorDetails')}
                        </H3>
                        <Paragraph>
                          <ParsedStyledText text={getString('orders.checkout.web.sailorDetailsDescription')} />
                        </Paragraph>
                        <Text fontSize={'$6'} marginBottom={'$3'}>
                          {getString('orders.checkout.web.sailorAddLater')}
                        </Text>

                        {sailorDetails && (
                          <ButtonCard onPress={() => setIsDialogSailorDetailsOpen(true)} style={{ cursor: 'pointer' }}>
                            <ButtonCard.Content>
                              <Text fontSize={'$6'} marginBottom={'$0.75'} fontWeight={'600'}>
                                {sailorDetails.firstName} {sailorDetails.lastName}
                              </Text>
                              <Text fontSize={'$6'} marginBottom={'$0.75'}>
                                {sailorDetails.email}
                              </Text>
                              <Text fontSize={'$6'} marginBottom={'$0.75'}>
                                DOB - {sailorDetails.dateOfBirth?.day}/{sailorDetails.dateOfBirth?.month}/{sailorDetails.dateOfBirth?.year}
                              </Text>
                              <Text fontSize={'$6'} marginBottom={'$0.75'}>
                                {GENDERS.find((g) => g.value === sailorDetails.gender)?.label}
                              </Text>
                              <Text fontSize={'$6'} marginBottom={'$0.75'}>
                                {CITIZENSHIP.find((c) => c.value === sailorDetails.citizenship)?.label}
                              </Text>
                              <Text fontSize={'$6'} marginBottom={'$0.75'}>
                                {sailorDetails.mobileNumber?.countryCode} {sailorDetails.mobileNumber?.number}
                              </Text>
                            </ButtonCard.Content>
                            <ButtonCard.FooterText>{getString('orders.checkout.web.editSailorDetails')}</ButtonCard.FooterText>
                            <ButtonCard.FooterIcon>
                              <Icon.ChevronRight color={'$red6'} height={'$3'} style={{ alignItems: 'center' }} />
                            </ButtonCard.FooterIcon>
                          </ButtonCard>
                        )}

                        {!sailorDetails && (
                          <ButtonCard onPress={() => setIsDialogSailorDetailsOpen(true)} style={{ cursor: 'pointer' }}>
                            <ButtonCard.FooterText>{getString('orders.checkout.web.addSailorDetails')}</ButtonCard.FooterText>
                            <ButtonCard.FooterIcon>
                              <Icon.Plus color={'$red6'} height={'$3'} style={{ alignItems: 'center' }} />
                            </ButtonCard.FooterIcon>
                          </ButtonCard>
                        )}
                      </View>
                    </YStack>

                    <DashedDivider marginTop={'$0.5'} />

                    <H3
                      textTransform={'uppercase'}
                      fontStyle={'italic'}
                      fontWeight={600}
                      marginBottom={'$3'}
                      marginTop={'$3'}
                      $mobile={{ fontSize: '$7' }}>
                      {getString('virginVoyages.checkout.termsAndConditionsTitle')}
                    </H3>

                    <CheckboxWithLabel
                      labelName="over18"
                      marginBottom={'$2'}
                      checked={order.consent.over18 || false}
                      onCheckedChange={handleChangeCheckbox('over18')}>
                      <Text id="over18" marginLeft={'$2'} fontSize={'$6'}>
                        <ParsedStyledText text={getString('virginVoyages.checkout.confirmOverEighteen')} />
                      </Text>
                    </CheckboxWithLabel>

                    <CheckboxWithLabel
                      labelName="confirmTerms"
                      marginBottom={'$2'}
                      checked={order.consent.terms || false}
                      onCheckedChange={handleChangeCheckbox('terms')}>
                      <Text id="confirmTerms" marginLeft={'$2'} fontSize={'$6'}>
                        <a href={termsUrl} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: theme.black10.val }}>
                          <ParsedStyledText text={getString('virginVoyages.checkout.confirmTsAndCs')} />
                        </a>
                      </Text>
                    </CheckboxWithLabel>
                    <CheckboxWithLabel
                      labelName="confirmPrivacyPolicy"
                      checked={order.consent.marketingUpdates || false}
                      onCheckedChange={handleChangeCheckbox('marketingUpdates')}>
                      <Text id="confirmPrivacyPolicy" marginLeft={'$2'} fontSize={'$6'}>
                        <ParsedStyledText text={getString('virginVoyages.checkout.confirmReceiveVirginMarketing')} />
                      </Text>
                    </CheckboxWithLabel>

                    <YStack marginBottom={'$3'} marginTop={'$4'} $mobile={{ flexDirection: 'column-reverse', marginTop: '$6' }}>
                      <Text color={'$red6'} fontSize={'$8'} fontWeight={'600'} fontStyle="italic" $mobile={{ textAlign: 'center' }}>
                        {formatPoints({ points: cost })} points
                      </Text>
                      <View marginBottom={'$2'}>
                        <Text fontSize="$6" color="$color-text-secondary">
                          {getString('virginVoyages.question.checkBeforeBooking')}
                        </Text>
                      </View>
                    </YStack>

                    <View display="block">
                      <Button
                        display="inline-block"
                        isFullWidth={false}
                        isPrimary
                        disabled={!isFormValid}
                        onClick={handleSubmit}
                        text={getString('actions.redeemNow')}
                        loading={isSubmitting}
                        dataAttributes={{
                          testid: 'order-checkout-confirm-button',
                        }}
                      />
                    </View>
                  </View>
                  {imageUrl && brandLogo && title && brandName && !!cost && (
                    <View
                      display={'block'}
                      marginTop={40}
                      marginLeft={50}
                      $mobile={{ display: 'none' }}
                      $tabletPortrait={{ display: 'none' }}
                      $gtTabletPortrait={{ maxWidth: 300 }}
                      $gtDesktop={{ maxWidth: 425 }}>
                      <CheckoutRewardSummary
                        imageUrl={imageUrl}
                        brandLogoUrl={brandLogo}
                        brandName={brandName}
                        title={title}
                        lead={<DotList list={ports} />}
                        cost={cost}
                      />
                    </View>
                  )}
                </View>
                <HelpCTA />
              </View>
            </Content>
            <Spacer size="small" />
          </>
        )}
      </Page>
    </>
  )
}

export { Voyages }
