import { Button, XStack, YStack, H4, H6, Dialog, styled } from '@red-ui/components'

export const StyledCloseButton = styled(Button, {
  position: 'absolute',
  top: '$3',
  right: '$3',
  backgroundColor: 'transparent',
  borderWidth: 0,
  hoverStyle: {
    backgroundColor: 'transparent',
  },
  focusStyle: {
    backgroundColor: 'transparent',
  },
})

export const StyledButtonWrapper = styled(XStack, {
  width: 'calc(100% - 32px)',
  gap: '$2',
  justifyContent: 'center',
  flexDirection: 'column-reverse',
  marginTop: 'auto',
  $gtMobile: {
    flexDirection: 'row',
  },
})

export const StyledButton = styled(Button, {
  size: '$4',
  paddingVertical: '$3',
  width: '100%',
  $gtMobile: {
    width: '182px',
    paddingVertical: '$3',
  },
})

export const StyledDialogContent = styled(Dialog.Content, {
  alignItems: 'center',
  overflow: 'scroll',
  paddingHorizontal: '$0',
  paddingVertical: '$3',
  height: '95%',
  maxHeight: '900px',
  $gtMobile: {
    minWidth: '650px',
    paddingHorizontal: '$2',
  },
})

export const StyledTitleWrapper = styled(YStack, {
  gap: '$2',
  marginBottom: '$3',
  alignItems: 'flex-start',
  $gtMobile: {
    alignItems: 'center',
  },
})

export const StyledTitle = styled(H4, {
  fontWeight: '500',
  fontSize: '$6',
  $gtMobile: {
    fontSize: '$7',
  },
})

export const StyledDescription = styled(H6, {
  lineHeight: 20,
  fontSize: '$3',
  $gtMobile: {
    textAlign: 'center',
    lineHeight: 24,
    fontSize: '$5',
  },
})
