import { Document } from '@contentful/rich-text-types'
import { useQuery } from '@tanstack/react-query'
import { apiGetGuestApplyModal } from '@vrw/data/src/api/content.api'

interface GuestApplyModalContent {
  guestApplyBody: Document
  guestApplyTitle: string
  loginButton: string
  redirectBody: Document
  redirectTitle: string
  skipButton: string
}

export const guestApplyModalQueryKeys = {
  content: () => ['guestApplyModalContent'] as const,
}

export const useGuestApplyModal = () =>
  useQuery({
    queryKey: guestApplyModalQueryKeys.content(),
    queryFn: () => apiGetGuestApplyModal() as Promise<GuestApplyModalContent>,
  })
