import React from 'react'
import { XStack, Text, Icon } from '@red-ui/components'
import { getString } from '@vrw/data'
import { TextButton } from '../TextButton'

export const SeatTypeInfo = () => {
  return (
    <>
      <Text fontWeight="600" fontSize="$4" $gtMobile={{ fontSize: '$7' }} marginBottom="$3">
        Economy Classic
      </Text>
      <XStack marginBottom="$2" alignItems="center">
        <Icon.Armchair />
        <Text fontSize="$3" $gtTabletLandscape={{ fontSize: '$4' }} marginLeft="$2">
          Standard Seat
        </Text>
      </XStack>
      <XStack marginBottom="$2" alignItems="center">
        <Icon.BaggageClaim />
        <Text fontSize="$3" $gtTabletLandscape={{ fontSize: '$4' }} marginLeft="$2">
          1 x 23kg checked bag
        </Text>
      </XStack>
      <XStack marginBottom="$2" alignItems="center">
        <Icon.Backpack />
        <Text fontSize="$3" $gtTabletLandscape={{ fontSize: '$4' }} marginLeft="$2">
          1 x 10kg carry on bag
        </Text>
      </XStack>
      <XStack>
        <TextButton $gtMobile={{ fontSize: '$5' }} $gtTabletPortrait={{ fontSize: '$6' }}>
          {getString('redSkySearch.results.card.seat.info.more')}
        </TextButton>
      </XStack>
    </>
  )
}
