import React, { RefObject, useEffect, useRef } from 'react'
import { AnimatePresence, View, useMedia } from '@red-ui/components'

type Width = `${number}%` | number

export type SideSheetProps = {
  open: boolean
  onClose: () => void
  width?: Width
  children: React.ReactNode
  ariaLabel?: string
  hasOverlay?: boolean
}

const useSideSheetEffects = ({
  open,
  onClose,
  sheetRef,
  disableScroll,
}: {
  open: boolean
  onClose: () => void
  sheetRef: RefObject<HTMLDivElement>
  disableScroll: boolean
}) => {
  const lastFocusedElementRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    const controller = new AbortController()

    document.addEventListener(
      'focusin',
      (event) => {
        const target = event.target as HTMLElement

        if (sheetRef.current?.contains(target)) return

        lastFocusedElementRef.current = target
      },
      { signal: controller.signal }
    )

    return () => {
      controller.abort()
    }
  }, [sheetRef])

  useEffect(() => {
    if (!open && lastFocusedElementRef.current) {
      lastFocusedElementRef.current.focus()
      lastFocusedElementRef.current = null
    }
  }, [open])

  useEffect(() => {
    if (open && disableScroll) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      if (disableScroll) {
        document.body.style.overflow = 'unset'
      }
    }
  }, [disableScroll, open])

  useEffect(() => {
    if (open) {
      const alreadyFocused = sheetRef.current?.contains(document.activeElement)

      if (!alreadyFocused) {
        sheetRef.current?.focus()
      }
    }
  }, [open, sheetRef])

  useEffect(() => {
    const controller = new AbortController()

    const handleEscapeKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    const handleFocusTrap = (event: KeyboardEvent) => {
      if (event.key !== 'Tab') return

      const focusableElements = sheetRef.current?.querySelectorAll<HTMLElement>(
        "button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])"
      )

      if (!focusableElements?.length) return

      const [firstElement, lastElement] = [focusableElements[0], focusableElements[focusableElements.length - 1]]

      if (event.shiftKey && document.activeElement === firstElement) {
        event.preventDefault()
        lastElement.focus()
      } else if (!event.shiftKey && document.activeElement === lastElement) {
        event.preventDefault()
        firstElement.focus()
      }
    }

    if (open) {
      document.addEventListener('keydown', handleEscapeKeyPress, { signal: controller.signal, capture: true })
      document.addEventListener('keydown', handleFocusTrap, { signal: controller.signal })
    }

    return () => {
      controller.abort()
    }
  }, [open, onClose, sheetRef])
}

export const SideSheet = ({ open, onClose, children, width = '30%', ariaLabel, hasOverlay = true }: SideSheetProps) => {
  const sheetRef = useRef<HTMLDivElement | null>(null)
  const media = useMedia()
  const breakpoints: Array<{ condition: boolean; width: Width }> = [
    { condition: media.mobile, width: '100%' },
    { condition: media.tabletPortrait, width: '60%' },
    { condition: media.tabletLandscape, width: '50%' },
  ]
  const sheetWidth = breakpoints.find((breakpoint) => breakpoint.condition)?.width ?? width
  const transformStyle = { transform: [{ translateX: sheetWidth }], opacity: 0 }

  useSideSheetEffects({ open, onClose, sheetRef, disableScroll: hasOverlay })

  return (
    <View ref={sheetRef} tabIndex={-1} role="dialog" aria-modal aria-label={ariaLabel ?? 'Side Sheet'}>
      <AnimatePresence>
        {open && (
          <>
            {hasOverlay && (
              <View
                aria-label="Dismissable Overlay"
                style={{ position: 'fixed' }}
                top="$0"
                left="$0"
                right="$0"
                bottom="$0"
                backgroundColor={'$black10'}
                opacity={0.5}
                onPress={onClose}
                enterStyle={{ opacity: 0 }}
                exitStyle={{ opacity: 0 }}
                animation="quick"
                zIndex={1000}
                aria-hidden
              />
            )}
            <View
              style={{ position: 'fixed' }}
              top="$0"
              right="$0"
              width={sheetWidth}
              height="100vh"
              backgroundColor="$background"
              shadowColor="$black10"
              shadowRadius="$1"
              animation="quick"
              zIndex={1000}
              enterStyle={transformStyle}
              exitStyle={transformStyle}
              animateOnly={['transform', 'opacity']}>
              {children}
            </View>
          </>
        )}
      </AnimatePresence>
    </View>
  )
}
