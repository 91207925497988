import Joi from 'joi'
import { useCallback, useEffect, useState } from 'react'
import { Airport } from '../../api/searchAirports'

export const RECENT_SEARCHES_KEY = 'recentFlightSearches'

const airportSchema = Joi.object({
  code: Joi.string().required(),
  airportName: Joi.string().required(),
  cityName: Joi.string().required(),
  countryName: Joi.string().required(),
  region: Joi.string().required(),
}).required()

const isValidAirport = (airport: any): airport is Airport => {
  const { error } = airportSchema.validate(airport)
  return !error
}

export const useRecentSearches = () => {
  const [recentSearches, setRecentSearches] = useState<Airport[]>(() => {
    try {
      const storedSearches = localStorage.getItem(RECENT_SEARCHES_KEY)
      if (!storedSearches) return []

      const parsed = JSON.parse(storedSearches)
      return Array.isArray(parsed) ? parsed.filter(isValidAirport) : []
    } catch (error) {
      console.error('Failed to parse recent searches from local storage', error)
      return []
    }
  })

  useEffect(() => {
    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(recentSearches))
  }, [recentSearches])

  const saveSearch = useCallback((airport: Airport) => {
    setRecentSearches((prev) => {
      const filtered = prev.filter((item) => item.code !== airport.code)
      return [airport, ...filtered].slice(0, 3)
    })
  }, [])

  return { recentSearches, saveSearch }
}
