import React, { useState } from 'react'
import { Button, Stack, Text, Popover, XStack, YStack, styled, Adapt, View } from '@red-ui/components'
import { ArrowUpDown } from '@tamagui/lucide-icons'
import { getString } from '@vrw/data/src/utils/getString'

// Update sort options to include date sorting
export type SortOption = 'blank' | 'featured' | 'low-to-high' | 'high-to-low' | 'date-closest' | 'date-furthest'

interface SortOptionsProps {
  selectedSort: SortOption
  onSortChange: (sort: SortOption) => void
}

const SortTitle = styled(Text, {
  fontWeight: '600',
  fontSize: '$6',
  fontStyle: 'italic',
  padding: '$4',
  paddingBottom: '$3',
  borderBottomWidth: 1,
  borderBottomColor: '$black5',
  textTransform: 'uppercase',
})

const SortText = styled(Text, {
  fontSize: '$4',
  fontWeight: '600',
})

const SortButton = styled(Button, {
  width: 110,
  variant: 'secondary',
})

const SortItemButton = styled(Button, {
  width: '100%',
  borderWidth: 0,
  borderRadius: 0,
  pressStyle: {
    backgroundColor: '$pink6',
  },
  focusStyle: {
    backgroundColor: '$pink6',
  },
  hoverStyle: {
    backgroundColor: '$pink6',
  },
  backgroundColor: 'transparent',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingVertical: '$3',
  paddingHorizontal: '$4',
  variants: {
    selected: {
      true: {
        backgroundColor: '$pink6',
      },
    },
  },
})

const SortItemText = styled(Text, {
  fontSize: '$4',
  color: '$black10',
  variants: {
    selectedItem: {
      true: {
        fontWeight: '700',
      },
    },
  },
  fontWeight: '400',
})
const OptionIndicator = styled(View, {
  borderRadius: 10,
  height: 8,
  width: 8,
  backgroundColor: '$white',
  variants: {
    selectedItem: {
      true: {
        backgroundColor: '$red6',
      },
    },
  },
})

export const SortOptions = ({ selectedSort, onSortChange }: SortOptionsProps) => {
  const [open, setOpen] = useState(false)

  const handleSortChange = (value: SortOption) => {
    onSortChange(value)
    // Close the popover after a short delay to allow the animation to complete
    setTimeout(() => {
      setOpen(false)
    }, 150)
  }

  const renderSortOptions = () => (
    <YStack width="100%">
      <SortTitle>{getString('brands.sort.title')}</SortTitle>
      <YStack>
        {/* Blank option due to 1st item selection bug in Tamagui popover */}
        <SortItem value="blank" label="" onSelect={() => {}} hideOption={true} isSelected={false} />
        <SortItem
          value="featured"
          label={getString('brands.sort.featured')}
          isSelected={selectedSort === 'featured'}
          onSelect={handleSortChange}
        />
        <SortItem
          value="low-to-high"
          label={getString('brands.sort.pointsLowest')}
          isSelected={selectedSort === 'low-to-high'}
          onSelect={handleSortChange}
        />
        <SortItem
          value="high-to-low"
          label={getString('brands.sort.pointsHighest')}
          isSelected={selectedSort === 'high-to-low'}
          onSelect={handleSortChange}
        />
        <SortItem
          value="date-closest"
          label={getString('brands.sort.dateClosest')}
          isSelected={selectedSort === 'date-closest'}
          onSelect={handleSortChange}
        />
        <SortItem
          value="date-furthest"
          label={getString('brands.sort.dateFurthest')}
          isSelected={selectedSort === 'date-furthest'}
          onSelect={handleSortChange}
        />
      </YStack>
    </YStack>
  )

  return (
    <Stack>
      <Popover open={open} onOpenChange={setOpen} placement="bottom-end" allowFlip stayInFrame offset={5}>
        <Popover.Trigger asChild>
          <SortButton onPress={() => setOpen(true)}>
            <SortText>{getString('brands.sort.button')}</SortText>
            <ArrowUpDown size={'$2'} />
          </SortButton>
        </Popover.Trigger>

        <Popover.Content
          width={300}
          shadowRadius={10}
          shadowColor="$black9"
          shadowOffset={{ width: 0, height: 0 }}
          shadowOpacity={0.5}
          paddingVertical={'$0'}
          paddingHorizontal={'$0'}
          backgroundColor="$backgroundStrong"
          elevate={true}
          enterStyle={{ y: -10, opacity: 0 }}
          exitStyle={{ y: -10, opacity: 0 }}
          animation={[
            'quick',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}>
          <Popover.Arrow />
          {renderSortOptions()}
        </Popover.Content>
        <Adapt when="mobile" platform="touch">
          <Popover.Sheet modal dismissOnSnapToBottom>
            <Popover.Sheet.Overlay backgroundColor="$shadowColor" animation="lazy" enterStyle={{ opacity: 0 }} exitStyle={{ opacity: 0 }} />
            <Popover.Sheet.Frame padding="$4">
              <Popover.Sheet.ScrollView>{renderSortOptions()}</Popover.Sheet.ScrollView>
            </Popover.Sheet.Frame>
          </Popover.Sheet>
        </Adapt>
      </Popover>
    </Stack>
  )
}

const SortItem = ({
  value,
  label,
  isSelected,
  onSelect,
  hideOption,
}: {
  value: SortOption
  label: string
  isSelected: boolean
  onSelect: (value: SortOption) => void
  hideOption?: boolean
}) => {
  return (
    <SortItemButton
      selected={isSelected}
      onPress={() => onSelect(value)}
      paddingVertical={!hideOption ? '$3' : '$0'}
      paddingHorizontal={!hideOption ? '$4' : '$0'}
      height={!hideOption ? 'auto' : 0}>
      {!hideOption && (
        <>
          <SortItemText selectedItem={isSelected}>{label}</SortItemText>
          <XStack
            width={16}
            height={16}
            borderRadius={8}
            borderWidth={1}
            borderColor="$red6"
            backgroundColor={isSelected ? '$pink6' : 'transparent'}
            alignItems="center"
            justifyContent="center">
            {isSelected && !hideOption && <OptionIndicator selectedItem={isSelected} />}
          </XStack>
        </>
      )}
    </SortItemButton>
  )
}
