import Joi from 'joi'

export const sailorDetailsSchema = Joi.object({
  firstName: Joi.string().required().messages({
    'string.empty': 'First name is required',
  }),
  lastName: Joi.string().required().messages({
    'string.empty': 'Last name is required',
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.empty': 'Email is required',
      'string.email': 'Please enter a valid email address',
    }),
  dateOfBirth: Joi.object({
    day: Joi.string().required(),
    month: Joi.string().required(),
    year: Joi.string()
      .required()
      .custom((value, helpers) => {
        const day = parseInt(helpers.state.ancestors[0].day)
        const month = parseInt(helpers.state.ancestors[0].month) - 1
        const year = parseInt(value)

        if (day < 1 || day > 31 || month < 0 || month > 11 || year < 1900) {
          return helpers.error('date.invalid')
        }

        const birthDate = new Date(year, month, day)
        const today = new Date()

        if (birthDate.getDate() !== day || birthDate.getMonth() !== month) {
          return helpers.error('date.invalid')
        }

        let age = today.getFullYear() - birthDate.getFullYear()
        const monthDiff = today.getMonth() - birthDate.getMonth()
        const dayDiff = today.getDate() - birthDate.getDate()

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
          age--
        }

        if (age < 18) {
          return helpers.error('date.underage')
        }
        return value
      }),
  }).messages({
    'date.invalid': 'Please enter a valid date',
    'date.underage': 'You must be at least 18 years old',
  }),
  gender: Joi.string().required().messages({
    'string.empty': 'Gender is required',
  }),
  citizenship: Joi.string().required().messages({
    'string.empty': 'Citizenship is required',
  }),
  mobileNumber: Joi.object({
    countryCode: Joi.string().required(),
    number: Joi.string().required().messages({
      'string.empty': 'Phone number is required',
    }),
  }),
})

export const validateField = async (schema: typeof sailorDetailsSchema, field: string, value: any): Promise<string | undefined> => {
  try {
    const fieldSchema = schema.extract(field)
    await fieldSchema.validateAsync(value)
    return undefined
  } catch (error: any) {
    console.log('error', error)
    return error.message
  }
}

export const validateForm = async (values: any): Promise<Record<string, string>> => {
  try {
    console.log('values', values)
    await sailorDetailsSchema.validateAsync(values, { abortEarly: false })
    return {}
  } catch (error: any) {
    if (!error.details) return {}

    return error.details.reduce((acc: Record<string, string>, detail: any) => {
      acc[detail.path[0]] = detail.message
      return acc
    }, {})
  }
}
