import { connect } from 'react-redux'

import { DispatchFunction } from '@vrw/data/src/redux/types'
import { getRewardById } from '@vrw/data/src/redux/rewards/selectors'
import { getDeliveryAddress, getOrderInProgress } from '@vrw/data/src/redux/orders/selectors'
import { doUpdateOrderDeliveryAddress } from '@vrw/data/src/redux/orders/dispatchers'
import { validateOrderDeliveryAddress } from '@vrw/data/src/redux/orders/validations'

import { DialogAddress } from './DialogAddress'
import { RootState } from '@/redux/types'
import { bindTypedActionCreators } from '@/helpers/bindTypedActionCreators'

const mapStateToProps = (state: RootState) => {
  const reward = getRewardById(state, getOrderInProgress(state).rewardId)
  return {
    reward,
    initialAddress: getDeliveryAddress(state),
    validateOrderDeliveryAddress,
  }
}

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doUpdateOrderDeliveryAddress,
    },
    dispatch
  )

const DialogAddressContainer = connect(mapStateToProps, mapDispatchToProps)(DialogAddress)

export { DialogAddressContainer }
