import React from 'react'
import { Accordion, AnimatePresence, Icon, Square, Text, XStack } from '@red-ui/components'
import { getString } from '@vrw/data'

export type Props = {
  field: string
  children: React.ReactNode
  isActive?: boolean
  canBeTriggered?: boolean
  onPress?: () => void
}

export const Question = ({ field, children, isActive = false, canBeTriggered = false, onPress }: Props) => {
  const title = getString(`redSkySearch.question.${field}.title`)

  const renderTriggerContent = ({ open }: { open: boolean }) => (
    <XStack cursor={canBeTriggered ? 'pointer' : 'unset'} flex={1} alignItems="center" justifyContent="space-between">
      <Text fontSize="$6" $mobile={{ fontSize: '$4' }} $tabletPortrait={{ fontSize: '$5' }}>
        <strong>{title}</strong>
      </Text>
      <Square animation="200ms" rotate={open ? '180deg' : '0deg'}>
        <Icon.ChevronDown />
      </Square>
    </XStack>
  )

  return (
    <Accordion.Item
      value={field}
      borderWidth={1}
      borderColor={isActive ? '$black10' : '$black2'}
      borderRadius="$5"
      padding="$2"
      backgroundColor="$white">
      <Accordion.Header>
        <Accordion.Trigger
          backgroundColor="$white"
          unstyled
          width="100%"
          aria-label={getString('redSkySearch.question.toggle.ariaLabel').replace('{{question}}', title)}
          alignItems="center"
          hoverStyle={{ backgroundColor: '$white' }}
          focusStyle={{ backgroundColor: '$white' }}
          onPress={onPress}
          disabled={!canBeTriggered}>
          {renderTriggerContent}
        </Accordion.Trigger>
      </Accordion.Header>
      <AnimatePresence>
        <Accordion.Content unstyled animation="200ms" enterStyle={{ opacity: 0 }} exitStyle={{ opacity: 0 }} backgroundColor="$white">
          {children}
        </Accordion.Content>
      </AnimatePresence>
    </Accordion.Item>
  )
}
