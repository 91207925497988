import { CarouselCard, Page } from '@/components'
import IconMapper, { Icon } from '@/components/icons/IconMapper'
import { PUBLIC_URL } from '@/config'
import { msalInstance } from '@/dataImplementation/auth'
import { useAppSelector } from '@/redux/hooks/hooks'
import { color } from '@/style/variables'
import { AuthError, EventType, AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser'
import { Button, Card, config, H1, H2, H3, H5, Image, Paragraph, Spinner, styled, View, XStack, YStack } from '@red-ui/components'
import { getString } from '@vrw/data'
import { getBalance } from '@vrw/data/src/redux/balance/balance.selectors'
import { getProfileData } from '@vrw/data/src/redux/profile/selectors'
import { formatPoints } from '@vrw/data/src/utils/formatters'
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import AliceCarousel from 'react-alice-carousel'
import { useLocation } from 'react-router-dom'
import { SearchSideSheets } from '../components/SearchSideSheets'
import { getTrackingId } from '../helpers/getTrackingId'
import { useRedNavigate } from '@/dataImplementation/useRedNavigate'
import { PATHS, virginRedPath } from '@/router/paths'

export const SEARCH_SHEET_OPEN_HASH = '#edit'

export function LandingPage() {
  const profile = useAppSelector(getProfileData)
  const points = useAppSelector(getBalance)
  const navigate = useRedNavigate()
  const [cardHeight, setCardHeight] = useState(0)
  const { hash } = useLocation()
  const { login, data, isLoading } = useVaaAuth()

  const isMainSheetOpen = hash === SEARCH_SHEET_OPEN_HASH && !!data
  const carouselItems = Array.from({ length: 3 }, (_, i) => i + 1)

  const handleSearchSideSheet = (isOpen: boolean) => () => {
    if (data) {
      navigate(`${PATHS.REDSKY_SEARCH}${isOpen ? SEARCH_SHEET_OPEN_HASH : ''}`)
    } else {
      login()
    }
  }

  const StyledCarouselButton = styled(Button, {
    borderRadius: 50,
    width: '$6',
    height: '$6',
    display: 'none',
    zIndex: 1,
    position: 'absolute',
    $gtTabletLandscape: {
      display: 'flex',
      top: cardHeight / 2 - 70,
    },
    disabledStyle: {
      display: 'none',
    },
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    cursor: 'pointer',
  })

  const StyledCarouselDots = styled(Button, {
    variants: {
      isActive: {
        true: {
          backgroundColor: '$red6',
          hoverStyle: {
            backgroundColor: '$red6',
          },
          pressStyle: {
            backgroundColor: '$red6',
          },
        },
      },
    },
    backgroundColor: '$white',
    borderRadius: '$6',
    height: '$3',
    width: '$3',
    borderColor: '$red6',
    marginHorizontal: '$2',
    hoverStyle: {
      backgroundColor: '$white',
    },
    pressStyle: {
      backgroundColor: '$white',
    },
  })

  return (
    <Page title={getString('redSkySearch.title')}>
      <SearchSideSheets mainSheetOpen={isMainSheetOpen} onCloseMainSheet={handleSearchSideSheet(false)} />
      <XStack
        backgroundColor="$pink4"
        justifyContent="center"
        paddingHorizontal="$3"
        $mobile={{ paddingHorizontal: '$2' }}
        paddingVertical="$5">
        <YStack flex={1} gap="$5" maxWidth={config.media.desktop.maxWidth}>
          <YStack alignItems="center" gap="$2">
            <H1>{getString('redSkySearch.landing.title')}</H1>
            <Paragraph>{getString('redSkySearch.landing.description')}</Paragraph>
          </YStack>
          <Card overflow="hidden">
            <Card.Header padding="$0" height={300} $gtMobile={{ height: 500 }}>
              <Image
                aria-label={getString('redSkySearch.landing.main.image.alt')}
                source={{ uri: `${PUBLIC_URL}/img/red-sky/landing-image.png` }}
                flex={1}
              />
            </Card.Header>
            <Card.Footer
              $gtMobile={{
                zIndex: '$1',
                left: '$7',
                top: '$7',
                position: 'absolute',
                minWidth: 350,
                width: '45%',
                borderRadius: '$3',
              }}
              backgroundColor="$red6"
              flexDirection="column"
              padded>
              <H3 marginBottom="$4" color="$white" tag="strong">
                {getString('redSkySearch.landing.book.welcome').replace('{{name}}', profile?.firstName || '')}
              </H3>
              <YStack gap="$2" marginBottom="$10">
                <H5 color="$white">
                  {getString('redSkySearch.landing.book.points').replace('{{points}}', formatPoints({ points: points.usable || 0 }))}
                </H5>
                <Paragraph color="$white">{getString('redSkySearch.landing.book.description')}</Paragraph>
              </YStack>
              <Button
                disabled={isLoading}
                iconAfter={isLoading ? <Spinner color="$red6" /> : null}
                id={getTrackingId(getString('redSkySearch.landing.book.cta'))}
                variant="secondary"
                padding="$3"
                onPress={handleSearchSideSheet(true)}>
                {getString('redSkySearch.landing.book.cta')}
              </Button>
            </Card.Footer>
          </Card>
        </YStack>
      </XStack>
      <YStack $gtTabletLandscape={{ marginVertical: '$4' }} flex={1}>
        <H2
          textAlign="center"
          marginTop="$5"
          marginBottom="$1"
          fontWeight="600"
          $mobile={{ fontSize: '$6' }}
          $tabletPortrait={{ fontSize: '$8' }}
          $tabletLandscape={{ fontSize: '$8' }}>
          {getString('redSkySearch.onboardingCarousel.heading')}
        </H2>
        <View onLayout={(e) => setCardHeight(e.nativeEvent.layout.height)}>
          <AliceCarousel
            items={carouselItems.map((index) => {
              return (
                <CarouselCard key={index}>
                  <YStack flex={1} height="100%">
                    <Image
                      width="100%"
                      height={300}
                      $gtMobile={{ display: 'none' }}
                      display="flex"
                      aria-label={getString(`redSkySearch.onboardingCarousel.${index}.image.alt`)}
                      marginBottom="$2"
                      source={{ uri: `${PUBLIC_URL}/img/red-sky/onboarding-carousel-${index}.png` }}
                    />
                    <XStack flex={1}>
                      <YStack
                        justifyContent="center"
                        paddingHorizontal="$4"
                        height="100%"
                        $gtMobile={{ width: '50%', paddingTop: '$3' }}
                        width="100%">
                        <H2
                          fontWeight="600"
                          paddingBottom="$3"
                          $gtTabletLandscape={{ width: '75%' }}
                          $tabletPortrait={{ fontSize: '$8' }}
                          $tabletLandscape={{ fontSize: '$8' }}
                          $mobile={{ fontSize: '$6', lineHeight: '$6', textAlign: 'center' }}>
                          {getString(`redSkySearch.onboardingCarousel.${index}.heading`)}
                        </H2>
                        <Paragraph
                          paddingBottom="$4"
                          $gtTabletLandscape={{ width: '80%' }}
                          $tabletPortrait={{ fontSize: '$5' }}
                          $tabletLandscape={{ fontSize: '$5' }}
                          $mobile={{ fontSize: '$4', textAlign: 'center' }}>
                          {getString(`redSkySearch.onboardingCarousel.${index}.body`)}
                        </Paragraph>
                      </YStack>
                      <Image
                        width="50%"
                        $gtMobile={{ display: 'flex' }}
                        aria-label={getString(`redSkySearch.onboardingCarousel.${index}.image.alt`)}
                        source={{ uri: `${PUBLIC_URL}/img/red-sky/onboarding-carousel-${index}.png` }}
                      />
                    </XStack>
                  </YStack>
                </CarouselCard>
              )
            })}
            renderPrevButton={({ isDisabled }) => (
              <StyledCarouselButton
                left={28}
                disabled={isDisabled}
                icon={<IconMapper icon={Icon.ArrowLeft} color={color.white} size={25} />}
                aria-label={getString('actions.back')}
              />
            )}
            renderNextButton={({ isDisabled }) => (
              <StyledCarouselButton
                right={28}
                disabled={isDisabled}
                icon={<IconMapper icon={Icon.ArrowRight} color={color.white} size={25} />}
                aria-label={getString('actions.next')}
              />
            )}
            renderDotsItem={({ isActive }) => (
              <StyledCarouselDots isActive={isActive} aria-label={getString('redSkySearch.onboardingCarousel.dot')} />
            )}
          />
        </View>
      </YStack>
    </Page>
  )
}

type State = {
  data: AuthenticationResult | null
  isLoading: boolean
  error: AuthError | null
}

type Action =
  | {
      type: 'success'
      payload: AuthenticationResult | null
    }
  | {
      type: 'error'
      payload: AuthError
    }
  | {
      type: 'request'
    }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'success':
      return { ...state, data: action.payload, isLoading: false, error: null }
    case 'error':
      return { ...state, data: null, isLoading: false, error: action.payload }
    case 'request':
      return { ...state, isLoading: true, error: null }
    default:
      return state
  }
}

export const VAA_AUTH_SCOPES = ['openid']

function useVaaAuth() {
  const { hash } = useLocation()
  const initializedRef = useRef(false)
  const [state, dispatch] = useReducer(reducer, {
    data: null,
    // if there is a hash, then we know they are in the process of logging in
    isLoading: !!hash,
    error: null,
  })

  useEffect(() => {
    const callbackId = msalInstance.addEventCallback((message) => {
      switch (message.eventType) {
        case EventType.LOGIN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_SUCCESS: {
          dispatch({ type: 'success', payload: message.payload as AuthenticationResult })
          break
        }
        case EventType.LOGIN_FAILURE:
        case EventType.ACQUIRE_TOKEN_FAILURE:
          dispatch({ type: 'error', payload: message.error as AuthError })
          break
      }
    })

    return () => {
      if (callbackId) msalInstance.removeEventCallback(callbackId)
    }
  }, [])

  useEffect(() => {
    // should only run once - in development it runs multiple times
    if (!initializedRef.current) {
      initializedRef.current = true
      // responses/errors of msal instance function calls will be handled by the event callback
      msalInstance.handleRedirectPromise().catch()
    }
  }, [])

  useEffect(() => {
    const accounts = msalInstance.getAllAccounts()
    if (!accounts.length) return

    dispatch({ type: 'request' })

    const request = { account: accounts[0], scopes: VAA_AUTH_SCOPES }

    msalInstance.acquireTokenSilent(request).catch((err) => {
      if (err instanceof InteractionRequiredAuthError) {
        return msalInstance.acquireTokenRedirect(request)
      }
      return err
    })
  }, [])

  const login = useCallback(() => {
    dispatch({ type: 'request' })
    msalInstance.loginRedirect({ scopes: VAA_AUTH_SCOPES, prompt: 'login', redirectUri: `${virginRedPath}${PATHS.REDSKY_SEARCH}` })
  }, [])

  return { login, ...state }
}
