import { getAuthTokens } from '../redux/auth/getAuthTokens'
import { getRedDataConfig } from '../config'
import { logger } from './logger'

type CallWithRetry = (fn: () => unknown, depth?: number) => Promise<any>

const wait = (ms: number) => new Promise((res) => setTimeout(res, ms))

const MAX_ATTEMPT_COUNT = 4

export const callWithRetry: CallWithRetry = async (fn, depth = 1) => {
  try {
    return await fn()
  } catch (e) {
    if (depth >= MAX_ATTEMPT_COUNT) {
      throw e
    }
    await wait(2 ** depth * 1000)

    return callWithRetry(fn, depth + 1)
  }
}

export const withContentfulFetchRetry = (url: string): Promise<Response> =>
  // eslint-disable-next-line consistent-return
  new Promise(async (resolve, reject) => {
    let retries = 0
    let secondsUntilRetry
    let rejectedErr
    const maxRetries = 3
    while (retries < maxRetries) {
      try {
        if (secondsUntilRetry) {
          return setTimeout(
            async () => {
              const request = await fetch(url, { method: 'GET' })
              resolve(request)
            },
            Number(secondsUntilRetry) * 1000
          )
        }
        resolve(await fetch(url, { method: 'GET' }))

        secondsUntilRetry = undefined
        break
      } catch (err) {
        const error = err as { response?: { status: number; headers: { 'X-Contentful-RateLimit-Reset': number } } }
        const status = error.response?.status ?? 500
        if (status === 429) {
          secondsUntilRetry = error.response?.headers['X-Contentful-RateLimit-Reset']
        } else {
          secondsUntilRetry = undefined
        }
        rejectedErr = err
        retries++
      }
    }

    reject(rejectedErr)
  })

export const httpErrors = {
  BAD_REQUEST: 'bad_request',
  CONNECTION_ERROR: 'connection_error',
  UNAUTHORIZED: 'unauthorized',
  FORBIDDEN: 'forbidden',
  NOT_FOUND: 'not_found',
  UNPROCESSABLE_ENTITY: 'unprocessable_entity',
  SERVER_ERROR: 'server_error',
  BAD_GATEWAY: 'bad_gateway',
  NOT_IMPLEMENTED: 'not_implemented',
  UNKNOWN: 'unknown',
  NOT_ACCEPTABLE: 'not_acceptable',
}

export const httpErrorMessages = {
  CLIENT_ERROR: 'CLIENT_ERROR',
}

export class ApiErrorResponse extends Error {
  constructor(
    message: string,
    public code?: string
  ) {
    super(message)
  }
}

export enum TrainsRetryStatus {
  RETRY_PAYMENT = 'RETRY_PAYMENT',
  EXIT_BOOKING = 'EXIT_BOOKING',
}

export class TrainsError extends Error {
  constructor(
    public debugMessage: string,
    public userMessage?: string,
    public retryStatus?: TrainsRetryStatus
  ) {
    super(debugMessage)
  }
}

/**
 * Shared wrapper for 'fetch' function which handles (optionally) getting the access token and mapping connection errors
 */
export const fetchWithToken = async (url: RequestInfo, options: RequestInit, includeAuthTokens: boolean, doStepUp: boolean) => {
  // Request function reused for first and second attempt with old and new tokens
  const request = async (accessToken?: string, idToken?: string) => {
    const headers: Record<string, string> = { ...(options.headers as Record<string, string>) }
    if (accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`
    }
    if (idToken) {
      headers['x-red-id-token'] = idToken
    }

    try {
      return await fetch(url, {
        ...options,
        headers,
      })
    } catch {
      throw new Error(`${httpErrors.CONNECTION_ERROR}: ${url}`)
    }
  }
  if (doStepUp) {
    const stepUpAccessToken = await getRedDataConfig().secrets.retrieve('stepUpAccessToken')
    return request(stepUpAccessToken)
  }
  if (!includeAuthTokens) {
    // Case for public/non-Red endpoints, provide neither access nor ID token
    return request()
  }
  // First try the existing token
  const existingTokenPair = await getAuthTokens(false)
  const response = await request(existingTokenPair?.accessToken, existingTokenPair?.idToken)
  if (response.status === 403) {
    // If original token does not work, try refreshing it
    const newTokenPair = await getAuthTokens(true)
    return request(newTokenPair?.accessToken, newTokenPair?.idToken)
  }
  return response
}

export const apiErrors = {
  VUA: 'voucher_unavailable',
  NOT_ACCEPTABLE: 'not_acceptable',
} as { [code: string]: string }

const checkResponseCodes = async (response: Response, responseBody?: any | ApiErrorResponse) => {
  switch (response.status) {
    case 200:
    case 201:
    case 202:
    case 204:
      return responseBody
    case 400:
      if (response.url.includes('trains.red.virgin.com')) {
        throw new TrainsError(responseBody.debugMessage, responseBody.userMessage, responseBody.retryStatus)
      }
      throw new ApiErrorResponse(responseBody?.message, responseBody?.code)
    case 401:
    case 403:
      throw new ApiErrorResponse(httpErrors.UNAUTHORIZED) // Handled in errorHandler.ts
    case 404:
      const responseBodyResponse = await responseBody
      throw new ApiErrorResponse(
        `${httpErrors.NOT_FOUND}: ${response.url}.${responseBodyResponse.message ? ` Message: ${responseBodyResponse.message}` : ''}`,
        responseBody?.code
      )
    case 406:
      throw new ApiErrorResponse(httpErrors.NOT_ACCEPTABLE, responseBody?.code)
    case 422:
      throw new ApiErrorResponse(httpErrors.UNPROCESSABLE_ENTITY, responseBody?.code)
    case 500:
      throw new ApiErrorResponse(httpErrors.SERVER_ERROR, responseBody?.code || responseBody?.error)
    case 501:
      throw new ApiErrorResponse(httpErrors.NOT_IMPLEMENTED, responseBody?.code)
    case 502:
      throw new ApiErrorResponse(httpErrors.BAD_GATEWAY, responseBody?.code)
    default:
      throw new ApiErrorResponse(httpErrors.UNKNOWN, responseBody?.code)
  }
}

export const getResponseBody = async (response: Response): Promise<any | null> => {
  try {
    return await response.json()
  } catch {
    return null
  }
}

export interface RequestOptions {
  enableCompression?: boolean
  extraHeaders?: Record<string, string>
}

export const get = async (url: string, includeAuthTokens = false, doStepUp = false, requestOptions?: RequestOptions) => {
  logger.log(`get() ${url} ${includeAuthTokens}`)
  const headers: HeadersInit = {
    'Content-Type': 'application/json;charset=UTF-8',
  }
  if (requestOptions?.enableCompression) {
    headers['Accept-Encoding'] = 'gzip'
  }
  const extraHeaders = requestOptions?.extraHeaders
  if (extraHeaders) {
    Object.keys(extraHeaders).forEach((key) => {
      headers[key] = extraHeaders[key]
    })
  }
  const options = { headers, method: 'GET' }

  const response = await fetchWithToken(url, options, includeAuthTokens, doStepUp)
  return checkResponseCodes(response, getResponseBody(response))
}

export const post = async (
  url: string,
  includeAuthTokens = false,
  doStepUp = false,
  body?: unknown,
  partnerIdToken?: string,
  requestOptions?: RequestOptions
) => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json;charset=UTF-8',
  }
  if (partnerIdToken) {
    headers['x-partner-id-token'] = partnerIdToken
  }
  if (requestOptions?.enableCompression) {
    headers['Accept-Encoding'] = 'gzip'
  }
  const extraHeaders = requestOptions?.extraHeaders
  if (extraHeaders) {
    Object.keys(extraHeaders).forEach((key) => {
      headers[key] = extraHeaders[key]
    })
  }
  const options: any = {
    headers,
    method: 'POST',
  }
  if (body) {
    options.body = JSON.stringify(body)
  }

  const response = await fetchWithToken(url, options, includeAuthTokens, doStepUp)
  return checkResponseCodes(response, await getResponseBody(response))
}

export const put = async (
  url: string,
  includeAuthTokens = false,
  doStepUp = false,
  body?: unknown,
  partnerIdToken?: string,
  enableCompression = false
) => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json;charset=UTF-8',
  }
  if (partnerIdToken) {
    headers['x-partner-id-token'] = partnerIdToken
  }
  if (enableCompression) {
    headers['Accept-Encoding'] = 'gzip'
  }
  const options: any = {
    headers,
    method: 'PUT',
  }
  if (body) {
    options.body = JSON.stringify(body)
  }

  const response = await fetchWithToken(url, options, includeAuthTokens, doStepUp)
  return checkResponseCodes(response, await getResponseBody(response))
}

export const simpleGet = async (url: string) => {
  const response = await fetch(url, {
    method: 'GET',
  })

  return checkResponseCodes(response, await getResponseBody(response))
}

export const patch = async (url: string, includeAuthTokens = false, doStepUp = false, body?: unknown) => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json;charset=UTF-8',
  }
  const options: any = {
    headers,
    method: 'PATCH',
  }
  if (body) {
    options.body = JSON.stringify(body)
  }

  const response = await fetchWithToken(url, options, includeAuthTokens, doStepUp)

  return checkResponseCodes(response, await getResponseBody(response))
}

export const del = async (url: string, includeAuthTokens = false, doStepUp = false) => {
  const headers: HeadersInit = {}
  const options = { headers, method: 'DELETE' }

  const response = await fetchWithToken(url, options, includeAuthTokens, doStepUp)

  return checkResponseCodes(response, await getResponseBody(response))
}
