import React from 'react'
import { Button, Icon, Text, XStack } from '@red-ui/components'

type Props = {
  onPress: () => void
  label: string
}

export const ListItem = ({ onPress, label }: Props) => {
  return (
    <Button
      height="$5"
      backgroundColor="transparent"
      borderColor="$black3"
      onPress={onPress}
      hoverStyle={{ backgroundColor: '$black1', borderColor: '$black3' }}
      pressStyle={{ backgroundColor: '$black2', borderColor: '$black3' }}>
      <XStack justifyContent="space-between" alignItems="center" flex={1}>
        <Text color="$black8" fontSize="$3" $gtTabletPortrait={{ fontSize: '$4' }} marginLeft="$1">
          {label}
        </Text>
        <Icon.ChevronRight color="$black10" />
      </XStack>
    </Button>
  )
}
