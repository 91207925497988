import { getCookiePreferences, CookiePreferenceId } from '../cookiePreferences'

export const updateDynamicYieldCookieConsent = (): void => {
  const cookiePreferences = getCookiePreferences()

  // Only enable Dynamic Yield if marketing/advertising cookies are accepted
  if (cookiePreferences?.[CookiePreferenceId.MarketingAdvertising]) {
    window.DY = window.DY || {}
    window.DY.userActiveConsent = { accepted: true }
  } else {
    window.DY = window.DY || {}
    window.DY.userActiveConsent = { accepted: false }
  }
}
