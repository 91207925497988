import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { apiLinkAccount } from '@vrw/data/src/api/accounts.api'
import { getRedDataConfig } from '@vrw/data/src/config'
import { setToastMessage } from '@vrw/data/src/redux/toast/actions'
import { getString, LinkingJourneyOrigins, logger } from '@vrw/data/src/utils'
import { useAppDispatch, useAppSelector } from '@/redux/hooks/hooks'
import { useAccounts } from '@/query/accounts'
import { regionalizePath } from '@/dataImplementation/viewingRegionWeb'
import { getCountry } from '@vrw/data/src/redux/profile/selectors'

export const useLinkVAA = () => {
  const { pathname, hash } = useLocation()
  const isVAAState = hash.includes('state')
  const { data: accounts, refetch: refetchAccounts } = useAccounts()
  const isVAALinked = accounts?.some((account) => account.partnerCode === 'VAA')
  const { vaa: vaaConfig } = getRedDataConfig()
  const hasRun = useRef(false)
  const [linkStatus, setLinkStatus] = useState<'idle' | 'loading' | 'success' | 'failed'>('idle')
  const dispatch = useAppDispatch()
  const userRegion = useAppSelector(getCountry)

  const getPathMatch = useCallback(() => {
    const localPath = pathname.replace('/en-us', '').replace('/virgin-red', '') ?? ''
    const currentPathWithHash = `${pathname}${hash ?? ''}`
    const regionalizedPathWithHash = `${regionalizePath(localPath, userRegion)}${hash ?? ''}`

    return currentPathWithHash === regionalizedPathWithHash
  }, [pathname, hash, userRegion])

  const linkVAAResponseHandler = useCallback(async () => {
    setLinkStatus('loading')
    try {
      if (!vaaConfig?.getVaaToken) {
        throw new Error('VAA service is not available')
      }

      const response = await vaaConfig.getVaaToken()
      logger.log('query/accounts:linkVAAResponseHandler() VAA response: ', response)

      if (response.idToken) {
        await apiLinkAccount('VAA', response.idToken) // upstream BE op is synchronous - linking should be instant
        refetchAccounts() // refetch accounts to update the UI
        setLinkStatus('success')
        return
      }

      throw new Error('query/accounts:linkVAAResponseHandler() VAA token not present.')
    } catch (error) {
      logger.error(error)
      dispatch(setToastMessage(getString('api.unknownError')))
      setLinkStatus('failed')
    }
  }, [refetchAccounts, vaaConfig, dispatch])

  useEffect(() => {
    const handleVaaLinking = async () => {
      const pathMatch = getPathMatch()
      const canRun = !hasRun.current && pathMatch && isVAAState && !isVAALinked

      if (canRun) {
        hasRun.current = true
        await linkVAAResponseHandler()
      }
    }

    handleVaaLinking()
  }, [isVAALinked, isVAAState, linkVAAResponseHandler, getPathMatch])

  const linkVAA = useCallback(
    async (journey: LinkingJourneyOrigins) => {
      setLinkStatus('loading')
      try {
        await vaaConfig.handleVaaAuth(journey)
      } catch (error) {
        logger.error(error)
        dispatch(setToastMessage(getString('api.unknownError')))
        setLinkStatus('failed')
      }
    },
    [vaaConfig, dispatch]
  )

  return { linkVAA, linkStatus, isVAALinked }
}
