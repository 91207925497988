import React, { NamedExoticComponent } from 'react'
import { useAppSelector } from '@/redux/hooks/hooks'
import { getViewingRegion, localizePath, regionalizePath } from '@/dataImplementation/viewingRegionWeb'
import { getIsAuthenticated } from '@vrw/data/src/redux/auth/selectors'
import { getRegionDisplayName, getString, ViewingRegion } from '@vrw/data/src/utils'
import { FooterBlock, TextLinkKeys, IconLinkKeys } from './types'
import { useContentBySlug } from '@/query/contentful'
import { Anchor, Button, Icon, styled, Text, XStack, YStack } from '@red-ui/components'
import { Link } from '@/components/Link'
import { PATHS } from '@/router/paths'
import { useRegionSelector } from '@/context/RegionSelectorProvider'
import { Trans } from 'react-i18next'

const Container = styled(YStack, {
  flex: 1,
  width: '100%',
  alignItems: 'center',
  alignSelf: 'stretch',
  paddingTop: '$5',
  paddingBottom: '$7',
  paddingHorizontal: '$2',
  $gtMobile: {
    paddingHorizontal: '$4',
  },
  $gtTabletLandscape: {
    padding: '$7',
  },
})

const InnerWrapper = styled(YStack, {
  flex: 1,
  width: '100%',
  maxWidth: 1320,
  $gtMobile: {
    gap: '$1',
  },
})

const FooterRow = styled(XStack, {
  marginTop: '$2',
})

const StyledLink = styled(Link, {
  marginBottom: '$3',
  fontSize: '$4',
  lineHeight: '$4',
  fontWeight: 500,
  flexGrow: 0,
  flexBasis: '100%',
  textAlign: 'center',
  $gtMobile: {
    flexBasis: '50%',
    textAlign: 'left',
    marginBottom: '$2',
  },
  $gtTabletLandscape: {
    flexBasis: '25%',
  },
})

const RegionText = styled(Text, {
  color: '$black10',
  fontWeight: '500',
  fontSize: '$3',
})

export const Footer = () => {
  const viewingRegion = getViewingRegion()
  const isAuthenticated = useAppSelector(getIsAuthenticated)
  const { showRegionSelectorModal } = useRegionSelector()

  const LocaleSelector = styled(Button, {
    color: '$black10',
    cursor: !isAuthenticated ? 'pointer' : 'default',
    borderWidth: 0,
    hoverStyle: {
      backgroundColor: 'transparent',
      borderWidth: 0,
    },
    pressStyle: {
      backgroundColor: 'transparent',
      borderWidth: 0,
    },
  })

  const { data, isLoading, isError } = useContentBySlug<FooterBlock>({ slug: 'footer' })

  if (isLoading || isError) return null

  let footerLinkKey, socialLinkKey
  if (viewingRegion === ViewingRegion.US) {
    footerLinkKey = isAuthenticated ? TextLinkKeys.usPrivate : TextLinkKeys.usPublic
    socialLinkKey = IconLinkKeys.usSocial
  } else {
    footerLinkKey = isAuthenticated ? TextLinkKeys.ukPrivate : TextLinkKeys.ukPublic
    socialLinkKey = IconLinkKeys.ukSocial
  }

  const footerLinks = data?.fields[footerLinkKey]?.fields.link
  const socialLinks = data?.fields[socialLinkKey]?.fields.link

  return (
    <XStack tag="footer">
      <Container>
        <InnerWrapper>
          <XStack flexDirection="row" flexWrap="wrap" $gtMobile={{ flexWrap: 'nowrap' }}>
            <YStack alignItems="center" width="100%" marginBottom="$3" $gtMobile={{ width: 'auto', marginBottom: 0 }}>
              <Link href={regionalizePath(PATHS.HOME)} aria-label={getString('navigation.home.ariaLabel')}>
                <Icon.VirginRed size="$10" color="$red6" />
              </Link>
            </YStack>
            <YStack flex={1} $gtMobile={{ marginLeft: '$3' }} $gtTabletLandscape={{ marginLeft: '$7' }}>
              <XStack flexWrap="wrap">
                {footerLinks?.map(({ fields: { linkText, url } }) => {
                  const isExternal = /^https?:\/\//.test(url)

                  return (
                    <StyledLink
                      key={linkText}
                      href={isExternal ? url : localizePath(url)}
                      external={isExternal}
                      target={isExternal ? '_blank' : undefined}>
                      {linkText}
                    </StyledLink>
                  )
                })}
              </XStack>
            </YStack>
          </XStack>
          <XStack>
            <YStack alignItems="center" flex={1}>
              <FooterRow $gtTabletLandscape={{ marginTop: 0 }}>
                {isAuthenticated ? (
                  <XStack alignItems="center">
                    <Icon.Globe size="$3" />
                    <RegionText aria-label={`Curent region: ${getRegionDisplayName(viewingRegion)}.`} marginLeft="$1">
                      {getRegionDisplayName(viewingRegion)}
                    </RegionText>
                  </XStack>
                ) : (
                  <LocaleSelector
                    chromeless
                    icon={<Icon.Globe size="$3" />}
                    iconAfter={<Icon.ChevronDown aria-hidden size="$3" />}
                    aria-label={`Curent region: ${getRegionDisplayName(viewingRegion)}. Click to change region.`}
                    onPress={showRegionSelectorModal}>
                    <RegionText aria-hidden>{getRegionDisplayName(viewingRegion)}</RegionText>
                  </LocaleSelector>
                )}
              </FooterRow>
              <FooterRow>
                {socialLinks?.map(({ fields: { accessibilityLabel, iconName, url } }, index) => {
                  const CustomIcon = Icon[iconName as keyof typeof Icon] as NamedExoticComponent<{ size: string }>
                  if (!CustomIcon) return null
                  return (
                    <Link marginHorizontal="$0.75" href={url} external target="_blank" key={url + index} aria-label={accessibilityLabel}>
                      <CustomIcon size="$4" />
                    </Link>
                  )
                })}
              </FooterRow>
              <FooterRow>
                <Anchor variant="red" fontSize="$3" fontWeight="500" href={'https://www.virgin.com/'} target="_blank">
                  Virgin.com
                </Anchor>
              </FooterRow>
              <FooterRow>
                <Trans
                  defaults={getString('redFooter.copyright')}
                  values={{
                    currentYear: new Date().getFullYear(),
                  }}
                />
              </FooterRow>
            </YStack>
          </XStack>
        </InnerWrapper>
      </Container>
    </XStack>
  )
}
