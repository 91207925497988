import React from 'react'
import { useAccounts } from '@/query/accounts'
import { DynamicComposedPage } from '@/pages/DynamicComposedPage'
import { useFeatureSelector } from '@vrw/data/src/redux/features/features.selectors'
import { FeatureName } from '@vrw/data/src/redux/features/features.types'
import { useAppSelector } from '@/redux/hooks/hooks'

export const CreditCardHub: React.FC = () => {
  const { data: accounts } = useAccounts()
  const hasSYFAccount = accounts?.some((account) => account.partnerCode === 'SYF')
  const variantsEnabled = useAppSelector(useFeatureSelector(FeatureName.CC_HUB_VARIANTS))

  if (!variantsEnabled) {
    return <DynamicComposedPage slugName="my-virgin-reward-card-benefits" />
  }

  return <DynamicComposedPage localized={false} slugName={hasSYFAccount ? 'cardholder-benefits' : 'cardholder-benefits-unlinked'} />
}
