import { getPage, getPageSuccess, getPageFailure } from './actions'
import { DispatchFunction } from '../types'
import { errorHandler } from '../errorHandler'
import { getSpecificRuntimePageBySlug, getRuntimeContentAndFilterBySlug } from '../../api/content.api'
import { Locale } from '../../utils/helperFunctions'

export const doSpecificPageFetch =
  ({ slugName, locale }: { slugName?: string; locale?: Locale | string; isAuthenticated?: boolean }) =>
  async (dispatch: DispatchFunction) => {
    try {
      const USregionToAppendToSlug = locale === Locale.EN_US ? '-us' : ''

      dispatch(getPage())

      // direct contentful fetch approach
      const targetPage = await getSpecificRuntimePageBySlug({
        slug: `${slugName}${USregionToAppendToSlug}`,
        locale,
      })
      if (!targetPage) {
        throw new Error(`doSpecificPageFetch(): page with slug ${slugName}${USregionToAppendToSlug} not found or accessible.`)
      }
      dispatch(getPageSuccess(targetPage))
      return
    } catch (error) {
      errorHandler(dispatch, error, getPageFailure)
    }
  }

export const doGenericPageFetchFilterBySlug =
  ({ slug, locale, isAuthenticated }: { slug: string; locale?: Locale | string; isAuthenticated?: boolean }) =>
  async (dispatch: DispatchFunction) => {
    dispatch(getPage())
    try {
      const targetPage = await getRuntimeContentAndFilterBySlug({ slug, locale, isAuthenticated })
      if (!targetPage) {
        throw new Error(`doGenericPageFetchFilterBySlug(): page with slug ${slug} content type not found or accessible.`)
      }
      dispatch(getPageSuccess(targetPage))
      return
    } catch (error) {
      errorHandler(dispatch, error, getPageFailure)
    }
  }
