import { longMonths } from '@vrw/data/src/utils/formatters'

const getDateValue = (year: number, monthIndex: number) => `${year}-${String(monthIndex + 1).padStart(2, '0')}-01`

export const generateDateObject = (date: Date) => {
  const monthIndex = date.getMonth()
  const year = date.getFullYear()
  const longMonth = longMonths[monthIndex]

  const value = getDateValue(year, monthIndex)
  const label = `${longMonth} ${year}`

  return { label, value }
}

export const generateDateOptions = () => {
  const now = new Date()
  const currentMonthIndex = now.getMonth()
  const currentYear = now.getFullYear()
  const nextMonthIndex = currentMonthIndex + 1

  return [
    generateDateObject(now),
    ...longMonths.slice(nextMonthIndex).map((_, index) => generateDateObject(new Date(currentYear, nextMonthIndex + index))),
  ]
}
