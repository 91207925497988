import React, { FC, useEffect } from 'react'
import { getString, formatIsoDateToDateWithMonthString } from '@vrw/data/src/utils'

import { color, layout } from '../../../style/variables'
import { PATHS } from '../../../router/paths'
import { Page } from '../../../components/Page'
import { H1Header } from '../../../components/typography'
import Header from '../../../components/RewardDetails/Header'
import { HelpCTA } from '../../../components/HelpCTA'
import { RewardImage } from '../../../components/RewardDetails/RewardImage'
import { Stars } from '../../../components/icons/Stars'

import { useRedNavigate } from 'web/src/dataImplementation/useRedNavigate'
import { formatPoints } from '@vrw/data/src/utils/formatters'

import { H2, H3, View, Text, ViewProps } from '@red-ui/components'
import { format, addDays, differenceInDays } from 'date-fns'
import { VoyageRewardContent } from '@vrw/data/src/redux/rewards/types'
import { VoyagesOrder } from '@vrw/data/src/redux/orders/types'
import { DotList } from '../Checkout/OrderCheckout/shared'

const Hr = (props: ViewProps) => <View width={'100%'} borderTopColor={'$black1'} borderTopWidth={'$0.25'} {...props} />

export type VirginVoyagesConfirmationProps = { order: VoyagesOrder }
const VirginVoyagesConfirmation: FC<VirginVoyagesConfirmationProps> = ({ order }) => {
  const navigate = useRedNavigate()

  useEffect(() => {
    if (!order) navigate(PATHS.MY_REWARDS_ORDERS)
  })

  const { content, cost, createdDate, voyagesBooking } = order || {}

  const {
    title,
    imageUrl,
    brandDetails,
    dateAndTime: voyageStartDate,
    endDate: voyageEndDate,
    lead,
  } = (content as VoyageRewardContent) || {}
  const { brandName, brandLogo } = brandDetails ?? {}

  const ports = lead?.split(';')

  const { bookingReference, cabinNumber, deckNumber, email, firstName, lastName, phoneNumber } = voyagesBooking || {}

  const voyageDuration = differenceInDays(new Date(voyageEndDate), new Date(voyageStartDate))

  const showCabinDetails = !!cabinNumber && !!deckNumber
  const showBookingReference = !!bookingReference
  const showSailorDetails = !!firstName && !!lastName && !!email && !!phoneNumber

  return (
    <Page testId="page-order" title={`My Rewards | Virgin`}>
      <View display="block">
        <Header>
          <View height={250} $gtMobile={{ height: 330 }} $gtDesktop={{ height: 370 }}>
            <View paddingTop={'$5'} paddingBottom={'$2'} alignItems="center">
              <Stars className="link-accounts__success-icon" size={57} color={color.redHover} />
            </View>
            <H1Header color={color.textHeader} textAlign="center" fontStyle="italic" textTransform="uppercase" weight={600}>
              {getString('virginVoyages.confirmation.heading')}
            </H1Header>
            <H1Header color={color.textHeader} textAlign="center" fontStyle="italic" textTransform="uppercase" weight={600}>
              {getString('virginVoyages.confirmation.headline')}
            </H1Header>
          </View>
        </Header>
      </View>
      <View
        marginTop={-80}
        marginInline="auto"
        display="block"
        overflow="hidden"
        position="relative"
        width="100%"
        $gtMobile={{
          borderRadius: 8,
          marginTop: -149,
          width: layout.heroWidth.tablet,
        }}
        $gtDesktop={{
          marginTop: -149,
          width: layout.heroWidth.desktop,
        }}>
        <RewardImage rewardImageUrl={imageUrl} rewardImageAlt={title || ''} brandLogoUrl={brandLogo} brandLogoAlt={brandName ?? ''} />
        <View style={{ alignItems: 'center', marginLeft: '$3', marginRight: '$3' }}>
          <View marginBottom={'$6'} marginTop={'$12'} maxWidth={650} paddingInline={'$3'}>
            <H2
              textAlign="center"
              fontStyle="italic"
              textTransform="uppercase"
              fontWeight={'600'}
              $gtDesktop={{ fontSize: '$8' }}
              $gtTabletPortrait={{ fontSize: '$7' }}
              $mobile={{ fontSize: '$7' }}>
              {title}
            </H2>
            <View alignItems="center" justifyContent="center" marginTop={'$2'}>
              <Text fontStyle="italic" fontSize={'$10'} textAlign="center">
                <Text fontWeight={'600'} color={'$red6'}>
                  {formatPoints({ points: cost })}
                </Text>
                <Text fontWeight={'400'} color={'$red6'}>
                  {' '}
                  point{cost !== 1 ? 's' : ''}
                </Text>
              </Text>
            </View>
            <View alignItems="center" marginTop={'$3'} marginBottom={'$3'}>
              <Text
                fontSize={'$6'}
                textAlign="center"
                maxWidth={600}
                $gtMobile={{ maxWidth: 500 }}
                $gtTabletLandscape={{ fontSize: '$7', maxWidth: 600 }}>
                {getString('virginVoyages.confirmation.subtitle')}
              </Text>
            </View>

            <Text fontSize={'$6'} textAlign={'center'} $gtTabletLandscape={{ fontSize: '$6' }}>
              {`${getString('virginVoyages.confirmation.orderPlaced')}: ${formatIsoDateToDateWithMonthString(createdDate || '', true)}`}
            </Text>
          </View>

          <View $gtMobile={{ width: '80%' }} $mobile={{ width: '90%' }}>
            <Hr marginBottom={'$4'} />

            <H3 fontWeight={'700'} fontSize={'$6'} color={'$black9'}>
              {getString('virginVoyages.confirmation.bookingSummary')}
            </H3>
            <Text fontSize={'$6'} marginTop={'$1'}>
              {`${format(new Date(voyageStartDate), 'eee dd MMMM')} - ${format(
                addDays(new Date(voyageStartDate), voyageDuration),
                'eee dd MMMM yyyy'
              )}`}
            </Text>

            {showBookingReference && (
              <>
                <H3 fontWeight={'700'} fontSize={'$6'} color={'$black9'} marginTop={'$4'}>
                  {getString('virginVoyages.confirmation.bookingReferenceNumber')}
                </H3>

                <Text fontSize={'$6'} marginTop={'$1'} color={'$red6'} fontWeight={'700'}>
                  {bookingReference}
                </Text>
              </>
            )}

            <Text fontWeight={'700'} fontSize={'$6'} color={'$black9'} marginTop={'$4'}>
              {getString('virginVoyages.confirmation.cabin')}
            </Text>

            {showCabinDetails && (
              <Text fontSize={'$6'} marginTop={'$1'} color={'$black10'} fontWeight={'700'}>
                {`Cabin ${cabinNumber}, Deck ${deckNumber}`}
              </Text>
            )}

            <Text fontSize={'$6'} marginTop={'$1'} color={'$black10'} fontWeight={'400'}>
              {getString('virginVoyages.confirmation.oneCabin')}{' '}
              <Text fontStyle="italic">{getString('virginVoyages.confirmation.upTo2Sailors')}</Text>
            </Text>
            <Text>
              <DotList list={ports} style={{ fontSize: '$6' }} />
            </Text>

            {showSailorDetails && (
              <>
                <H3 fontWeight={'700'} fontSize={'$6'} color={'$black9'} marginTop={'$4'}>
                  {getString('virginVoyages.confirmation.SailorDetails')}
                </H3>

                <Text fontSize={'$6'} marginTop={'$1'} color={'$black10'} fontWeight={'400'}>
                  {`${firstName} ${lastName}`}
                </Text>
                <Text fontSize={'$6'} marginTop={'$1'} color={'$black10'} fontWeight={'400'}>
                  {email}
                </Text>
                <Text fontSize={'$6'} marginTop={'$1'} color={'$black10'} fontWeight={'400'}>
                  {`${phoneNumber}`}
                </Text>
              </>
            )}

            <H3 fontWeight={'700'} fontSize={'$6'} color={'$black9'} marginTop={'$4'}>
              {getString('virginVoyages.confirmation.statusTitle')}
            </H3>

            <Text fontSize={'$6'} marginTop={'$1'} color={'green'} fontWeight={'400'}>
              {getString('virginVoyages.confirmation.orderConfirmed')}
            </Text>

            <Text fontSize={'$6'} marginTop={'$1'} color={'$black9'} fontWeight={'400'}>
              {getString('virginVoyages.confirmation.orderConfirmedDescription')}
            </Text>
          </View>
        </View>
      </View>
      <HelpCTA />
    </Page>
  )
}

export { VirginVoyagesConfirmation }
