import { connect } from 'react-redux'

import { doSubmitPromoCode, doSubmitPromoCodeConfirmed } from '@vrw/data/src/redux/promotions/dispatchers'
import { getIsLoading, getErrorMessage, getHasSubmitted } from '@vrw/data/src/redux/promotions/selectors'
import { DispatchFunction, RootState } from '../../../redux/types'
import { bindTypedActionCreators } from '../../../helpers/bindTypedActionCreators'

import { PromoCodes } from './PromoCodes'

const mapStateToProps = (state: RootState) => ({
  isLoading: getIsLoading(state),
  hasSubmitted: getHasSubmitted(state),
  errorMessage: getErrorMessage(state),
})

const mapDispatchToProps = (dispatch: DispatchFunction) =>
  bindTypedActionCreators(
    {
      doSubmitPromoCode,
      doSubmitPromoCodeConfirmed,
    },
    dispatch
  )

const PromoCodesContainer = connect(mapStateToProps, mapDispatchToProps)(PromoCodes)

export { PromoCodesContainer }
