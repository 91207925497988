import { styled, YStack, XStack, Text, Image, View, Icon } from '@red-ui/components'
import { PUBLIC_URL } from '../../config'

export const PageWrapper = styled(YStack, {
  paddingVertical: '$4',
  minHeight: '100vh',
  width: '40vw',
  marginHorizontal: 'auto',
  $tabletPortrait: {
    width: '65vw',
  },
  $mobile: {
    width: '80vw',
  },
})

export const SpinnerContainer = styled(YStack, {
  minHeight: '80vh',
  justifyContent: 'center',
})

export const LogoImageWrapper = styled(View, {
  padding: '$1',
  borderColor: '$black2',
  borderWidth: 1,
  borderRadius: '$2',
  marginRight: '$2',
})

export const LogoImage = styled(Image, {
  source: {
    uri: `${PUBLIC_URL}/img/virgin-logo-circle.svg`,
    width: 30,
    height: 30,
  },
})

export const BoldText = styled(Text, {
  fontWeight: '600',
})

export const SecondaryText = styled(Text, {
  fontSize: '$2',
  color: '$black5',
})

export const RightText = styled(Text, {
  textAlign: 'right',
})

export const PriceInfoWrapper = styled(XStack, {
  justifyContent: 'space-between',
})

export const InfoIcon = styled(Icon.Info, {
  color: '$black10',
  size: '$5',
  marginRight: '$1',
})

export const PaymentDescriptionText = styled(Text)
