import React, { FC } from 'react'

import { Spacing } from '@/style/variables'
import { formatThousands } from '@/helpers/numberFormat'
import { optimizeContentfulImageUrl, brandLogoFallBackImage, brandLogoImages } from '@/helpers/images'
import CurveSVG from '@/components/CurvedContainer/CurveSVG'
import { parseStyledText } from '@vrw/data/src/utils/helperFunctions'
import { Text, View, TextStyle, TextProps } from '@red-ui/components'
import { Body, H3Header, Picture } from '@/components'

export const h3MarginTop: Spacing = {
  mobile: 40,
  tablet: 40,
  desktop: 40,
}

export const h3MarginBottom: Spacing = {
  mobile: 24,
  tablet: 24,
  desktop: 24,
}

export const header3Style = {
  fontStyle: 'italic',
  textTransform: 'uppercase',
  weight: 600,
  marginBottom: h3MarginBottom,
  marginTop: h3MarginTop,
}

export const ParsedStyledText = ({ text, ...rest }: { text: string } & TextProps) =>
  parseStyledText(text).map((styledText) =>
    Object.keys(styledText.style).length === 0 ? (
      <Text {...rest}>{styledText.text}</Text>
    ) : (
      <Text {...styledText.style} {...rest}>
        {styledText.text}
      </Text>
    )
  )

export type DotListProps = {
  list?: string[]
  style?: TextStyle
  paddingHorizontal?: TextStyle['paddingHorizontal']
}

export const DotList: FC<DotListProps> = ({ list, style = { fontSize: '$4', fontWeight: '500' }, paddingHorizontal = '$0.75' }) => {
  if (!list || list.length === 0) return null

  return list.map((port, i) => (
    <>
      <Text {...style}>{port}</Text>
      {i !== list.length - 1 && (
        <Text {...style} paddingHorizontal={paddingHorizontal}>
          •
        </Text>
      )}
    </>
  ))
}

export type RewardCheckoutViewProps = {
  imageUrl: string
  brandLogoUrl: string
  brandName: string
  title: string
  lead: string | JSX.Element
  cost: number
}

export const CheckoutRewardSummary: FC<RewardCheckoutViewProps> = ({ imageUrl, brandLogoUrl, brandName, title, lead, cost }) => {
  const responsiveImages = {
    mobile: {
      imgSrc: optimizeContentfulImageUrl(imageUrl, 'fill', { width: 350, height: 197 }, 60),
      hiResImgSrc: optimizeContentfulImageUrl(imageUrl, 'fill', { width: 700, height: 394 }, 60),
    },
    tablet: {
      imgSrc: optimizeContentfulImageUrl(imageUrl, 'fill', { width: 422, height: 237 }, 65),
      hiResImgSrc: optimizeContentfulImageUrl(imageUrl, 'fill', { width: 844, height: 474 }, 65),
    },
  }
  return (
    <View borderStyle={'solid'} borderWidth={1} borderColor={'$black2'} borderRadius={'$4'}>
      <View position={'relative'} width={'100%'}>
        <View borderRadius={'$4'} display={'block'} height={'auto'} overflow={'hidden'} width={'100%'}>
          <Picture
            altText={`${title} reward`}
            width={422}
            fallbackImg={responsiveImages.tablet.imgSrc}
            height={237}
            responsiveImages={responsiveImages}
            loading={'lazy'}
            isWebP={true}
          />
          <View position="absolute" bottom={-1} left={0} right={0}>
            <CurveSVG position="bottom" />
          </View>
        </View>
        {brandLogoUrl && (
          <View
            bottom={-10}
            shadowColor={`rgba(0, 0, 0,0.2)`}
            shadowOffset={{ width: 0, height: 8 }}
            shadowRadius={16}
            height={72}
            left={15}
            position={'absolute'}
            width={72}
            zIndex={1}>
            <Picture
              altText={`${brandName} logo`}
              width={144}
              fallbackImg={brandLogoFallBackImage(brandLogoUrl)}
              height={144}
              responsiveImages={brandLogoImages(brandLogoUrl)}
              loading={'lazy'}
              isWebP={true}
            />
          </View>
        )}
      </View>
      <View marginHorizontal={'$2'} marginBottom={'$2'}>
        <H3Header
          textAlign="left"
          weight={600}
          marginTop={{
            mobile: 28,
            tablet: 28,
            desktop: 28,
          }}
          marginBottom={{
            mobile: 24,
            tablet: 24,
            desktop: 24,
          }}>
          {title}
        </H3Header>
        {lead && <Body>{lead}</Body>}
        <Text
          color={'$red5'}
          fontSize={28}
          fontStyle={'italic'}
          fontWeight={600}
          letterSpacing={0.6}
          lineHeight={32}
          $gtTabletPortrait={{ bottom: 0 }}>
          {formatThousands(cost)} point{cost === 1 ? '' : 's'}
        </Text>
      </View>
    </View>
  )
}
