import { REDEEM_PROMOCODE, REDEEM_PROMOCODE_FAILURE, REDEEM_PROMOCODE_SUCCESS, REDEEM_PROMOCODE_CONFIRMED, PromoCodesState } from './types'
import { RedDataAction } from '../types'

const initialState: PromoCodesState = {
  isLoading: false,
  errorMessage: undefined,
  hasSubmitted: false,
  pointsAdded: null,
}

const promoCodesReducer = (state = initialState, action: RedDataAction): PromoCodesState => {
  switch (action.type) {
    case REDEEM_PROMOCODE:
      return {
        ...state,
        errorMessage: undefined,
        isLoading: true,
        hasSubmitted: false,
        pointsAdded: null,
      }
    case REDEEM_PROMOCODE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        hasSubmitted: true,
        errorMessage: undefined,
        pointsAdded: action.payload.data.pointsAdded,
      }
    }
    case REDEEM_PROMOCODE_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasSubmitted: true,
        errorMessage: action.payload.errorMessage,
      }
    case REDEEM_PROMOCODE_CONFIRMED: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}

export { initialState, promoCodesReducer }
