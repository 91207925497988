import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { DYNAMIC_YIELD_ACCOUNT_ID } from '../../config'
import { useAppSelector } from '../../redux/hooks/hooks'
import { useFeatureSelector } from '@vrw/data/src/redux/features/features.selectors'
import { FeatureName } from '@vrw/data/src/redux/features/features.types'
import { updateDynamicYieldCookieConsent } from '@/helpers/dynamicYield/dynamicYieldCookieConsent'

export const DynamicYieldHelmet: React.FC = () => {
  const isEnabled = useAppSelector(useFeatureSelector(FeatureName.WEB_DY_INTEGRATION))

  useEffect(() => {
    if (isEnabled && DYNAMIC_YIELD_ACCOUNT_ID) {
      updateDynamicYieldCookieConsent()
    }
  }, [isEnabled])

  if (!isEnabled || !DYNAMIC_YIELD_ACCOUNT_ID) {
    return null
  }

  return (
    <Helmet>
      <link rel="preconnect" href="//cdn-eu.dynamicyield.com" />
      <link rel="preconnect" href="//st-eu.dynamicyield.com" />
      <link rel="preconnect" href="//rcom-eu.dynamicyield.com" />
      <script type="text/javascript">
        {`
          window.DY = window.DY || {};
          DY.recommendationContext = { type: 'HOMEPAGE' }
        `}
      </script>
      <script type="text/javascript" src={`//cdn-eu.dynamicyield.com/api/${DYNAMIC_YIELD_ACCOUNT_ID}/api_dynamic.js`} />
      <script type="text/javascript" src={`//cdn-eu.dynamicyield.com/api/${DYNAMIC_YIELD_ACCOUNT_ID}/api_static.js`} />
    </Helmet>
  )
}
