import Cookies from 'js-cookie'
import { getViewingRegion } from '../dataImplementation/viewingRegionWeb'
import { pushToGTM } from './windowMethods'

// Declaring this on @types/index.d.ts doesn't work
declare global {
  interface Window {
    dataLayer: Array<object>
    msCrypto: Crypto
  }
}

export const cookieName = 'user-cookie-preferences'

export enum CookiePreferenceId {
  NecessaryCookies = 'necessary-cookies',
  MarketingAdvertising = 'marketing-advertising-cookies',
  Functional = 'functional-cookies',
  Analytics = 'analytics-cookies',
}

export type CookiePreferences = {
  [K in CookiePreferenceId]: boolean
}

enum Trigger {
  PageLoad = 'page-load',
  AcceptAll = 'accept-all',
  RejectAll = 'reject-all',
  SavePreferences = 'save-preferences',
}

interface DataLayerProperties {
  virtualPagePath?: string
  virtualPageTitle?: string
  trigger?: Trigger
  cookie?: CookiePreferences
  loggedIn?: boolean
  tealiumId?: string
  language?: string
  viewingRegion?: string
  campaign_id?: string // SHOP-387 this has been requested as pascal case.
  features?: string[]
}

export const getDefaultCookiePreferences = (): CookiePreferences => ({
  [CookiePreferenceId.NecessaryCookies]: true,
  [CookiePreferenceId.MarketingAdvertising]: false,
  [CookiePreferenceId.Functional]: false,
  [CookiePreferenceId.Analytics]: false,
})

export const getMarketingAdvertisingPreferenceCookie = (): boolean | undefined => {
  const cookie = cookieHelper.get(cookieName)

  return cookie ? JSON.parse(cookie)['marketing-advertising-cookies'] : undefined
}

export const getCookiePreferences = (): CookiePreferences | null => {
  const cookie = cookieHelper.get(cookieName)
  let savedPreferences = null

  if (cookie) {
    try {
      savedPreferences = JSON.parse(cookie)
    } catch (e) {
      console.error(`Failed to parse cookie preferences cookie: ${(e as Error).message}`)
    }
  }

  // Make sure we initialise cookie preferences with safe defaults for BC when we add new preference keys
  return savedPreferences ? { ...getDefaultCookiePreferences(), ...(savedPreferences as any) } : null
}

export const setDataLayer = ({
  virtualPagePath,
  virtualPageTitle,
  trigger,
  cookie,
  loggedIn,
  tealiumId,
  language,
  viewingRegion,
  campaign_id,
  features,
}: DataLayerProperties): void => {
  const data = {
    event: 'virtualPageView',
    virtualPagePath: virtualPagePath || window.location.pathname,
    virtualPageTitle: virtualPageTitle || window.document.title,
    ...(trigger && { trigger }),
    ...(tealiumId && { tealiumId }),
    ...(cookie || (getCookiePreferences() ?? getDefaultCookiePreferences())),
    loggedIn: loggedIn || false,
    language: language || 'en', // TODO(SQ2-1945): language will need updating with non hardcoded value - when available.
    viewingRegion: viewingRegion || getViewingRegion(),
    ...(campaign_id && { campaign_id }),
    ...(features && { features }),
  }
  pushToGTM(data)
}

const persistCookiePreferences = (preferences: CookiePreferences, trigger: Trigger, loggedIn?: boolean): void => {
  const cookieValue = JSON.stringify(preferences)
  cookieHelper.set(cookieName, cookieValue, {
    expires: 365,
  })
  setDataLayer({
    cookie: preferences,
    trigger,
    loggedIn,
  })
}

export const setCookiePreferences = (preferences: CookiePreferences, loggedIn?: boolean): void => {
  persistCookiePreferences(preferences, Trigger.SavePreferences, loggedIn)
}

export const setAllCookiePreferences = (val: boolean): void => {
  persistCookiePreferences(
    {
      [CookiePreferenceId.NecessaryCookies]: true,
      [CookiePreferenceId.MarketingAdvertising]: val,
      [CookiePreferenceId.Functional]: val,
      [CookiePreferenceId.Analytics]: val,
    },
    val ? Trigger.AcceptAll : Trigger.RejectAll
  )
}

// This sets the defaults to the same as the js-cookie v2
export const cookieHelper = Cookies.withAttributes({})
